<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col class="text-h5 font-weight-bold"> {{ title }} </v-col>
      </v-row>
      <!-- Chips Display -->
      <v-row align="center" justify="start">
        <v-col
          v-for="(item, index) in selectedItems"
          :key="index"
          class="shrink"
        >
          <v-chip close @click:close="removeChip(index)">
            {{ item.text }}
          </v-chip>
        </v-col>
      </v-row>

      <!-- Autocomplete -->
      <v-row align="center" justify="start" class="mt-2">
        <v-col cols="12">
          <v-autocomplete
            outlined
            v-model="search"
            :items="filteredItems"
            label="Search or Select Categories"
            hide-details
            clearable
            return-object
            @change="addCategory"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-if="note">
        <v-col sm="auto"><v-icon>mdi-information-outline</v-icon></v-col>
        <v-col class="text-start" align-self="center" style="padding-left: 0">
          <p>{{ note }}</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "AutocompleteChip",
  data() {
    return {
      search: {},
    };
  },
  props: {
    title: {
      type: String,
    },
    note: {
      type: String,
    },
    selectedItems: {
      type: Array,
      default: () => [], // Default to an empty array if not passed
    },
    items: {
      type: Array,
      default: () => [], // Default to an empty array if not passed
    },
  },
  computed: {
  filteredItems() {
    return this.items.filter(item => 
      !this.selectedItems.some(selectedItem => 
        selectedItem.id === item.id && selectedItem.text === item.text
      )
    );
  },
  },
  methods: {
    addCategory() {
      if (this.search && !this.selectedItems.includes(this.search)) {
        this.$emit("add-category", this.title, this.search);
      }
      this.search = ""; // Clear the search field after adding
    },
    removeChip(index) {
      this.$emit("remove-chip", this.title, index); // Emit the index to the parent
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
</style>