<template>
  <v-main>
    <v-container class="px-16" fluid v-if="isLoading == false">
      <!-- Similar Jobs -->
      <v-row>
        <v-col cols="6">
          <h1>{{ getStatusType(toggle_status) }} Candidates</h1>
        </v-col>
        <v-col cols="6" class="text-end">
          <v-btn-toggle v-model="toggle_status" color="primary" dense group>
            <v-btn :value="0" @click="handleStatus('Applied')" text>
              Applied
            </v-btn>

            <v-btn :value="1" @click="handleStatus('Contacted')" text>
              Contacted
            </v-btn>

            <v-btn :value="2" @click="handleStatus('Hired')" text>
              Hired
            </v-btn>

            <v-btn :value="3" @click="handleStatus('Rejected')" text>
              Rejected
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-row class="mb-16" v-if="candidates.length != 0">
        <v-col sm="3" v-for="(candidate, index) in candidates" :key="index">
          <CandidateCard
            :candidate="candidate"
            :job-id="decryptIt($route.params.jobId)"
          />
        </v-col>
      </v-row>
      <v-row class="mb-16" v-else>
        <v-col sm="3" class="text-center brown--text">
          <h1>No Data</h1>
        </v-col>
      </v-row>

      <!-- Newsletter Layout -->
      <v-container class="mt-16" fluid v-if="user.position_id != 1">
        <NewsLetter />
      </v-container>
    </v-container>
    <v-container class="px-16" fluid v-else>
      <Loader />
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import CandidateCard from "../components/candidate-card.vue";
import NewsLetter from "../components/news-letter.vue";
import Loader from "../components/loader-circle.vue";
export default {
  components: { NewsLetter, CandidateCard, Loader },
  data() {
    return {
      filter: {
        is_active: "",
        role_id: "",
        position_id: 2,
        show_deleted: false,
        search_keyword: "",
      },
      candidates: [],
      isLoading: false,
      toggle_status: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleStatus(title) {
      if (title == "Applied") {
        this.filter.status = "";
      } else if (title == "Contacted") {
        this.filter.status = 1;
      } else if (title == "Hired") {
        this.filter.status = 2;
      } else if (title == "Rejected") {
        this.filter.status = 3;
      }

      this.getData();
    },
    getStatusType(statusType) {
      if (statusType === null || statusType === "") return "";
      switch (statusType) {
        case 0:
          return "Applied";
        case 1:
          return "Contacted";
        case 2:
          return "Hired";
        case 3:
          return "Rejected";
        default:
          return 0;
      }
    },
    async getData() {
      console.log("Get Data");
      this.isLoading = true;
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;

      let query = new URLSearchParams({
        role_id: 3,
        page: this.page,
        rowsPerPage: this.rowsPerPage,
        recruiter_id: this.user.id,
        job_id: this.decryptIt(this.$route.params.jobId),
        ...this.filter, // Use the existing filter object directly
      });
      await axios
        .get(`/users?${query}`)
        .then((response) => {
          this.isLoading = false;
          this.candidates = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
