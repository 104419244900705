<template>
  <v-app
    style="font-family: Inter !important"
    id="inspire"
    :style="{
      background: $vuetify.theme.themes.light.background,
    }"
  >
    <router-view style="background: #fcfcfc" />

    <v-spacer></v-spacer>
    <div style="background: #fcfcfc" v-if="!$vuetify.breakpoint.xsOnly && !$vuetify.breakpoint.smOnly">
      <v-divider style="border-color: #c0c0c0" class="mt-8 mb-6"></v-divider>
      <Footer />
    </div>
  </v-app>
</template>
<script>
const CryptoJS = require("crypto-js");
import Footer from "../src/views/layouts/Footer.vue";

export default {
  data() {
    return {
      isTabClosed: false,

      logoutTimer: null, // Timer for automatic logout
      inactivityDuration: 10 * 60 * 1000, // 10 minutes in milliseconds
    };
  },
  components: {
    Footer,
  },
  methods: {
    logout() {
      this.logOut();
      location.replace("/");
    },
    encryptIt(string) {
      let encrypted = CryptoJS.AES.encrypt(
        `${string}`,
        "secret key"
      ).toString();
      return encrypted;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");

.viewBtn {
  border-radius: 10px !important; /* Adjust border radius */
}

.sr_no {
  border-top-left-radius: 4px !important;
}
.action_th {
  min-width: 115px;
}
.striped-table .table-row-even {
  background-color: #f5f5f5;
}

.striped-table .table-row-odd {
  background-color: #ffffff;
}
.striped-table .table-row-last {
  border-end-end-radius: 4px !important;
  /* border-radius-bottomleft: 4px !important; */
}

.v-card {
  border-radius: 10px !important; /* Adjust border radius */
}

.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255); /* Semi-transparent background */
  z-index: 9999;
}

.line-clamp-3 {
  display: -webkit-box; /* Required for webkit-based browsers */
  -webkit-line-clamp: 3; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-4 {
  display: -webkit-box; /* Required for webkit-based browsers */
  -webkit-line-clamp: 4; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
