<template>
  <v-container fluid v-if="isPgLoading == false">
    <v-row>
      <v-col>
        <h4 class="mb-2">Sort by Category</h4>
        <v-autocomplete
          outlined
          v-model="filter.category_id"
          :items="categoryItems"
          placeholder="Eg. Back Office / Data Entry"
          clearable
          hide-details
          @click:clear="filter.category_id = null"
          @change="(page = 1), getData()"
          :error-messages="errors.category_id"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row class="align-center justify-space-between" style="height: 90px">
      <!-- Showing Information -->
      <v-col class="text-start" cols="auto">
        <span>Showing </span>
        <span class="bold-text">{{ count }} </span>
        <!-- <span>of </span>
        <span class="bold-text">944 </span> -->
        <span>jobs</span>
      </v-col>

      <v-spacer></v-spacer>

      <v-col cols="auto">
        <v-tabs v-model="selectedTab" @change="getData">
          <v-tab value="0">Pending Jobs</v-tab>
          <v-tab value="1">Approved Jobs</v-tab>
          <v-tab value="2">Rejected Jobs</v-tab>
          <v-tab value="">All Jobs</v-tab>
        </v-tabs>
      </v-col>

      <!-- Sort By Dropdown -->
      <!-- <v-col cols="auto">
        <v-autocomplete
          dense
          outlined
          v-model="filter.industry"
          :items="sortByItems"
          label="Sort By"
          hide-details
          clearable
          :error-messages="errors.industry"
        ></v-autocomplete>
      </v-col> -->
    </v-row>

    <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>

    <v-row v-if="jobItems.length > 0">
      <v-col sm="4" v-for="(jobData, jobIndex) in jobItems" :key="jobIndex">
        <JobCard
          :job="jobData"
          @get-data="getData"
          :selected-tab="selectedTab"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center py-5">
        <h3 class="text-center" style="color: #3d3d3d">
          No jobs posted yet! Please check back later.
        </h3>
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../components/loader-circle.vue";
import JobCard from "../components/job-card.vue";
export default {
  components: { Loader, JobCard },
  name: "PostedJobsPage",
  data() {
    return {
      profile_section: "Applied Candidates",
      selectedTab: "",
      filter: {
        show_deleted: 0,
        position_id: 2,
        category_id: "",
        is_active: 1,
        search_keyword: "",
        status: "",
      },
      count: 0,
      jobItems: [],
      categoryItems: [],
      sortByItems: ["Newest Post", "Oldest Post"],
      isPgLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      this.isPgLoading = true;
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;

          //Categories
          masters.categories.forEach((cat) => {
            this.categoryItems.push({
              id: cat.id,
              text: cat.name,
              value: cat.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
      this.isPgLoading = false;
    },
    async getData() {
      console.log("Get Data");
      this.isPgLoading = true;

      if (this.selectedTab == "0") {
        this.filter.status = "0";
      } else if (this.selectedTab == "1") {
        this.filter.status = "1";
      } else if (this.selectedTab == "2") {
        this.filter.status = "2";
      } else {
        this.filter.status = "";
      }

      this.filter.category_id = this.filter.category_id
        ? this.filter.category_id
        : "";

      let query = new URLSearchParams({
        page: this.page,
        rowsPerPage: this.rowsPerPage,
        user_id: this.user.id,
        ...this.filter, // Use the existing filter object directly
      });

      await axios
        .get(`/jobs?${query}`)
        .then((response) => {
          this.jobItems = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isPgLoading = false;
    },
  },
};
</script>
