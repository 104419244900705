<template>
  <v-container fluid v-if="isPgLoading == false">
    <v-row justify="center">
      <v-col sm="8">
        <!-- Profile Completion Indicator -->
        <v-card class="mb-4 pa-3" outlined>
          <span class="subtitle-1 font-weight-bold"> Profile Completion </span>
          <v-row align="center" justify="space-between">
            <v-col cols="9">
              <v-progress-linear
                :value="progress"
                :color="progressColor"
                height="10"
                rounded
              ></v-progress-linear>
            </v-col>
            <v-col cols="3" class="text-right">
              <v-chip :color="progressColor" dark>
                {{ progress }}% Completed
              </v-chip>
            </v-col>
          </v-row>
        </v-card>

        <label class="subtitle-1 font-weight-bold"> Company Name </label>
        <v-text-field
          placeholder="Enter Company Name"
          v-model="form.name"
          outlined
          :error-messages="errors.first_name"
          @keypress="onlyAlphabets"
          readonly
        ></v-text-field>

        <label class="subtitle-1 font-weight-bold"> Email </label>
        <v-text-field
          v-model="form.email"
          placeholder="Enter Email"
          outlined
          :error-messages="errors.email"
          readonly
        ></v-text-field>

        <label class="subtitle-1 font-weight-bold"> Phone </label>
        <v-text-field
          v-model="form.phone"
          placeholder="Enter Phone"
          outlined
          maxlength="10"
          @keypress="onlyNumbers"
          :error-messages="errors.phone"
          readonly
        ></v-text-field>

        <label class="subtitle-1 font-weight-bold"> Address </label>
        <v-text-field
          v-model="form.address"
          placeholder="Enter Address"
          outlined
          :error-messages="errors.address"
        ></v-text-field>

        <label class="subtitle-1 font-weight-bold"> City </label>
        <v-autocomplete
          ref="city"
          outlined
          placeholder="Enter City"
          v-model="form.city_id"
          :items="cityItems"
          clearable
          @change="getCityLocality"
          :error-messages="errors.city_id"
        ></v-autocomplete>

        <label class="subtitle-1 font-weight-bold"> City Locality </label>
        <v-autocomplete
          placeholder="Enter City Locality"
          outlined
          v-model="form.city_locality_id"
          :items="cityLocalityItems"
          clearable
          :error-messages="errors.city_locality_id"
        ></v-autocomplete>

        <label class="subtitle-1 font-weight-bold"> About </label>
        <v-textarea
          v-model="form.about"
          outlined
          placeholder="Describe About Your Organisation"
        ></v-textarea>

        <label class="subtitle-1 font-weight-bold">Documentation</label>
        <v-autocomplete
          placeholder="Enter Document Type"
          outlined
          v-model="form.doc_type_id"
          :items="organisationItems"
          clearable
          :error-messages="errors.organisation_document_id"
          @change="updatePlaceholder"
        ></v-autocomplete>

        <v-text-field
          v-model="form.doc_number"
          :placeholder="documentPlaceholder"
          outlined
          :error-messages="errors.number"
        ></v-text-field>

        <v-file-input
          outlined
          type="file"
          v-model="doc_attachment"
          truncate-length="50"
          placeholder="Upload the file here"
        ></v-file-input>
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../components/loader-circle.vue";
export default {
  components: { Loader },
  name: "MyOrganisationPage",
  data() {
    return {
      form: {
        is_your_company: true,
        name: "",
        email: "",
        phone: "",
        address: "",
        city_id: "",
        city_locality_id: "",
        doc_type_id: "",
        doc_number: "",
      },
      profile_section: "My Company",
      tab: null,
      doc_attachment: null,
      myOrg: [],
      cityItems: [],
      cityLocalityItems: [],
      organisationItems: [],
      organisation_document: [],
      isPgLoading: false,
      documentPlaceholder: "Enter Document Number",
      progress: 0,
      progressColor: "red",
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    calculateProgress() {
      let completedFields = 0;
      let totalFields = 8; // Adjust this based on required fields
      console.log("this.myOrg", this.myOrg);
      if (this.myOrg.name) completedFields++;
      if (this.myOrg.email) completedFields++;
      if (this.myOrg.phone) completedFields++;
      if (this.myOrg.address) completedFields++;
      if (this.myOrg.city_id) completedFields++;
      if (this.myOrg.doc_type_id) completedFields++;
      if (this.myOrg.doc_number) completedFields++;
      if (this.myOrg.doc_path) completedFields++; // Bonus for document upload

      // Calculate percentage
      this.progress = Math.round((completedFields / totalFields) * 100);

      // Update color based on progress
      if (this.progress < 50) this.progressColor = "red";
      else if (this.progress < 80) this.progressColor = "orange";
      else if (this.progress < 100) this.progressColor = "green";
      else this.progressColor = "blue";
    },
    async getMasters() {
      this.isPgLoading = true;
      await axios
        .get(`organisations/masters`)
        .then((masters) => {
          masters = masters.data;
          //City
          masters.cities.forEach((city) => {
            this.cityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
          });
          //Organisation Documents
          masters.organisation_documents.forEach((organisation) => {
            this.organisationItems.push({
              id: organisation.id,
              text: organisation.description,
              value: organisation.id,
            });
          });

          this.isPgLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isPgLoading = true;
      if (this.user.organisation_id != null) {
        let encryptedId = this.encryptIt(this.user.organisation_id);
        axios
          .get(`/organisations/${encryptedId}`)
          .then((response) => {
            this.form = response.data.data;
            this.myOrg = response.data.data;
            if (this.form.city_id) {
              this.getCityLocality();
            }
            this.calculateProgress();
            this.old_json = response.data.data;
            this.isPgLoading = false;
          })
          .catch((error) => {
            // redirect to Home Page
            //   window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      }
      this.isLoading = false;
    },
    async getCityLocality() {
      await axios
        .get(`/city_localities?city_id=${this.form.city_id}`)
        .then((response) => {
          const cityLocalities = response.data.data;
          this.cityLocalityItems = [];
          cityLocalities.forEach((cityLocality) => {
            this.cityLocalityItems.push({
              id: cityLocality.id,
              text: cityLocality.name,
              value: cityLocality.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    updatePlaceholder(selectedId) {
      const selectedDocument = this.organisationItems.find(
        (item) => item.id === selectedId
      );
      this.documentPlaceholder = selectedDocument
        ? `Enter ${selectedDocument.text} Number`
        : "Enter Document Number";
    },
    async save() {
      try {
        await axios
          .post(`/organisations`, this.form)
          .then(async (response) => {
            this.organisation_document = response.data.data;
            await this.handleFileUpload();
            await this.saveUserTimestamp(this.old_json, this.form);
          })
          .catch((error) => {
            // redirect to Home Page
            // window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
        this.isLoading = false;
        if (this.$route.name == "ProfilePage") {
          // this.$router.push("/supervisor");
          window.location.href = "/auth/login";
        }
      } catch (e) {
        console.error("Error fetching data:", e);
      }
    },
    async handleFileUpload() {
      let formData = new FormData();
      console.log("this.$refs.file ", this.$refs.file);
      console.log("doc_attachment ", this.doc_attachment);
      formData.append("organisation_id", this.organisation_document.id);
      formData.append("filePath", this.doc_attachment);
      await axios
        .post("organisation_document_path", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
      this.isUploading = false;
    },
  },
};
</script>
