<template>
  <v-main>
    <v-container
      style="background: #1f8bff; color: white"
      class="py-8 py-sm-12 px-5"
      fluid
    >
      <!-- Header Section -->
      <v-row class="text-center" justify="center">
        <v-col cols="12">
          <h1
            style="font-size: 42px"
            class="font-weight-bold text-h5 text-md-h3"
          >
            <span>Explore Jobs By Roles</span>
          </h1>
        </v-col>
      </v-row>

      <!-- Search Section -->
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card class="pa-3 pa-sm-5" elevation="1">
            <v-row>
              <!-- Keyword -->
              <v-col
                cols="10"
                sm="9"
                class="d-flex align-center"
                style="padding: 0 0 0 24px"
              >
                <v-text-field
                  dense
                  solo
                  v-model="filter.search_keyword"
                  label="Your Keyword"
                  prepend-inner-icon="mdi-keyboard-variant"
                  hide-details
                  class="search-textfield"
                ></v-text-field>
              </v-col>
              <v-col class="d-none d-sm-flex" sm="3" style="padding: 0 20px">
                <v-btn color="primary" @click="(page = 1), getData()" large block>
                  <v-icon left size="26">mdi-magnify</v-icon>Search
                </v-btn>
              </v-col>
              <v-col class="d-sm-none" cols="2" style="padding: 0 12px">
                <v-btn color="primary" @click="(page = 1), getData()" large icon>
                  <v-icon left size="26">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- Popular Searches -->
      <v-row justify="center" class="mt-4">
        <v-col cols="12" class="text-center text-caption text-sm-body-1">
          <span style="font-weight: 700">Popular Searches: </span>
          <span
            v-for="(item, index) in [
              'Designer',
              'Web',
              'iOS',
              'Developer',
              'PHP',
              'Senior',
              'Engineer',
            ]"
            :key="index"
            class="text-decoration-underline mx-1"
            style="font-weight: 300"
          >
            {{ item }}<span v-if="index < 6">,</span>
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="py-5">
      <h1 class="text-center font-weight-bold text-h6 text-sm-h4 mb-6">Job Roles</h1>
      <v-row justify="center" align="start">
        <template v-if="!isLoading">
          <v-col
            v-for="role in roles"
            :key="role"
            cols="12"
            sm="6"
            md="3"
            class="text-center"
          >
            <v-card
              class="cardLay"
              outlined
              elevation="1"
              :to="`/jobs?category=${role.id}`"
            >
              <v-card-title
                class="d-flex justify-center font-weight-medium text-body-1"
                style="padding: 8px 16px"
              >
                {{ role.name }}
              </v-card-title>
            </v-card>
          </v-col>
        </template>
        <template v-else>
          <!-- Skeleton Loader -->
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="text-center"
              v-for="n in 4"
              :key="'skeleton-' + n"
            >
              <v-card outlined>
                <v-card-title style="padding: 16px 16px 10px">
                  <v-skeleton-loader
                    class="mx-auto"
                    width="80%"
                    type="text"
                  ></v-skeleton-loader>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "modulesPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      roles: [],
      isLoading: false,
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&show_deleted=" +
        this.filter.show_deleted +
        "&is_active=" +
        this.filter.is_active +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`/categories?${query}`)
        .then((response) => {
          this.roles = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.cardLay {
  transition: transform 0.2s ease;
}
.cardLay:hover {
  transform: scale(1.02);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}
</style>
