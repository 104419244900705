<template>
  <v-main>
    <v-container style="padding: 40px 0 30px" class="px-2 px-sm-16">
      <v-stepper v-model="stepValue">
        <v-stepper-header>
          <v-stepper-step :complete="stepValue > 1" step="1">
            Verify Your Email
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="stepValue > 2" step="2">
            Personal Details
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="stepValue > 3" step="3">
            Your Company Details
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <!-------- Verify Your Email -------->
          <v-stepper-content step="1">
            <v-card class="my-6" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="5" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">Email *</p>
                    <v-text-field
                      v-model="form.email"
                      outlined
                      placeholder="Enter Email"
                      clearable
                      @input="errors.email = []"
                      :disabled="isVerified"
                      :error-messages="errors.email"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="5" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-0">OTP</p>
                    <v-otp-input
                      v-model="form.otp"
                      :disabled="isVerified"
                    ></v-otp-input>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                    style="padding: 0 12px"
                    align-self="center"
                    v-if="isVerified"
                  >
                    <v-icon
                      style="font-size: 50px"
                      class="ml-1 mb-2"
                      color="primary"
                      >mdi-check-decagram-outline</v-icon
                    >
                  </v-col>
                </v-row>
                <v-row v-if="isVerified">
                  <v-col cols="12" sm="6" md="4" style="padding-top: 0">
                    <p class="font-weight-bold text-start mb-1">
                      Enter password *
                    </p>
                    <v-text-field
                      v-model="form.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      outlined
                      solo
                      :error-messages="errors.password"
                      placeholder="Enter password"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" style="padding-top: 0">
                    <p class="font-weight-bold text-start mb-1">
                      Re-Enter password *
                    </p>
                    <v-text-field
                      v-model="form.password_confirmation"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      outlined
                      solo
                      @keypress.enter="login"
                      @input="errors.password_confirmation = []"
                      :error-messages="errors.password_confirmation"
                      placeholder="Re-Enter password"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col class="text-end" v-if="!isVerified && form.otp.length < 6">
                <v-btn
                  color="primary"
                  class="px-16"
                  :disabled="isButtonDisabled"
                  @click="emailVerification"
                  :loading="isOtpLoading"
                >
                  {{ otpButtonText }}
                </v-btn>
              </v-col>
              <v-col
                class="text-end"
                v-else-if="!isVerified && form.otp.length == 6"
              >
                <v-btn
                  color="primary"
                  class="px-16"
                  :loading="isOtpLoading"
                  @click="emailVerification"
                >
                  Verify
                </v-btn>
              </v-col>
              <v-col class="text-end" v-else>
                <v-btn
                  color="primary"
                  class="px-16"
                  :disabled="!form.password || !form.password_confirmation"
                  @click="step1"
                >
                  Next
                </v-btn>
              </v-col>
              <!-- <v-col class="text-end">
                <v-btn
                  color="primary"
                  class="px-16"
                  @click="stepValue < 3 && stepValue++"
                >
                  Next
                </v-btn>
              </v-col> -->
            </v-row>
          </v-stepper-content>

          <!-------- Personal Details -------->
          <v-stepper-content step="2">
            <v-card class="my-6" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">First Name *</p>
                    <v-text-field
                      v-model="form.first_name"
                      outlined
                      placeholder="Enter First Name"
                      clearable
                      :error-messages="errors.first_name"
                      @keypress="onlyAlphabets"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">Middle Name</p>
                    <v-text-field
                      v-model="form.middle_name"
                      outlined
                      placeholder="Enter Middle Name"
                      clearable
                      :error-messages="errors.middle_name"
                      @keypress="onlyAlphabets"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">Last Name *</p>
                    <v-text-field
                      v-model="form.last_name"
                      outlined
                      placeholder="Enter Last Name"
                      clearable
                      :error-messages="errors.last_name"
                      @keypress="onlyAlphabets"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  class="px-10 px-sm-16"
                  @click="stepValue > 1 && stepValue--"
                >
                  Back
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-end">
                <v-btn
                  color="primary"
                  class="px-10 px-sm-16"
                  @click="stepValue < 3 && stepValue++"
                  :disabled="!form.first_name || !form.last_name"
                >
                  Next
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-------- Your Company Details -------->
          <v-stepper-content step="3">
            <v-card class="my-6" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">
                      Company Name *
                    </p>
                    <v-text-field
                      v-model="form.organisation.name"
                      outlined
                      placeholder="Enter Company Name"
                      clearable
                      :error-messages="errors.company_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">
                      Company Email
                    </p>
                    <v-text-field
                      v-model="form.organisation.email"
                      outlined
                      placeholder="Enter Company Email"
                      clearable
                      :error-messages="errors.company_email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="8" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">
                      Company Address *
                    </p>
                    <v-textarea
                      v-model="form.organisation.address"
                      placeholder="Enter Company Address"
                      outlined
                      :error-messages="errors.address"
                    ></v-textarea>
                  </v-col>
                  <v-col sm="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">
                      Company City *
                    </p>
                    <v-autocomplete
                      ref="city"
                      outlined
                      v-model="form.organisation.city_id"
                      :items="cityItems"
                      placeholder="City"
                      clearable
                      hide-details="auto"
                      @change="getCityLocality"
                      :error-messages="errors.city_id"
                    ></v-autocomplete>

                    <p class="font-weight-bold text-start mt-3 mb-1">
                      Company City Locality
                    </p>
                    <v-autocomplete
                      ref="city_locality"
                      outlined
                      v-model="form.organisation.city_locality_id"
                      :items="cityLocalityItems"
                      placeholder="City Locality"
                      clearable
                      hide-details="auto"
                      :error-messages="errors.city_locality_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col>
                <v-btn
                  cols="6"
                  color="primary"
                  class="px-10 px-sm-16"
                  @click="stepValue > 1 && stepValue--"
                >
                  Back
                </v-btn>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  cols="6"
                  color="primary"
                  class="px-10 px-sm-16"
                  @click="save"
                  :disabled="
                    !form.organisation.name ||
                    !form.organisation.address ||
                    !form.organisation.city_id
                  "
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        is_active: true,
        position_id: 1,
        soft_password: "123456",
        password: "",
        password_confirmation: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone: "",
        otp: "",
        organisation: {},
      },
      is_experience: 0,
      stepValue: 1,
      genderItems: [],
      cityItems: [],
      cityLocalityItems: [],
      qualificationItems: [],
      categoryItems: [],
      dob_date_menu: false,
      showPassword: false,
      isVerified: false,
      errors: {
        password_confirmation: [],
        email: [],
        company_name: [],
        city_id: [],
        address: [],
      },
      isButtonDisabled: false,
      isOtpLoading: false,
      otpButtonText: "Send OTP",
    };
  },
  mounted() {
    if (localStorage.getItem("company-id").length) {
      this.form.company_id = localStorage.getItem("company-id");
    }
    this.form.phone = sessionStorage.getItem("phone");
    this.form.position_id = sessionStorage.getItem("positionId");
    // const phone = this.$route.state?.phone;
    this.form.role_id = "3";
    this.getMasters();
  },
  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    step1() {
      if (this.form.password == this.form.password_confirmation) {
        this.errors.password_confirmation = [];
        this.stepValue < 3 && this.stepValue++;
      } else {
        this.errors.password_confirmation.push("Passwords do not match");
      }
    },
    async getMasters() {
      await axios
        .get(`register/masters`)
        .then((masters) => {
          masters = masters.data;
          //City
          masters.cities.forEach((city) => {
            this.cityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
          });
          // //City Locality
          // masters.cityLocalities.forEach((cityLocality) => {
          //   this.cityLocalityItems.push({
          //     id: cityLocality.id,
          //     text: cityLocality.name,
          //     value: cityLocality.id,
          //   });
          // });
          //Qualification
          masters.qualifications.forEach((qualification) => {
            this.qualificationItems.push({
              id: qualification.id,
              text: qualification.description,
              value: qualification.id,
            });
          });
          //Categories
          masters.categories.forEach((category) => {
            this.categoryItems.push({
              category_id: category.id,
              text: category.name,
              value: category.id,
            });
          });
          //Gender
          masters.genders.forEach((gender) => {
            this.genderItems.push({
              id: gender.id,
              text: gender.description,
              value: gender.description,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async emailVerification() {
      try {
        this.isOtpLoading = true;
        const regOtp = await axios.post(
          "/register/email_verification",
          this.form
        );
        if (regOtp && !this.form.otp) {
          this.isOtpLoading = false;
          this.$toast.success(
            `Your OTP has been sent to ${this.form.email}. Kindly check your email.`,
            {
              position: "top-right",
              timeout: 5000,
            }
          );
        }
        if (regOtp.data.isVerified) {
          this.isVerified = true;
          this.isOtpLoading = false;
        }
        this.disableOtpButton();
      } catch (error) {
        this.isOtpLoading = false;
        if (error.response && error.response.data.errors) {
          this.errors.email = error.response.data.errors.email || [];
        }
      }
    },
    async save() {
      if (this.form.organisation && this.form.organisation.name == null) {
        this.errors.company_name.push("Enter your company name");
      }
      if (this.form.organisation && this.form.organisation.address == null) {
        this.errors.address.push("Enter your company address");
      }
      if (this.form.organisation && this.form.organisation.city_id == null) {
        this.errors.city_id.push("Select the city name");
      }
      if (
        this.errors.company_name.length > 0 ||
        this.errors.address.length > 0 ||
        this.errors.city_id.length > 0
      ) {
        return;
      }
      console.log("this.category_experiences", this.form);
      try {
        this.isLoading = true;
        let user = await axios.post("/register", this.form);
        this.user_id = user.data.data.id;
        this.isLoading = false;
        let userData = {
          is_login: true,
          email: user.data.data.email,
          password: user.data.data.soft_password,
          position_id: user.data.data.position_id,
        };
        await this.logIn(userData);
        await this.saveUserTimestamp(null, this.form);
        window.location.href = "/";
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    disableOtpButton() {
      this.isButtonDisabled = true;
      let remainingTime = 20; // 20 seconds timer
      this.otpButtonText = `Wait ${remainingTime}s`;

      const timer = setInterval(() => {
        remainingTime--;
        if (remainingTime <= 0) {
          clearInterval(timer);
          this.isButtonDisabled = false;
          this.otpButtonText = "Send OTP"; // Reset button text
        } else {
          this.otpButtonText = `Wait ${remainingTime}s`;
        }
      }, 1000);
    },
    async getCityLocality() {
      await axios
        .get(`/city_localities?city_id=${this.form.organisation.city_id}`)
        .then((response) => {
          const cityLocalities = response.data.data;
          this.cityLocalityItems = [];
          cityLocalities.forEach((cityLocality) => {
            this.cityLocalityItems.push({
              id: cityLocality.id,
              text: cityLocality.name,
              value: cityLocality.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
