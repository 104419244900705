<template>
  <!-- JOB BOARD -->
  <v-container
    :style="{
      backgroundImage: `url(${require('@/assets/newsletter.png')})`,
      borderRadius: '30px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      padding: '50px 110px',
      height: '300px',
    }"
    fluid
  >
    <!-- Text -->
    <v-row class="text-center" justify="center">
      <v-col cols="12">
        <h1 class="mb-4" style="color: white">
          <span>New Things Will Aways </span>
          <br />
          <span> Update Regularly </span>
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-card width="500">
        <v-card-text class="pt-5 pb-6">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              class="d-flex align-center"
              style="padding: 0 0 0 24px"
            >
              <v-text-field
                dense
                v-model="form.email"
                label="Enter Email Here"
                prepend-inner-icon="mdi-email"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col sm="3" style="padding: 0 16px">
              <v-btn color="primary" class="mt-1 py-5" small>
                <v-icon left size="20">mdi-check-decagram-outline</v-icon>
                Subscribe
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Newsletter",
  data() {
    return {
      form: {
        email: "",
      },
    };
  },
};
</script>