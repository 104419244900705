<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title>
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add a User
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.first_name"
                outlined
                label="First Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.first_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.middle_name"
                outlined
                label="Middle Name"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.middle_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.last_name"
                outlined
                label="Last Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.last_name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.phone"
                outlined
                label="Phone"
                prepend-inner-icon="mdi-phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="position"
                outlined
                v-model="form.position_id"
                :items="positionItems"
                label="Position *"
                prepend-inner-icon="mdi-account-tie-outline"
                clearable
                @input="UpdateUpline"
                :error-messages="errors.position_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="upline_position"
                outlined
                readonly
                v-model="form.upline_position_id"
                :items="positionItems"
                label="Upline Position *"
                prepend-inner-icon="mdi-account-tie-hat-outline"
                messages="this will be automatically select based on your position."
                :error-messages="errors.upline_position_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="upline_user"
                outlined
                v-model="form.upline_user_id"
                :items="uplineUserItems"
                label="Upline User"
                prepend-inner-icon="mdi-account"
                clearable
                :error-messages="errors.user_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="status"
                outlined
                label="Device Reset status"
                v-model="form.is_requested_device_reset"
                :items="typeItems"
                prepend-inner-icon="mdi-cellphone-remove"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="status"
                outlined
                label="Location Reset status"
                v-model="form.is_requested_location_reset"
                :items="typeItems"
                prepend-inner-icon="mdi-map-marker-remove-outline"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>
          <section v-if="company.is_SUSP_enabled">
            <template v-if="form.user_devices.length">
              <v-divider class="my-2"> </v-divider>
              <h3 class="my-4">List of Device(s) for this User:</h3>
              <v-row
                v-for="(device, uD) in form.user_devices"
                :key="`device${uD}`"
              >
                <v-col sm="4">
                  <v-text-field
                    v-model="device.imei_no"
                    outlined
                    label="Imei No *"
                    prepend-inner-icon="mdi-phone"
                    :error-messages="
                      errors[`user_devices.${uD}.imei_no`]
                        ? errors[`user_devices.${uD}.imei_no`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="1">
                  <v-dialog
                    v-model="isDeleteDeviceDialogs[p]"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        class="ml-9"
                        small
                        dark
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-i con dark> mdi-close-thick </v-i>
                      </v-btn>
                    </template>
                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the report?
                      </v-card-title>
                      <v-card-text>
                        <v-btn
                          color="primary"
                          @click.stop="deleteDevice(device)"
                        >
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          color="red"
                          dark
                          @click.stop="isDeleteDeviceDialogs = []"
                        >
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col sm="12" class="mb-5">
                <v-btn small rounded color="primary" @click="Add_Empty_Device">
                  Add Device
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5"> </v-divider>
          </section>
          <section v-if="company.is_SUSL_enabled">
            <template v-if="form.user_locations.length">
              <v-divider class="my-2"> </v-divider>
              <h3 class="my-4">List of Location(s) for this User:</h3>
              <v-row
                v-for="(location, uL) in form.user_locations"
                :key="`location${uL}`"
              >
                <v-col sm="3">
                  <v-text-field
                    v-model="location.lat"
                    outlined
                    label="Lat *"
                    prepend-inner-icon="mdi-map-marker-radius-outline"
                    :error-messages="
                      errors[`user_locations.${uL}.lat`]
                        ? errors[`user_locations.${uL}.lat`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-text-field
                    v-model="location.long"
                    outlined
                    label="Long *"
                    prepend-inner-icon="mdi-map-marker-radius-outline"
                    :error-messages="
                      errors[`user_locations.${uL}.long`]
                        ? errors[`user_locations.${uL}.long`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-textarea
                    label="Address"
                    auto-grow
                    outlined
                    rows="1"
                    row-height="15"
                  ></v-textarea>
                </v-col>
                <v-col sm="1">
                  <v-dialog
                    v-model="isDeleteLocationDialogs[p]"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        class="ml-9"
                        small
                        dark
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-close-thick </v-icon>
                      </v-btn>
                    </template>
                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the report?
                      </v-card-title>
                      <v-card-text>
                        <v-btn
                          color="primary"
                          @click.stop="deleteLocation(location)"
                        >
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          color="red"
                          dark
                          @click.stop="isDeleteLocationDialogs = []"
                        >
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col sm="12" class="mb-5">
                <v-btn
                  small
                  rounded
                  color="primary"
                  @click="Add_Empty_Location"
                >
                  Add Location
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5"> </v-divider>
          </section>
          <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            rounded
            color="primary"
            dark
            @click="save"
          >
            Save User
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "UserCreatePage",
  data() {
    return {
      form: {
        is_active: true,
        soft_password: "123456",
        user_devices: [],
        user_locations: [],
      },
      supervisorDownlined: [
        "Field Executive",
        "Merchandiser",
        "Promoter",
        "Van Sales",
      ],
      companyId: "",
      positionItems: [],
      uplineUserItems: [],
      isDeleteDeviceDialogs: [],
      isDeleteLocationDialogs: [],
      isLoading: false,
      typeItems: [
        { id: "PENDING", text: "PENDING", value: 1 },
        { id: "APPROVED", text: "APPROVED", value: 0 },
      ],
    };
  },
  mounted() {
    this.form.company_id = this.company.id;
    this.form.role_id = "3";
    console.log(this.companyId);
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Position
          masters.positions.forEach((position) => {
            this.positionItems.push({
              id: position.id,
              text: position.name,
              value: position.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/users", this.form);
        this.isLoading = false;
        await this.saveUserTimestamp(null, this.form);
        this.$router.push("/users");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    Add_Empty_Device() {
      let user_device = {
        imei_no: "",
      };
      this.form.user_devices.push(user_device);
    },
    deleteDevice(user_device) {
      this.form.user_devices.splice(
        this.form.user_devices.indexOf(user_device),
        1
      );
      this.isDeleteDeviceDialogs = [];
    },
    Add_Empty_Location() {
      let user_location = {
        imei_no: "",
      };
      this.form.user_locations.push(user_location);
    },
    deleteLocation(user_location) {
      this.form.user_locations.splice(
        this.form.user_locations.indexOf(user_location),
        1
      );
      this.isDeleteLocationDialogs = [];
    },
    UpdateUpline() {
      console.log("Position:" + this.form.position_id);

      let current = this.positionItems.find(
        (position) => position.id == this.form.position_id
      );
      if (
        this.supervisorDownlined.find((position) => position == current.text)
      ) {
        let upline = this.positionItems.find(
          (position) => position.text == "Supervisor"
        );
        if (upline) {
          this.form.upline_position_id = upline ? upline.id : null;
          this.getUplineUsers();
        }
      } else {
        let upline =
          this.positionItems[this.positionItems.indexOf(current) - 1];
        if (upline) {
          this.form.upline_position_id = upline ? upline.id : null;
          this.getUplineUsers();
        }
      }
    },
    async getUplineUsers() {
      this.uplineUserItems = [];
      let query = "position_id=" + this.form.upline_position_id;
      await axios
        .get(`users?${query}`)
        .then((users) => {
          //User
          users.data.data.forEach((user) => {
            this.uplineUserItems.push({
              id: user.id,
              text: user.user_name,
              value: user.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
