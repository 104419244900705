<template>
  <v-card width="450">
    <v-card-title>
      <v-row justify="center" class="mb-4">
        <v-col cols="12" class="text-center">
          <v-chip color="#BADBFF" text-color="#1F8BFF" label>
            Job Application
          </v-chip>
        </v-col>
        <h1 class="text-h5 font-weight-bold" style="color: black">
          Start Your Career Today
        </h1>
        <v-col cols="12" class="text-center" style="padding-top: 10px">
          <p style="color: #606060; font-size: 16px">
            Please fill in your information and send it to <br />the employer.
          </p>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="px-10">
      <v-row>
        <!-- Full Name -->
        <v-col cols="12">
          <label>Full Name *</label>
          <v-text-field
            outlined
            dense
            placeholder="Enter First Name"
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Email -->
        <v-col cols="12">
          <label>Email *</label>
          <v-text-field
            outlined
            dense
            placeholder="Enter Email"
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Contact Number -->
        <v-col cols="12">
          <label>Contact Number *</label>
          <v-text-field
            outlined
            dense
            placeholder="Enter Contact Number"
            hide-details
          ></v-text-field>
        </v-col>

        <!-- Upload Resume -->
        <v-col cols="12">
          <label>Upload Resume</label>
          <v-file-input
            outlined
            dense
            placeholder="Choose File"
            prepend-icon="mdi-paperclip"
            hide-details
          ></v-file-input>
        </v-col>
      </v-row>

      <!-- Footer Section -->
      <v-row>
        <v-col cols="12" style="padding-bottom: 0px">
          <v-checkbox
            v-model="agreeToTerms"
            label="Agree To Terms and Conditions"
            color="#1C1B1F"
            class="mt-0"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="text-center" style="color: white">
          <v-btn color="primary" block large> Apply Job </v-btn>
        </v-col>
        <v-col cols="12" class="text-center">
          <p style="color: #606060; font-size: 18px">
            Do You Need Support?
            <span style="color: black; font-style: italic; font-weight: 500">
              Contact Us
            </span>
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>
<style scoped>
p {
  margin: 0;
  line-height: 1.2;
  text-transform: capitalize;
}
label {
  color: black;
  font-size: 16px;
  font-weight: 600;
}
</style>