<template>
  <v-container fluid v-if="isPgLoading == false">
    <v-row>
      <v-col>
        <h4 class="mb-2">Sort by Posted Jobs</h4>
        <v-autocomplete
          outlined
          v-model="filter.job_id"
          :items="jobItems"
          placeholder="Eg. Back Office / Data Entry"
          clearable
          hide-details
          :error-messages="errors.job_id"
          @change="getData"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row class="align-center justify-space-between">
      <!-- Showing Information -->
      <v-col class="text-start">
        <span>Showing </span>
        <span class="bold-text"> {{ count }} </span>
        <!-- <span>of </span>
        <span class="bold-text">944 </span> -->
        <span>Candidates</span>
      </v-col>

      <!-- Sort By Dropdown -->
      <!-- <v-col cols="auto">
        <v-autocomplete
          dense
          outlined
          v-model="filter.industry"
          :items="B"
          label="Sort By"
          hide-details
          clearable
          :error-messages="errors.industry"
        ></v-autocomplete>
      </v-col> -->
    </v-row>
    <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>
    <v-row v-if="candidates.length > 0">
      <v-col sm="4" v-for="(candidate, index) in candidates" :key="index">
        <CandidateCard :candidate="candidate" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center py-5">
        <h2 class="text-center" style="color: #3d3d3d">
          No candidates have applied yet. Check back later!
        </h2>
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../components/loader-circle.vue";
import CandidateCard from "../components/candidate-card.vue";
export default {
  components: { Loader, CandidateCard },
  name: "JobAppliedCandidatesPage",
  data() {
    return {
      profile_section: "Applied Candidates",
      filter: {
        show_deleted: 0,
        position_id: 2,
        category_id: "",
        is_active: "",
        search_keyword: "",
        job_id: "",
      },
      count: 0,
      candidates: [],
      jobItems: [],
      sortByItems: ["Newest Post", "Oldest Post"],
      isPgLoading: false,
    };
  },
  mounted() {
    this.getJobData();
    this.getData();
  },
  methods: {
    async getData() {
      console.log("Get Data");
      this.isPgLoading = true;

      this.filter.job_id = this.filter.job_id ? this.filter.job_id : "";
      let query = new URLSearchParams({
        page: this.page,
        rowsPerPage: this.rowsPerPage,
        role_id: 3,
        recruiter_id: this.user.id,
        ...this.filter, // Use the existing filter object directly
      });

      await axios
        .get(`/users?${query}`)
        .then((response) => {
          this.candidates = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isPgLoading = false;
    },
    async getJobData() {
      this.isPgLoading = true;

      let query = new URLSearchParams({
        is_active: 1,
        position_id: 2,
        show_deleted: 0,
        user_id: this.user.id,
      });

      await axios
        .get(`/jobs?${query}`)
        .then((response) => {
          this.jobs = response.data.data;
          //Categories
          this.jobs.forEach((job) => {
            this.jobItems.push({
              id: job.id,
              text: job.title,
              value: job.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isPgLoading = false;
    },
  },
};
</script>
