<template>
  <v-main>
    <v-container class="px-5 px-sm-16 mt-8" fluid v-if="isPgLoading == false">
      <v-row class="pa-0" align="center" justify="space-between">
        <!-- Left Section: Job Info -->
        <v-col>
          <v-row align-content="center">
            <v-col cols="auto">
              <v-avatar
                size="100"
                color="secondary"
                class="d-flex align-center justify-center"
                style="border: 1px solid #1f8bff; cursor: pointer"
                @click="triggerFileUpload"
              >
                <v-img
                  :src="
                    userData.organisation && userData.organisation.logo_path
                      ? mediaUrl + userData.organisation.logo_path
                      : require('@/assets/office-building.webp')
                  "
                  alt="Organisation Logo"
                  contain
                ></v-img>
              </v-avatar>
              <!-- Hidden file input -->
              <input
                type="file"
                ref="imagepath_file"
                accept="image/*"
                style="display: none"
                :disabled="tab != 0"
                @change="handleFileUpload"
              />
            </v-col>
            <v-col align-self="center">
              <h1 class="font-weight-bold text-start text-h5 mb-1">
                {{
                  userData.organisation_id != null
                    ? userData.organisation.name
                    : userData.user_name
                }}
              </h1>
              <v-row
                dense
                align="center"
                v-if="userData.city?.name && userData.city_locality?.name"
              >
                <v-icon size="18" color="#1C1B1F">
                  mdi-map-marker-outline
                </v-icon>
                <div class="text-caption grey--text text--darken-2">
                  {{ userData.city_locality?.name }},
                  {{ userData.city?.name }}
                </div>
              </v-row>
              <h3 class="font-weight-semibold text-start mt-1">
                {{
                  userData.category_experiences &&
                  userData.category_experiences[0]?.category.name
                }}
              </h3>
            </v-col>
          </v-row>
        </v-col>

        <!-- Right Section: Apply Now Button -->
        <v-col
          class="d-flex align-center justify-end"
          v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        >
          <v-btn
            v-if="user.position == 2"
            color="primary"
            class="py-5"
            @click="is_job_app_login = true"
            large
            to="/candidate-preview"
          >
            <v-icon left size="20">mdi-eye-outline</v-icon>
            Preview
          </v-btn>
        </v-col>
      </v-row>
      <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>
      <v-row>
        <v-col sm="3">
          <v-tabs
            v-model="tab"
            vertical
            background-color="transparent"
            class="tabs-left-align"
          >
            <v-tab>
              <v-icon left> mdi-account </v-icon>
              My Profile
            </v-tab>
            <v-tab>
              <v-icon left> mdi-office-building-outline </v-icon>
              My Company
            </v-tab>
            <!-- <v-tab>
              <v-icon left> mdi-domain </v-icon>
              Client Company
            </v-tab> -->
            <v-tab>
              <v-icon left> mdi-briefcase </v-icon>
              Job Posted
            </v-tab>
            <!-- <v-tab>
              <v-icon left> mdi-text-box-search-outline </v-icon>
              Applied Candidates
            </v-tab> -->
            <!-- <v-tab>
              <v-icon left> mdi-database-search </v-icon>
              Matched Candidates
            </v-tab> -->
            <v-tab>
              <v-icon left> mdi-list-status </v-icon>
              <!-- Candidates Status -->
              Applied Candidates
            </v-tab>
            <v-tab>
              <v-icon left> mdi-diamond </v-icon>
              Premiums
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col sm="9">
          <v-tabs-items v-model="tab" style="background: transparent">
            <v-tab-item>
              <RecruiterId ref="save_btn" />
            </v-tab-item>
            <v-tab-item>
              <MyOrganisation ref="save_btn" />
            </v-tab-item>
            <!-- <v-tab-item>
              <ClientCompany ref="save_btn" />
            </v-tab-item> -->
            <v-tab-item>
              <PostedJobs />
            </v-tab-item>
            <!-- <v-tab-item>
              <AppliedCandidates />
            </v-tab-item> -->
            <!-- <v-tab-item>
              <MatchedCandidates />
            </v-tab-item> -->
            <v-tab-item>
              <ApprovedCandidates />
            </v-tab-item>
            <v-tab-item>
              <UserPremium />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <div v-if="tab == 0 || tab == 1">
        <v-divider
          style="border: 1px solid #d7d7d7"
          class="mt-5 mt-sm-16 mb-5 mb-sm-10"
        ></v-divider>

        <v-row class="pb-2">
          <v-col sm="12" class="d-flex justify-center">
            <v-btn
              color="primary"
              :min-width="$vuetify.breakpoint.mdAndDown ? '100%' : '40%'"
              x-large
              @click="triggerChildSave"
            >
              Save All Changes
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <div v-else class="full-page-loader">
      <Loader />
    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import Loader from "../components/loader-circle.vue";
import RecruiterId from "./recruiter-id.vue";
// import AppliedCandidates from "./applied-candidates.vue";
// import MatchedCandidates from "./matched-candidates.vue";
import PostedJobs from "./posted-jobs.vue";
import MyOrganisation from "./my-organisation.vue";
// import ClientCompany from "./client-company.vue";
import ApprovedCandidates from "./candidates-status.vue";
import UserPremium from "./user-premium.vue";
export default {
  components: {
    Loader,
    RecruiterId,
    // AppliedCandidates,
    // MatchedCandidates,
    PostedJobs,
    MyOrganisation,
    // ClientCompany,
    ApprovedCandidates,
    UserPremium,
  },
  name: "RecruiterProfilePage",
  data() {
    return {
      profile_section: "My Company",
      tab: parseInt(this.$route.query.tab) || "",
      userData: {},
      isPgLoading: false,
      isDataLoading: false,
    };
  },
  watch: {
    "$route.query.tab": {
      immediate: true,
      handler(newTab) {
        this.tab = parseInt(newTab) || 0; // Ensure tab is an integer
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isPgLoading = true;
      this.isLoading = true;
      let encryptedId = this.encryptIt(this.user.id);
      axios
        .get(`/users/${encryptedId}`)
        .then(async (response) => {
          this.userData = response.data.data;
          this.old_json = response.data.data;
          this.isPgLoading = false;
        })
        .catch((error) => {
          // redirect to Home Page
          //   window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async handleFileUpload() {
      let formData = new FormData();
      this.imagepath_attachment = this.$refs.imagepath_file.files[0];
      formData.append("id", this.userData.organisation.id);
      formData.append("imagepath", this.imagepath_attachment);
      await axios
        .post("upload_organisation_logo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
      this.isUploading = false;
    },
    async triggerChildSave() {
      await this.$refs.save_btn.save(); // Call the child component's save function
      this.$toast.success("Updated successfully!", {
        position: "top-right",
        timeout: 3000,
      });
      this.getData();
    },
    triggerFileUpload() {
      this.$refs.imagepath_file.click();
    },
  },
};
</script>

<style>
.tabs-left-align {
  text-align: left;
}

.tabs-left-align .v-tab {
  justify-content: flex-start;
}
</style>
