<template>
  <v-main>
    <div v-if="isPgLoading == false">
      <!-- Header -->
      <v-container class="mt-6 py-8 px-16" fluid>
        <v-row>
          <v-col>
            <v-row align="center">
              <v-col cols="auto" style="padding-right: 0">
                <v-avatar
                  size="100"
                  color="secondary"
                  class="d-flex align-center justify-center"
                  style="border: 1px solid #1f8bff"
                >
                  <v-img
                    :src="
                      organisation.logo_path
                        ? mediaUrl + organisation.logo_path
                        : require('@/assets/office-building.webp')
                    "
                    cover
                  ></v-img>
                </v-avatar>
              </v-col>
              <v-col>
                <h4 class="text-h4 font-weight-bold mb-1 text-start">
                  {{ organisation.name }}
                </h4>
                <v-row dense align="center">
                  <v-icon size="18" color="#1C1B1F">
                    mdi-map-marker-outline
                  </v-icon>
                  <div class="text-caption grey--text text--darken-2">
                    {{ organisation.city_locality?.name }},
                    {{ organisation.city?.name }}
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col align-self="center" class="text-end">
            <v-btn color="primary" class="mt-4" large>
              <v-icon left size="20">mdi-phone</v-icon>
              Contact Us
            </v-btn>
          </v-col> -->
        </v-row>
        <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>
      </v-container>

      <!-- Posting Form -->
      <v-container class="px-16" fluid>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <label class="subtitle-1 font-weight-bold">
                  Select Your Organisation *
                </label>
                <v-autocomplete
                  outlined
                  v-model="form.organisation_id"
                  :items="organisationItems"
                  placeholder="Select Your Organisation"
                  clearable
                  :error-messages="
                    form.organisation_id ? '' : errors.organisation_id || ''
                  "
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="6">
            <v-card>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <label class="subtitle-1 font-weight-bold">
                      Job Title *
                    </label>
                    <v-combobox
                      outlined
                      v-model="form.title"
                      :items="jobTitleItems"
                      placeholder="Eg. Data Entry Operation"
                      clearable
                      :error-messages="form.title ? '' : errors.title"
                    ></v-combobox>
                  </v-col>

                  <v-col sm="12">
                    <label class="subtitle-1 font-weight-bold">
                      Job Category *
                    </label>
                    <v-autocomplete
                      outlined
                      v-model="form.category_id"
                      :items="categoryItems"
                      placeholder="Eg. Back Office / Data Entry"
                      clearable
                      :error-messages="
                        form.category_id ? '' : errors.category_id
                      "
                    ></v-autocomplete>
                  </v-col>

                  <v-col sm="12">
                    <label class="subtitle-1 font-weight-bold">
                      Number of Openings *
                    </label>
                    <v-text-field
                      v-model="form.job_openings"
                      outlined
                      placeholder="E.g 1"
                      @keypress="onlyNumbers"
                      :error-messages="
                        form.job_openings ? '' : errors.job_openings
                      "
                    ></v-text-field>
                  </v-col>

                  <v-col class="mb-8">
                    <label class="subtitle-1 font-weight-bold">
                      Job Type *
                    </label>
                    <br />
                    <v-btn-toggle v-model="form.job_type" style="width: 100%">
                      <v-row>
                        <v-col>
                          <v-btn
                            value="0"
                            outlined
                            block
                            :color="
                              !form.job_type && errors.job_type
                                ? 'red'
                                : 'black'
                            "
                          >
                            Full Time
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn
                            value="1"
                            outlined
                            block
                            :color="
                              !form.job_type && errors.job_type
                                ? 'red'
                                : 'black'
                            "
                          >
                            Part Time
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-btn-toggle>
                    <span
                      class="error--text ml-4"
                      style="font-size: 12px"
                      v-if="!form.job_type && errors.job_type"
                    >
                      {{ errors.job_type }}
                    </span>
                  </v-col>

                  <v-col class="mb-8" sm="12">
                    <label class="subtitle-1 font-weight-bold">
                      Work Location Type *
                    </label>
                    <br />
                    <v-btn-toggle v-model="form.work_type" style="width: 100%">
                      <v-row>
                        <v-col lg="6">
                          <v-btn
                            value="0"
                            outlined
                            block
                            :color="
                              !form.work_type && errors.work_type
                                ? 'red'
                                : 'black'
                            "
                          >
                            Work From Office
                          </v-btn>
                        </v-col>
                        <v-col lg="6">
                          <v-btn
                            value="1"
                            outlined
                            block
                            :color="
                              !form.work_type && errors.work_type
                                ? 'red'
                                : 'black'
                            "
                          >
                            Work From Home
                          </v-btn>
                        </v-col>
                        <v-col lg="6">
                          <v-btn
                            value="2"
                            outlined
                            block
                            :color="
                              !form.work_type && errors.work_type
                                ? 'red'
                                : 'black'
                            "
                          >
                            Hybrid
                          </v-btn>
                        </v-col>
                        <v-col lg="6">
                          <v-btn
                            value="3"
                            outlined
                            block
                            :color="
                              !form.work_type && errors.work_type
                                ? 'red'
                                : 'black'
                            "
                          >
                            Field Job
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-btn-toggle>
                    <span
                      class="error--text ml-4"
                      style="font-size: 12px"
                      v-if="!form.work_type && errors.work_type"
                    >
                      {{ errors.work_type }}
                    </span>
                  </v-col>

                  <v-col sm="12">
                    <label class="subtitle-1 font-weight-bold">
                      Qualification *
                    </label>
                    <v-autocomplete
                      ref="requiredQualification"
                      outlined
                      v-model="form.required_qualification"
                      :items="qualificationItems"
                      placeholder="Eg. SSC / HSC"
                      clearable
                      :error-messages="
                        form.required_qualification
                          ? ''
                          : errors.required_qualification
                      "
                    ></v-autocomplete>
                  </v-col>

                  <!-- From Salary Group -->
                  <v-col cols="12">
                    <label class="subtitle-1 font-weight-bold"
                      >From Salary *</label
                    >

                    <v-row>
                      <v-col cols="4">
                        <v-autocomplete
                          outlined
                          v-model="form.salary_from_value"
                          :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          label="Value"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          outlined
                          v-model="form.salary_from_multiplier"
                          :items="[100, 1000, 10000, 100000, 1000000]"
                          label="Multiplier"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          v-model="computedSalaryFrom"
                          label="Total"
                          hide-details="auto"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mb-5">
                    <label class="subtitle-1 font-weight-bold"
                      >To Salary *</label
                    >
                    <v-row>
                      <!-- To Salary Group -->
                      <v-col cols="4">
                        <v-autocomplete
                          outlined
                          v-model="form.salary_to_value"
                          :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          label="Value"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          outlined
                          v-model="form.salary_to_multiplier"
                          :items="[100, 1000, 10000, 100000, 1000000]"
                          label="Multiplier"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          v-model="computedSalaryTo"
                          label="Total"
                          hide-details="auto"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col sm="12">
                    <label class="subtitle-1 font-weight-bold">Gender *</label>
                    <v-autocomplete
                      ref="Gender"
                      outlined
                      v-model="form.gender"
                      :items="genderItems"
                      placeholder="Gender"
                      clearable
                      :error-messages="form.gender ? '' : errors.gender"
                    ></v-autocomplete>
                  </v-col>

                  <!-- <v-col sm="12">
                    <label class="subtitle-1 font-weight-bold"
                      >Job Timing *</label
                    >
                    <v-row>
                      <v-col cols="11" sm="6">
                        <v-dialog
                          ref="timingFrom"
                          v-model="timing_from_menu"
                          :return-value.sync="form.timing_from"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="form.timing_from"
                              outlined
                              label="From"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="
                                form.timing_from ? '' : errors.timing_from
                              "
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="timing_from_menu"
                            v-model="form.timing_from"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="timing_from_menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.timingFrom.save(form.timing_from)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="11" sm="6">
                        <v-dialog
                          ref="timingTo"
                          v-model="timing_to_menu"
                          :return-value.sync="form.timing_to"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="form.timing_to"
                              outlined
                              label="To"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="
                                form.timing_to ? '' : errors.timing_to
                              "
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="timing_to_menu"
                            v-model="form.timing_to"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="timing_to_menu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.timingTo.save(form.timing_to)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-col> -->

                  <v-col class="mb-8" sm="12">
                    <label class="subtitle-1 font-weight-bold">
                      Working Shifts *
                    </label>
                    <br />
                    <v-btn-toggle
                      v-model="form.work_shifts"
                      style="width: 100%"
                      multiple
                    >
                      <v-row>
                        <v-col>
                          <v-btn
                            value="MORNING"
                            outlined
                            block
                            :color="
                              !form.work_shifts && errors.work_shifts
                                ? 'red'
                                : 'black'
                            "
                          >
                            Morning
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn
                            value="EVENING"
                            outlined
                            block
                            :color="
                              !form.work_shifts && errors.work_shifts
                                ? 'red'
                                : 'black'
                            "
                          >
                            Evening
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn
                            value="NIGHT"
                            outlined
                            block
                            :color="
                              !form.work_shifts && errors.work_shifts
                                ? 'red'
                                : 'black'
                            "
                          >
                            Night
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-btn-toggle>
                    <span
                      class="error--text ml-4"
                      style="font-size: 12px"
                      v-if="!form.work_shifts && errors.work_shifts"
                    >
                      {{ errors.work_shifts }}
                    </span>
                  </v-col>

                  <v-col class="mb-8" sm="12">
                    <label class="subtitle-1 font-weight-bold">
                      Working Days *
                    </label>
                    <br />
                    <v-btn-toggle
                      v-model="form.working_days"
                      style="width: 100%"
                    >
                      <v-row>
                        <v-col>
                          <v-btn
                            value="5 Days"
                            outlined
                            block
                            :color="
                              !form.working_days && errors.working_days
                                ? 'red'
                                : 'black'
                            "
                          >
                            5 Days
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn
                            value="6 Days"
                            outlined
                            block
                            :color="
                              !form.working_days && errors.working_days
                                ? 'red'
                                : 'black'
                            "
                          >
                            6 Days</v-btn
                          >
                        </v-col>
                        <v-col>
                          <v-btn
                            value="Others"
                            outlined
                            block
                            :color="
                              !form.working_days && errors.working_days
                                ? 'red'
                                : 'black'
                            "
                          >
                            Others
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-btn-toggle>
                    <span
                      class="error--text ml-4"
                      style="font-size: 12px"
                      v-if="!form.working_days && errors.working_days"
                    >
                      {{ errors.working_days }}
                    </span>
                  </v-col>
                  <v-col sm="12">
                    <label class="subtitle-1 font-weight-bold">
                      Job Description *
                    </label>
                    <br />

                    <v-textarea
                      v-model="form.descriptions"
                      outlined
                      :error-messages="
                        form.descriptions ? '' : errors.descriptions
                      "
                    ></v-textarea>
                    <!-- <vue-editor
                      ref="about me"
                      v-model="form.about_me"
                      class="form-control"
                    /> -->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="6">
            <!-------- Job Benefit -------->
            <div>
              <AutocompleteChip
                title="Job Benefits"
                :selectedItems="jobBenefitSelected"
                :items="jobBenefits"
                @add-category="addChip"
                @remove-chip="removeChip"
              />
            </div>

            <!-------- Job Documents -------->
            <div class="mt-8">
              <AutocompleteChip
                title="Job Documents"
                :selectedItems="jobDocumentSelected"
                :items="jobDocuments"
                @add-category="addChip"
                @remove-chip="removeChip"
              />
            </div>

            <!-------- Skill -------->
            <div class="mt-8">
              <AutocompleteChip
                title="Skills"
                note="You can add up to 15 Skills"
                :selectedItems="skillSelected"
                :items="skills"
                @add-category="addChip"
                @remove-chip="removeChip"
              />
            </div>

            <!-------- Work Experience -------->
            <v-card class="mt-10">
              <v-card-text>
                <v-row>
                  <v-col class="text-h5 font-weight-bold mb-8">
                    Work Experience
                  </v-col>
                </v-row>
                <p class="subtitle-1 font-weight-bold">
                  Working Experience Required?
                </p>
                <v-row>
                  <v-col>
                    <v-btn-toggle v-model="form.is_fresher" style="width: 100%">
                      <v-row>
                        <v-col>
                          <v-btn value="0" outlined block> Yes </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn value="1" outlined block> No </v-btn>
                        </v-col>
                      </v-row>
                    </v-btn-toggle>
                  </v-col>
                  <v-col sm="12" v-if="form.is_fresher == 0">
                    <v-row>
                      <v-col>
                        <v-text-field
                          outlined
                          label="Experience From"
                          v-model="form.required_experience_from"
                          placeholder="Eg. 2"
                          :error-messages="
                            form.required_experience_from
                              ? ''
                              : errors.required_experience_from
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          outlined
                          label="Experience To"
                          v-model="form.required_experience_to"
                          placeholder="Eg. 4"
                          :error-messages="
                            form.required_experience_to
                              ? ''
                              : errors.required_experience_to
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <!-------- Work Experience -------->
            <v-card class="mt-10">
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="text-h6 font-weight-bold">
                    Location
                  </v-col>

                  <v-col sm="12">
                    <v-textarea
                      v-model="form.address"
                      label="Address *"
                      outlined
                      :error-messages="form.address ? '' : errors.address"
                    ></v-textarea>
                  </v-col>
                  <v-col sm="6" style="padding: 0px 12px">
                    <v-autocomplete
                      ref="state"
                      outlined
                      v-model="form.state_id"
                      :items="stateItems"
                      label="State"
                      prepend-inner-icon="mdi-account-tie-hat-outline"
                      clearable
                      @change="getCity"
                      :error-messages="form.state_id ? '' : errors.state_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="6" style="padding: 0 12px">
                    <v-autocomplete
                      ref="city"
                      outlined
                      v-model="form.city_id"
                      :items="cityItems"
                      label="City"
                      prepend-inner-icon="mdi-account-tie-hat-outline"
                      clearable
                      @change="getCityLocality"
                      :error-messages="form.city_id ? '' : errors.city_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="6" style="padding: 0px 12px">
                    <v-autocomplete
                      ref="cityLocality"
                      outlined
                      v-model="form.city_locality_id"
                      :items="cityLocalityItems"
                      label="City Locality"
                      prepend-inner-icon="mdi-account-tie-hat-outline"
                      clearable
                      :error-messages="
                        form.city_locality_id ? '' : errors.city_locality_id
                      "
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="6" style="padding: 0px 12px">
                    <v-text-field
                      outlined
                      v-model="form.pincode"
                      label="Pincode"
                      maxlength="6"
                      @keypress="onlyNumbers"
                      :error-messages="form.pincode ? '' : errors.pincode"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- <v-row class="mt-10">
          <v-col sm="12" class="d-flex justify-center">
            <v-checkbox
              v-model="form.allow_to_call"
              label="Allow Candidates to Call between 10:00 am - 07:00 pm on 8828085321"
              class="mt-0"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col sm="12" class="d-flex justify-center">
            <v-checkbox
              v-model="form.whatsapp_updates"
              label="Receive updates from CHOTU JOBS on whatsapp"
              class="mt-0"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row> -->

        <v-divider
          style="border: 1px solid #d7d7d7"
          class="mt-16 mb-10"
        ></v-divider>

        <v-row class="mt-10">
          <v-col sm="12" class="d-flex justify-center">
            <v-btn
              color="primary"
              width="40%"
              x-large
              @click="save"
              :loading="isJobLoading"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <Loader />
    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import AutocompleteChip from "../components/autocomplete-chip.vue";
import Loader from "../components/loader-circle.vue";
// import { VueEditor } from "vue2-editor";
export default {
  components: { AutocompleteChip, Loader },
  name: "JobCreatePage",
  data() {
    return {
      form: {
        title: "",
        category_id: "",
        job_openings: "",
        job_type: "",
        is_contract_job: 0,
        work_type: "",
        state_id: "",
        city_id: "",
        city_locality_id: "",
        pincode: "",
        gender: "",
        required_qualification: "",
        is_fresher: 1,
        required_experience_from: "",
        required_experience_to: "",
        salary_type: 0,
        salary_range_from: "",
        salary_range_to: "",
        descriptions: "",
        timing_from: "",
        timing_to: "",
        working_days: "",
        work_shifts: [],
        is_deposit: false,
        deposit_amount: "",
        asset: "",
        deposit_type: 0,
        work_sector: "",
        allow_to_call: false,
        whatsapp_updates: false,
      },
      organisation: [],
      jobTitleItems: [],
      qualificationItems: [],
      genderItems: [],
      stateItems: [],
      cityItems: [],
      cityLocalityItems: [],
      categoryItems: [],
      organisationItems: [],
      isLoading: false,
      isJobLoading: false,
      isPgLoading: false,
      timing_from_menu: false,
      timing_to_menu: false,

      jobBenefitSelected: [],
      jobBenefits: [],

      jobDocumentSelected: [],
      jobDocuments: [],

      skillSelected: [],
      skills: [],
    };
  },
  computed: {
    computedSalaryFrom() {
      return this.form.salary_from_value && this.form.salary_from_multiplier
        ? this.form.salary_from_value * this.form.salary_from_multiplier
        : null;
    },
    computedSalaryTo() {
      return this.form.salary_to_value && this.form.salary_to_multiplier
        ? this.form.salary_to_value * this.form.salary_to_multiplier
        : null;
    },
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      this.isPgLoading = true;
      await axios
        .get(`jobs/masters`)
        .then((masters) => {
          masters = masters.data;
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
          });
          //Job Title
          masters.jobTitle.forEach((title) => {
            this.jobTitleItems.push(title.name);
          });
          //Job Benefits
          masters.jobBenefits.forEach((benefit) => {
            this.jobBenefits.push({
              id: benefit.id,
              text: benefit.description,
              value: benefit.name,
            });
          });
          //Job Document
          masters.jobDocuments.forEach((document) => {
            this.jobDocuments.push({
              id: document.id,
              text: document.description,
              value: document.id,
            });
          });
          //Category
          masters.categories.forEach((category) => {
            this.categoryItems.push({
              id: category.id,
              text: category.name,
              value: category.id,
            });
          });
          //Skill
          masters.skills.forEach((skill) => {
            this.skills.push({
              id: skill.id,
              text: skill.name,
              value: skill.id,
            });
          });
          //Organisation
          masters.organisations.forEach((organisation) => {
            this.organisationItems.push({
              id: organisation.id,
              text: organisation.name,
              value: organisation.id,
            });
          });
          //Qualification
          masters.qualifications.forEach((qualification) => {
            this.qualificationItems.push({
              id: qualification.id,
              text: qualification.description,
              value: qualification.id,
            });
          });
          //Genders
          masters.genders.forEach((gender) => {
            this.genderItems.push({
              id: gender.id,
              text: gender.description,
              value: gender.id,
            });
          });
          this.isPgLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },

    async getData() {
      this.isPgLoading = true;
      if (this.user.organisation_id != null) {
        let encryptedId = this.encryptIt(this.user.organisation_id);
        axios
          .get(`/organisations/${encryptedId}`)
          .then((response) => {
            this.organisation = response.data.data;
            this.form.organisation_id = this.organisation.id;
            this.old_json = response.data.data;
            this.isPgLoading = false;
          })
          .catch((error) => {
            // redirect to Home Page
            //   window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      }
      this.isLoading = false;
    },
    async getCity() {
      await axios
        .get(`/cities?state_id=${this.form.state_id}`)
        .then((response) => {
          const cityLocalities = response.data.data;
          this.cityItems = [];
          cityLocalities.forEach((city) => {
            this.cityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async getCityLocality() {
      await axios
        .get(`/city_localities?city_id=${this.form.city_id}`)
        .then((response) => {
          const cityLocalities = response.data.data;
          this.cityLocalityItems = [];
          cityLocalities.forEach((cityLocality) => {
            this.cityLocalityItems.push({
              id: cityLocality.id,
              text: cityLocality.name,
              value: cityLocality.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isJobLoading = true;
        if (this.form.is_deposit == false) {
          this.form.deposit_amount = "";
          this.form.asset = "";
          this.form.deposit_type = 0;
        }
        this.form.job_benefits = this.jobBenefitSelected.map(
          (benefit) => benefit.text
        );
        this.form.job_documents = this.jobDocumentSelected.map((doc) => doc.id);
        this.form.skills = this.skillSelected.map((skill) => skill.id);
        this.form.created_by = this.user.id;
        this.form.salary_range_from = this.computedSalaryFrom;
        this.form.salary_range_to = this.computedSalaryTo;

        const job = await axios.post("/jobs", this.form);

        if (job) {
          this.$toast.success("Job posted successfully!", {
            position: "top-right",
            timeout: 3000,
          });
        }
        await this.saveUserTimestamp(null, this.form);
        this.isJobLoading = false;
        this.$router.push("/recruiter-profiles?tab=3");
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isJobLoading = false;
      }
    },
    addChip(title, value) {
      if (title == "Category" && this.catSelected.length < 5) {
        this.catSelected.push(value);
      } else if (title == "Skills" && this.skillSelected.length < 15) {
        this.skillSelected.push(value);
      } else if (title == "Job Benefits" && this.skillSelected.length < 15) {
        this.jobBenefitSelected.push(value);
      } else if (title == "Job Documents" && this.skillSelected.length < 15) {
        this.jobDocumentSelected.push(value);
      }
    },
    removeChip(title, index) {
      if (title == "Category") {
        this.catSelected.splice(index, 1);
      } else if (title == "Skills") {
        this.skillSelected.splice(index, 1);
      } else if (title == "Job Benefits") {
        this.jobBenefitSelected.splice(index, 1);
      } else if (title == "Job Documents") {
        this.jobDocumentSelected.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
</style>
