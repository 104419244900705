<template>
  <v-main>
    <v-container class="text-center">
      <v-card class="pa-6 mx-auto" max-width="500">
        <v-icon size="60" color="green">mdi-check-circle</v-icon>
        <h2 class="mt-4">Your Payment is Successful!</h2>
        <p>Please wait for the premium confirmation approval from ChotuJobs.</p>

        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          class="mt-4"
        ></v-progress-circular>

        <v-alert v-if="error" type="error" class="mt-4">
          {{ error }}
        </v-alert>

        <v-card-actions class="mt-4">
          <v-btn color="primary" @click="$router.push('/premiums')"
            >Back to Premium Page</v-btn
          >
          <v-btn color="secondary" @click="$router.push('/recruiter-profiles?tab=5')"
            >Check Status</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      error: null,
    };
  },
  mounted() {
    this.checkPaymentStatus(); // Call API when page loads
  },
  methods: {
    async checkPaymentStatus() {
      this.loading = true;
      this.error = null;

      const transactionId = this.$route.params.transactionId; // Get transactionId from URL

      if (!transactionId) {
        this.error = "Transaction ID is missing.";
        this.loading = false;
        return;
      }
      try {
        const response = await axios.post("/check_payment_status", {
          transactionId,
        });

        console.log("Payment Status:", response.data);
      } catch (err) {
        this.error =
          err.response?.data?.error || "Failed to check payment status.";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
