import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import axios from "axios";

Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
import AuthLayout from "../views/layouts/auth-layout.vue";

// Static
import HomePage from "../views/home";

// Auth
import LoginPage from "../views/auth/login.vue";
import RegisterPage from "../views/auth/register.vue";
import ForgotPasswordPage from "../views/auth/forgot-password.vue";
import GoogleCallback from "../views/auth/google-callback.vue";

import CandidateRegisterPage from "../views/auth/candidate-registration.vue";
import RecruiterRegisterPage from "../views/auth/recruiter-registration.vue";

// Companies
import CompaniesPage from "../views/companies/index.vue";
import CompanyCreatePage from "../views/companies/create.vue";
import CompanyIdPage from "../views/companies/id.vue";

// Users
import UsersPage from "../views/users/index.vue";
import UserCreatePage from "../views/users/create.vue";
import UserIdPage from "../views/users/id.vue";
import ProfilePage from "../views/users/id.vue";
// import DeviceResetRequestPage from "../views/users/device-reset-requests.vue";
// import LocationResetRequestPage from "../views/users/location-reset-requests.vue";
import UsersUploadPage from "../views/users/upload.vue";

// Positions
import PositionsPage from "../views/positions/index.vue";
import PositionCreatePage from "../views/positions/create.vue";
import PositionIdPage from "../views/positions/id.vue";

// Roles
import RolesPage from "../views/roles/index.vue";
import RoleIdPage from "../views/roles/id.vue";

// Modules
import ModulesPage from "../views/modules/index.vue";
import ModuleCreatePage from "../views/modules/create.vue";
import ModuleIdPage from "../views/modules/id.vue";

// Outlets
import OutletsPage from "../views/outlets/index.vue";
import OutletCreatePage from "../views/outlets/create.vue";
import OutletIdPage from "../views/outlets/id.vue";
import OutletsUploadPage from "../views/outlets/upload.vue";

// Outlet Users
import OutletUsersPage from "../views/outlets/outlet-users/index.vue";
import OutletUserCreatePage from "../views/outlets/outlet-users/create.vue";
import OutletUserIdPage from "../views/outlets/outlet-users/id.vue";

// Pjps
import PjpsPage from "../views/pjps/index.vue";
import PjpCreatePage from "../views/pjps/create.vue";
import PjpIdPage from "../views/pjps/id.vue";
import PjpsUploadPage from "../views/pjps/upload.vue";
import PjpCopyReferencesPage from "../views/pjps/copy-pjp-references.vue";
import PjpCopyPreviousMonth from "../views/pjps/copy-pjp-prev-month.vue";

// PjpReferences
import PjpReferencesPage from "../views/pjp-references/index.vue";
import PjpReferenceCreatePage from "../views/pjp-references/create.vue";
import PjpReferenceIdPage from "../views/pjp-references/id.vue";

// Permissions
import PermissionsPage from "../views/permissions/index.vue";
import PermissionCreatePage from "../views/permissions/create.vue";
import PermissionIdPage from "../views/permissions/id.vue";

// Values
import Values from "@/views/values/index.vue";
import ValueCreate from "@/views/values/create.vue";
import ValueId from "@/views/values/id.vue";

// ValueLists
import ValueLists from "@/views/value-lists/index.vue";
import ValueListUpload from "@/views/value-lists/upload.vue";
import ValueListCreate from "@/views/value-lists/create.vue";
import ValueListId from "@/views/value-lists/id.vue";

// User Timestamps
import UserTimestamps from "@/views/user-timestamps/index.vue";

// City Jobs
import CityJobsPage from "@/views/city-jobs/index.vue";

// Role Jobs
import RoleJobsPage from "@/views/role-jobs/index.vue";

// Jobs
import JobsPage from "@/views/jobs/index.vue";
import JobCreatePage from "@/views/jobs/create.vue";

// Job Details
import JobDetailPage from "@/views/jobs/job-details.vue";

// Candidate Profile
import CandidateProfilePage from "@/views/candidates/profile.vue";

// Candidate Preview Page
import CandidatePreviewPage from "@/views/candidates/preview.vue";

// Applied Candidates Page
import AppliedCandidatesPage from "@/views/jobs/applied-candidates.vue";

// Recruiter Profile
import RecruiterProfilePage from "@/views/recruiters/profile.vue";

// Saved Jobs
import SavedJobsPage from "@/views/candidates/saved-job.vue";

// Organisations Page
import OrganisationsPage from "@/views/organisations/index.vue";
import OrganisationCreatePage from "@/views/organisations/create.vue";

// About Us
import AboutUsPage from "@/views/companies/about-us.vue";

// Privacy Policy
import PrivacyPolicyPage from "@/views/companies/privacy-policy.vue";

// Terms And Conditions
import TermsAndConditionsPage from "@/views/companies/terms-and-conditions.vue";

// Contact Us
import ContactUsPage from "@/views/companies/contact-us.vue";

// Job Applied Candidates Page
import JobAppliedCandidatesPage from "@/views/recruiters/applied-candidates.vue";

// Job Matched Candidates Page
import JobMatchedCandidatesPage from "@/views/recruiters/matched-candidates.vue";

// Premium Page
import PremiumsPage from "@/views/premiums/index.vue";

// Payment Success Page
import PaymentSuccessPage from "@/views/recruiters/payment-success.vue";

const routes = [
  // AuthLayout
  {
    path: "/auth",
    name: "AuthLayout",
    component: AuthLayout,
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
      // Comapnies
      {
        path: "/companies",
        name: "CompaniesPage",
        component: CompaniesPage,
        meta: { role: 1 },
      },
      {
        path: "/companies/create",
        name: "CompanyCreatePage",
        component: CompanyCreatePage,
        meta: { role: 1 },
      },
      {
        path: "/companies/:companyId",
        name: "CompanyIdPage",
        component: CompanyIdPage,
        meta: { role: 1 },
      },
      // Users
      {
        path: "/users",
        name: "UsersPage",
        component: UsersPage,
      },
      {
        path: "/users/create",
        name: "UserCreatePage",
        component: UserCreatePage,
      },
      {
        path: "/users/upload",
        name: "UsersUploadPage",
        component: UsersUploadPage,
      },
      {
        path: "/profile/:userId",
        name: "ProfilePage",
        component: ProfilePage,
      },
      {
        path: "/users/:userId",
        name: "UserIdPage",
        component: UserIdPage,
      },
      // Positions
      {
        path: "/positions",
        name: "PositionsPage",
        component: PositionsPage,
      },
      {
        path: "/positions/create",
        name: "PositionCreatePage",
        component: PositionCreatePage,
      },
      {
        path: "/positions/:positionId",
        name: "PositionIdPage",
        component: PositionIdPage,
      },
      // Roles
      {
        path: "/roles",
        name: "RolesPage",
        component: RolesPage,
      },
      {
        path: "/roles/:roleId",
        name: "RoleIdPage",
        component: RoleIdPage,
      },
      // Modules
      {
        path: "/modules",
        name: "ModulesPage",
        component: ModulesPage,
      },
      {
        path: "/modules/create",
        name: "ModuleCreatePage",
        component: ModuleCreatePage,
      },
      {
        path: "/modules/:moduleId",
        name: "ModuleIdPage",
        component: ModuleIdPage,
      },
      // Permissions
      {
        path: "/permissions",
        name: "PermissionsPage",
        component: PermissionsPage,
      },
      {
        path: "/permissions/create",
        name: "PermissionCreatePage",
        component: PermissionCreatePage,
      },
      {
        path: "/permissions/:permissionId",
        name: "PermissionIdPage",
        component: PermissionIdPage,
      },
      // Values
      {
        path: "/values",
        name: "Values",
        component: Values,
      },
      {
        path: "/values/create",
        name: "ValueCreate",
        component: ValueCreate,
      },
      {
        path: "/values/:valueId",
        name: "ValueId",
        component: ValueId,
      },
      // ValueLists
      {
        path: "/value-lists",
        name: "ValueLists",
        component: ValueLists,
      },
      {
        path: "/value-lists/upload",
        name: "ValueListUpload",
        component: ValueListUpload,
      },
      {
        path: "/value-lists/create",
        name: "ValueListCreate",
        component: ValueListCreate,
      },
      {
        path: "/value-lists/:valueListId",
        name: "ValueListId",
        component: ValueListId,
      },
      // Outlets
      {
        path: "/outlets",
        name: "OutletsPage",
        component: OutletsPage,
      },
      {
        path: "/outlets/upload",
        name: "OutletsUploadPage",
        component: OutletsUploadPage,
      },
      {
        path: "/outlets/create",
        name: "OutletCreatePage",
        component: OutletCreatePage,
      },
      {
        path: "/outlets/:outletId",
        name: "OutletIdPage",
        component: OutletIdPage,
      },
      // OutletUsers
      {
        path: "/outlets/:outletId/outlet-users",
        name: "OutletUsersPage",
        component: OutletUsersPage,
      },
      {
        path: "/outlets/:outletId/outlet-users/create",
        name: "OutletUserCreatePage",
        component: OutletUserCreatePage,
      },
      {
        path: "/outlet-users/:outletUserId",
        name: "OutletUserIdPage",
        component: OutletUserIdPage,
      },
      // Pjps
      {
        path: "/pjps",
        name: "PjpsPage",
        component: PjpsPage,
      },
      {
        path: "/pjps/upload",
        name: "PjpsUploadPage",
        component: PjpsUploadPage,
      },
      {
        path: "/pjps/copy-pjp-references",
        name: "PjpCopyReferencesPage",
        component: PjpCopyReferencesPage,
      },
      {
        path: "/pjps/copy-pjp-prev-month",
        name: "PjpCopyPreviousMonth",
        component: PjpCopyPreviousMonth,
      },
      {
        path: "/pjps/create",
        name: "PjpCreatePage",
        component: PjpCreatePage,
      },
      {
        path: "/pjps/:pjpId",
        name: "PjpIdPage",
        component: PjpIdPage,
      },
      // PjpReferences
      {
        path: "/pjp-references",
        name: "PjpReferencesPage",
        component: PjpReferencesPage,
      },
      {
        path: "/pjp-references/create",
        name: "PjpReferenceCreatePage",
        component: PjpReferenceCreatePage,
      },
      {
        path: "/pjp-references/:pjpReferenceId",
        name: "PjpReferenceIdPage",
        component: PjpReferenceIdPage,
      },
      // UserTimestamps
      {
        path: "/user-timestamps",
        name: "UserTimestamps",
        component: UserTimestamps,
      },
      // Login
      {
        path: "/login/:position",
        name: "LoginPage",
        component: LoginPage,
      },
      // Register
      {
        path: "/register/:position",
        name: "RegisterPage",
        component: RegisterPage,
      },
      // GoogleCallback
      {
        path: "/google/callback",
        name: "GoogleCallback",
        component: GoogleCallback,
      },
      {
        path: "/register/candidate/profile",
        name: "CandidateRegisterPage",
        component: CandidateRegisterPage,
      },
      {
        path: "/register/recruiter/profile",
        name: "RecruiterRegisterPage",
        component: RecruiterRegisterPage,
      },
      // City Jobs
      {
        path: "/city-jobs",
        name: "CityJobsPage",
        component: CityJobsPage,
      },
      // Role Jobs
      {
        path: "/role-jobs",
        name: "RoleJobsPage",
        component: RoleJobsPage,
      },
      // Jobs
      {
        path: "/jobs",
        name: "JobsPage",
        component: JobsPage,
        meta: { role: 0 },
      },
      {
        path: "/jobs/create",
        name: "JobCreatePage",
        component: JobCreatePage,
        meta: { role: 1 },
      },
      // Job Details
      {
        path: "/job-details/:jobDetailId",
        name: "JobDetailPage",
        component: JobDetailPage,
      },
      // Candidate Profile Page
      {
        path: "/candidate-profiles",
        name: "CandidateProfilePage",
        component: CandidateProfilePage,
        meta: { role: 2 },
      },
      // Candidate Preview Page
      {
        path: "/candidate-preview",
        name: "CandidatePreviewPage",
        component: CandidatePreviewPage,
        meta: { role: 2 },
      },
      // Applied Candidates Page
      {
        path: "/applied-candidates/:jobId",
        name: "AppliedCandidatesPage",
        component: AppliedCandidatesPage,
        meta: { role: 1 },
      },
      // Recruiter Profile Page
      {
        path: "/recruiter-profiles",
        name: "RecruiterProfilePage",
        component: RecruiterProfilePage,
        meta: { role: 1 },
      },
      // Saved Jobs Page
      {
        path: "/saved-jobs",
        name: "SavedJobsPage",
        component: SavedJobsPage,
        meta: { role: 2 },
      },
      // Organisation Page
      {
        path: "/organisations/:organisationId",
        name: "OrganisationsPage",
        component: OrganisationsPage,
        meta: { role: 1 },
      },
      {
        path: "/organisations/create",
        name: "OrganisationCreatePage",
        component: OrganisationCreatePage,
        meta: { role: 1 },
      },
      // About Us Page
      {
        path: "/about-us",
        name: "AboutUsPage",
        component: AboutUsPage,
      },
      // Privacy Policy Page
      {
        path: "/privacy-policy",
        name: "PrivacyPolicyPage",
        component: PrivacyPolicyPage,
      },
      // Terms And Conditions Page
      {
        path: "/terms-and-conditions",
        name: "TermsAndConditionsPage",
        component: TermsAndConditionsPage,
      },
      // Contact Us Page
      {
        path: "/contact-us",
        name: "ContactUsPage",
        component: ContactUsPage,
      },
      // Job Applied Candidates
      {
        path: "/job-applied-candidates",
        name: "JobAppliedCandidatesPage",
        component: JobAppliedCandidatesPage,
        meta: { role: 1 },
      },
      // Job Matched Candidates
      {
        path: "/job-matched-candidates",
        name: "JobMatchedCandidatesPage",
        component: JobMatchedCandidatesPage,
        meta: { role: 1 },
      },
      // Premium Page
      {
        path: "/premiums",
        name: "PremiumsPage",
        component: PremiumsPage,
        meta: { role: 1 },
      },
      // Premium Page
      {
        path: "/payment-success/:transactionId",
        name: "PaymentSuccessPage",
        component: PaymentSuccessPage,
      },
    ],
  },
  // End AuthLayout

  // UnAuthLayout
  {
    path: "/unauth",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      // {
      //   path: "/login",
      //   name: "LoginPage",
      //   component: LoginPage,
      // },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      // {
      //   path: "/home",
      //   name: "HomePage",
      //   component: HomePage,
      // },
    ],
  },
  //End UnAuthLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // Use saved position when navigating back/forward
    }
    return { x: 0, y: 0 }; // Scroll to the top-left of the page
  },
});
let initialNavigation = true;
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["auth/authenticated"];
  const userPositionId = store.getters["auth/user"]?.position_id;
  const authRoutes = ["LoginPage", "RegisterPage", "ForgotPasswordPage"];
  const publicRoutes = [
    "HomePage",
    "CandidateRegisterPage",
    "RecruiterRegisterPage",
    "AboutUsPage",
    "PrivacyPolicyPage",
    "TermsAndConditionsPage",
    "ContactUsPage",
    "JobsPage",
    "JobDetailPage",
    "CityJobsPage",
    "RoleJobsPage",
    "OrganisationsPage",
    "GoogleCallback",
  ];

  // Allow initial navigation to HomePage for both logged-in and logged-out users
  if (initialNavigation && to.name === "HomePage") {
    initialNavigation = false; // Reset the flag after initial navigation
    return next();
  }

  if (to.name === "HomePage" && userPositionId === 1) {
    return next({ name: "RecruiterProfilePage" }); // Redirect to a recruiter-specific page
  }

  if (authRoutes.includes(to.name)) {
    if (isAuthenticated && to.name !== "HomePage") {
      return next({ name: "HomePage" });
    }
    return next();
  }

  if (publicRoutes.includes(to.name)) {
    return next(); // Allow access to public routes
  }

  // Redirect unauthenticated users to HomePage || Handle routes with null names ||  Role-based access control
  if (
    !isAuthenticated ||
    to.name === null ||
    (to.meta.role && to.meta.role !== userPositionId)
  ) {
    return next({ name: "HomePage" });
  }
  next();
});

router.afterEach(async (to, from) => {
  if (
    store.getters["auth/authenticated"] &&
    from.name &&
    !to.name.includes("Create") &&
    !to.name.includes("Edit")
  ) {
    let endTime = new Date();
    let startTime = from.params.startTime;
    let timeSpentInSeconds = "0";
    if (startTime) {
      timeSpentInSeconds = (endTime - startTime) / 1000;
    }
    if (timeSpentInSeconds > 1200) {
      timeSpentInSeconds = 1200;
    }
    console.log(
      `Time spent on "${to.name}" route: ${timeSpentInSeconds} seconds`
    );
    let form = {
      from_path: from.path,
      from_name: from.name,
      user_id: store.getters["auth/user"].id,
      timespent: timeSpentInSeconds,
    };
    await axios.post(`/user_timestamps`, form);
  }
});

export default router;
