<template>
  <div>
    <v-app-bar
      app
      color="white"
      class="dont-print-header"
      style="overflow-x: auto; overflow-y: hidden"
    >
      <div class="d-flex align-center ml-2 ml-sm-10">
        <router-link to="/" class="text-decoration-none">
          <v-row>
            <v-img
              v-if="$vuetify.breakpoint.xs"
              alt="Vuetify Name"
              class="mt-1"
              contain
              :src="
                company.logo_path
                  ? `${mediaUrl + company.logo_path}`
                  : require('../../assets/cj.png')
              "
              width="60"
              height="40"
            />
            <v-img
              v-else
              alt="Vuetify Name"
              class="mt-1"
              contain
              :src="
                company.logo_path
                  ? `${mediaUrl + company.logo_path}`
                  : require('../../assets/cj-2.png')
              "
              width="180"
              height="50"
            />
            <!-- <h2 style="color: black">Chotu Jobs</h2> -->
          </v-row>
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <template v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
        <!-- Find a Job -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="user.position_id == 1"
              class="hover-arrow"
              color="black"
              text
              v-bind="attrs"
              v-on="on"
              style="
                text-transform: initial !important;
                width: 160px;
                height: 100%;
              "
              to="/jobs/create"
            >
              Post a jobs
            </v-btn>
            <v-btn
              v-else
              class="hover-arrow"
              color="black"
              text
              v-bind="attrs"
              v-on="on"
              style="
                text-transform: initial !important;
                width: 160px;
                height: 100%;
              "
              to="/jobs"
            >
              Find a jobs
            </v-btn>
          </template>
        </v-menu>

        <!-- About Us -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="hover-arrow"
              color="black"
              text
              v-bind="attrs"
              v-on="on"
              style="
                text-transform: initial !important;
                width: 160px;
                height: 100%;
              "
              to="/about-us"
            >
              About Us
            </v-btn>
          </template>
        </v-menu>

        <!-- Contact Us -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="hover-arrow"
              color="black"
              text
              v-bind="attrs"
              v-on="on"
              style="
                text-transform: initial !important;
                width: 160px;
                height: 100%;
              "
              to="/contact-us"
            >
              Contact Us
            </v-btn>
          </template>
        </v-menu>

        <v-spacer></v-spacer>
      </template>

      <template v-if="user.id == null || user.id == ''">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mr-sm-10"
              v-bind="attrs"
              v-on="on"
              text
            >
              Register
            </v-btn>
          </template>
          <v-list dense width="150">
            <v-list-item to="/register/recruiter">
              <v-icon> mdi-account-tie-hat-outline </v-icon>
              <v-list-item-title class="ml-2">Recruiter</v-list-item-title>
            </v-list-item>
            <v-list-item to="/register/candidate">
              <v-icon> mdi-account-tie-hat-outline </v-icon>
              <v-list-item-title class="ml-2">Candidate</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mr-sm-10"
              v-bind="attrs"
              v-on="on"
            >
              Sign In
            </v-btn>
          </template>
          <v-list dense width="150">
            <v-list-item to="/login/recruiter">
              <v-icon> mdi-account-tie-hat-outline </v-icon>
              <v-list-item-title class="ml-2">Recruiter</v-list-item-title>
            </v-list-item>
            <v-list-item to="/login/candidate">
              <v-icon> mdi-account-tie-hat-outline </v-icon>
              <v-list-item-title class="ml-2">Candidate</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-else>
        <v-text-field
          v-if="user.position_id == 1"
          v-model="user.credits"
          outlined
          hide-details
          dense
          class="mr-4"
          style="max-width: 150px"
          readonly
          prepend-inner-icon="mdi-bitcoin"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div v-if="hide_bell == false">
              <v-avatar color="primary" v-bind="attrs" v-on="on">
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>
            </div>
            <div v-else>
              <v-avatar color="primary" v-bind="attrs" v-on="on">
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>
            </div>
          </template>
          <v-list dense min-width="150">
            <v-list-item to="/recruiter-profiles" v-if="user.position_id == 1">
              <v-icon> mdi-account-tie-hat-outline </v-icon>
              <v-list-item-title class="ml-2">Profile</v-list-item-title>
            </v-list-item>
            <v-list-item to="/candidate-profiles" v-else>
              <v-icon> mdi-account-tie-hat-outline </v-icon>
              <v-list-item-title class="ml-2">Profile</v-list-item-title>
            </v-list-item>
            <v-list-item to="/premiums" v-if="user.position_id == 1">
              <v-icon> mdi-diamond </v-icon>
              <v-list-item-title class="ml-2">Premiums</v-list-item-title>
            </v-list-item>
            <v-list-item to="/about-us" v-if="$vuetify.breakpoint.mdAndDown">
              <v-icon> mdi-information-outline </v-icon>
              <v-list-item-title class="ml-2">About Us</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/privacy-policy"
              v-if="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon> mdi-information-outline </v-icon>
              <v-list-item-title class="ml-2">Privacy Policy</v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/terms-and-conditions"
              v-if="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon> mdi-information-outline </v-icon>
              <v-list-item-title class="ml-2"
                >Terms and Conditions</v-list-item-title
              >
            </v-list-item>
            <v-list-item to="/contact-us" v-if="$vuetify.breakpoint.mdAndDown">
              <v-icon> mdi-card-account-phone-outline </v-icon>
              <v-list-item-title class="ml-2">Contact Us</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-icon> mdi-exit-run </v-icon>
              <v-list-item-title class="ml-2">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <router-view />
  </div>
</template>

<script type="text/javascript">
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        search_keyword: "",
      },
      hide_bell: false,
      companies: [],
    };
  },
  // mounted() {
  //   let company = store.getters["auth/company"];
  // },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
      location.replace("/");
    },
  },
};
</script>
<style scoped>
.nav-text {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding: 0 8px 0 20px;
}
</style>
