<template>
  <v-container fluid v-if="isPgLoading == false">
    <v-row>
      <v-col sm="6">
        <!-- First Name -->
        <label class="subtitle-1 font-weight-bold"> First Name * </label>
        <v-text-field
          placeholder="Enter First Name"
          v-model="form.first_name"
          outlined
          :error-messages="errors.first_name"
          @keypress="onlyAlphabets"
        ></v-text-field>

        <!-- Middle Name -->
        <label class="subtitle-1 font-weight-bold"> Middle Name * </label>
        <v-text-field
          v-model="form.middle_name"
          placeholder="Enter Middle Name"
          outlined
          :error-messages="errors.middle_name"
          @keypress="onlyAlphabets"
        ></v-text-field>

        <!-- Last Name -->
        <label class="subtitle-1 font-weight-bold"> Last Name * </label>
        <v-text-field
          v-model="form.last_name"
          placeholder="Enter Last Name"
          outlined
          :error-messages="errors.last_name"
          @keypress="onlyAlphabets"
        ></v-text-field>

        <!-- Email -->
        <label class="subtitle-1 font-weight-bold"> Email * </label>
        <v-text-field
          v-model="form.email"
          placeholder="Enter Email"
          outlined
          :error-messages="errors.email"
        ></v-text-field>

        <!-- Contact Number -->
        <label class="subtitle-1 font-weight-bold"> Contact Number * </label>
        <v-text-field
          v-model="form.phone"
          placeholder="Enter Contact Number"
          outlined
          :error-messages="errors.phone"
        ></v-text-field>

        <!-- Current Salary -->
        <label class="subtitle-1 font-weight-bold"> Current Salary * </label>
        <v-text-field
          v-model="form.current_salary"
          placeholder="Enter Current Salary"
          outlined
          :error-messages="errors.current_salary"
        ></v-text-field>

        <!-- Qualification -->
        <label class="subtitle-1 font-weight-bold"> Qualification </label>
        <v-autocomplete
          outlined
          v-model="form.qualification_id"
          placeholder="Enter Qualification"
          :items="qualificationItems"
          clearable
          :error-messages="errors.qualification_id"
        ></v-autocomplete>

        <!-- Bio -->
        <label class="subtitle-1 font-weight-bold"> Bio </label>
        <v-textarea
          v-model="form.about_me"
          outlined
          placeholder="Bio"
        ></v-textarea>

        <v-row>
          <!-- State -->
          <v-col style="padding: 0 12px" cols="12" sm="6">
            <label class="subtitle-1 font-weight-bold"> State </label>
            <v-autocomplete
              ref="state"
              outlined
              v-model="form.state_id"
              :items="stateItems"
              placeholder="Select State"
              clearable
              @change="getCity"
              :error-messages="errors.state_id"
            ></v-autocomplete>
          </v-col>

          <!-- City -->
          <v-col style="padding: 0 12px" cols="12" sm="6">
            <label class="subtitle-1 font-weight-bold"> City </label>
            <v-autocomplete
              ref="city"
              outlined
              v-model="form.city_id"
              :items="cityItems"
              placeholder="Select City"
              clearable
              @change="getCityLocality"
              :error-messages="errors.city_id"
            ></v-autocomplete>
          </v-col>

          <!-- City Locality -->
          <v-col style="padding: 0 12px" cols="12" sm="6">
            <label class="subtitle-1 font-weight-bold"> City Locality </label>
            <v-autocomplete
              ref="cityLocality"
              outlined
              v-model="form.city_locality_id"
              :items="cityLocalityItems"
              placeholder="Select City Locality"
              clearable
              :error-messages="errors.city_locality_id"
            ></v-autocomplete>
          </v-col>

          <!-- Pincode -->
          <v-col style="padding: 0 12px" cols="12" sm="6">
            <label class="subtitle-1 font-weight-bold"> Pincode</label>
            <v-text-field
              v-model="form.pincode"
              placeholder="Enter Pincode"
              outlined
              :error-messages="errors.pincode"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>

        <!-- Password -->
        <label class="subtitle-1 font-weight-bold"> Password</label>
        <v-text-field
          v-model="form.password"
          placeholder="Enter Password"
          outlined
          :error-messages="errors.password"
        ></v-text-field>

        <!-- Re-Password -->
        <label class="subtitle-1 font-weight-bold"> Re-Password</label>
        <v-text-field
          v-model="form.password_confirmation"
          placeholder="Enter Re-Password"
          outlined
          :error-messages="errors.password_confirmation"
        ></v-text-field>
      </v-col>

      <v-col sm="6">
        <div>
          <!-- Category -->
          <AutocompleteChip
            title="Category"
            note="You can add up to 5 Categories"
            :selectedItems="catSelected"
            :items="categories"
            @add-category="addChip"
            @remove-chip="removeChip"
          />
        </div>

        <!-- Preferred Location -->
        <div class="mt-8">
          <AutocompleteChip
            title="Preferred Location"
            note="You can add up to 5 Location"
            :selectedItems="preferredLocSelected"
            :items="preferredLocations"
            @add-category="addChip"
            @remove-chip="removeChip"
          />
        </div>

        <!-- Skills -->
        <div class="mt-8">
          <AutocompleteChip
            title="Skills"
            note="You can add up to 15 Skills"
            :selectedItems="skillSelected"
            :items="skills"
            @add-category="addChip"
            @remove-chip="removeChip"
          />
        </div>

        <!-- Upload Resume -->
        <v-card class="mt-8">
          <v-card-text>
            <v-row>
              <v-col class="text-h5 font-weight-bold"> Resume Upload </v-col>
            </v-row>

            <!-- Autocomplete -->
            <v-row align="center" justify="start" class="mt-2">
              <v-col cols="12">
                <v-file-input
                  outlined
                  type="file"
                  v-model="form.file_path"
                  truncate-length="50"
                  placeholder="Upload Resume file here"
                ></v-file-input>
              </v-col>
            </v-row>

            <v-btn
              text
              :href="`${mediaUrl + form.resume_file_path}`"
              color="primary"
              target="_blank"
            >
              <v-icon>mdi-download</v-icon>
              Download your uploaded resume
            </v-btn>
          </v-card-text>
        </v-card>

        <!-- Work Experience -->
        <v-card class="mt-8">
          <v-card-text>
            <v-row align="center">
              <v-col class="text-h5 font-weight-bold"> Work Experience </v-col>
              <!-- Add Work Experience Button -->
              <v-col>
                <v-btn color="primary" @click="openDialog">
                  Add Work Experience
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-for="(workExp, index) in form.work_experiences"
                :key="index"
                cols="12"
              >
                <v-card class="mt-4" elevation="3">
                  <v-card-title class="bg-primary text-white">
                    Work Experience {{ index + 1 }}
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <p class="text-body-1">
                          <strong>Company:</strong> {{ workExp.company_name }}
                        </p>
                        <p class="text-body-1">
                          <strong>Location:</strong>
                          {{ workExp.city?.name ?? workExp.city_id }}
                        </p>
                        <p class="text-body-1">
                          <strong>Job Title:</strong> {{ workExp.title }}
                        </p>
                        <p class="text-body-1">
                          <strong>Job Role:</strong>
                          {{ workExp.category?.name ?? workExp.category_id }}
                        </p>
                        <p class="text-body-1">
                          <strong>Skills:</strong>
                          {{
                            workExp.skills
                              ?.map((skill) => skill.name)
                              .join(", ") || "N/A"
                          }}
                        </p>
                      </v-col>
                    </v-row>

                    <v-divider class="my-2"></v-divider>

                    <v-row>
                      <v-col cols="6">
                        <p class="text-body-1">
                          <strong>Start Date:</strong> {{ workExp.start_date }}
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <p class="text-body-1">
                          <strong>End Date:</strong>
                          {{ workExp.end_date || "Present" }}
                        </p>
                      </v-col>
                    </v-row>

                    <p class="text-body-1">
                      <strong>Salary:</strong> {{ workExp.salary }}
                    </p>

                    <p class="text-body-1">
                      <strong>Currently Working:</strong>
                      {{ workExp.working_status ? "Yes" : "No" }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!-- Dialog for Adding Work Experience -->
            <v-dialog v-model="dialog" max-width="80%">
              <v-card>
                <v-card-title class="text-h5">Add Work Experience</v-card-title>
                <v-card-text>
                  <v-form ref="workExpForm">
                    <v-row>
                      <!-- Company Name -->
                      <v-col cols="12" sm="6">
                        <label class="subtitle-1 font-weight-bold">
                          Company Name
                        </label>
                        <v-text-field
                          placeholder="Enter Company Name"
                          v-model="currentWorkExp.company_name"
                          outlined
                          hide-details="auto"
                          :dense="$vuetify.breakpoint.mdAndDown"
                          :error-messages="errors.company_name"
                        ></v-text-field>
                      </v-col>

                      <!-- Location -->
                      <v-col sm="6">
                        <label class="subtitle-1 font-weight-bold">
                          Location
                        </label>
                        <v-autocomplete
                          ref="city"
                          outlined
                          v-model="currentWorkExp.city_id"
                          :items="cityItems"
                          placeholder="Select City"
                          clearable
                          hide-details="auto"
                          :dense="$vuetify.breakpoint.mdAndDown"
                          :error-messages="errors.city_id"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Job Title -->
                      <v-col cols="12" sm="6">
                        <label class="subtitle-1 font-weight-bold">
                          Job Title
                        </label>
                        <v-text-field
                          placeholder="Enter Job Title"
                          v-model="currentWorkExp.title"
                          outlined
                          hide-details="auto"
                          :dense="$vuetify.breakpoint.mdAndDown"
                          :error-messages="errors.title"
                        ></v-text-field>
                      </v-col>

                      <!-- Job Role -->
                      <v-col sm="6">
                        <label class="subtitle-1 font-weight-bold">
                          Job Role
                        </label>
                        <v-autocomplete
                          ref="city"
                          outlined
                          v-model="currentWorkExp.category_id"
                          :items="categories"
                          placeholder="Select City"
                          clearable
                          hide-details="auto"
                          :dense="$vuetify.breakpoint.mdAndDown"
                          :error-messages="errors.category_id"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Start Date -->
                      <v-col cols="12" sm="6">
                        <label class="subtitle-1 font-weight-bold">
                          Start Date
                        </label>
                        <v-menu
                          v-model="start_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="currentWorkExp.start_date"
                              placeholder="Start Date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              hide-details="auto"
                              @click:clear="currentWorkExp.start_date = null"
                              :dense="$vuetify.breakpoint.mdAndDown"
                              :error-messages="errors.start_date"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentWorkExp.start_date"
                            @change="start_date_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- End Date -->
                      <v-col cols="12" sm="6">
                        <label class="subtitle-1 font-weight-bold">
                          End Date
                        </label>
                        <v-menu
                          v-model="end_date_menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="currentWorkExp.end_date"
                              placeholder="Start Date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              hide-details="auto"
                              @click:clear="currentWorkExp.end_date = null"
                              :dense="$vuetify.breakpoint.mdAndDown"
                              :error-messages="errors.end_date"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentWorkExp.end_date"
                            @change="end_date_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- Skills -->
                      <v-col cols="12">
                        <AutocompleteChip
                          title="Work Experience Skills"
                          note="You can add up to 15 Skills"
                          :selectedItems="workExpSkillSelected"
                          :items="workExpSkills"
                          @add-category="addChip"
                          @remove-chip="removeChip"
                        />
                      </v-col>

                      <!-- Salary -->
                      <v-col cols="12" sm="6">
                        <label class="subtitle-1 font-weight-bold">
                          Salary
                        </label>
                        <v-text-field
                          placeholder="Enter Salary"
                          v-model="currentWorkExp.salary"
                          outlined
                          hide-details="auto"
                          :dense="$vuetify.breakpoint.mdAndDown"
                          :error-messages="errors.salary"
                        ></v-text-field>
                      </v-col>

                      <!-- Currently Working -->
                      <v-col cols="12" sm="6">
                        <label class="subtitle-1 font-weight-bold"
                          >Are You Currently Working Here?</label
                        >
                        <br />
                        <v-btn-toggle
                          v-model="currentWorkExp.working_status"
                          style="width: 100%"
                        >
                          <v-row>
                            <v-col>
                              <v-btn
                                value="Yes"
                                outlined
                                block
                                :small="$vuetify.breakpoint.mdAndDown"
                              >
                                Yes
                              </v-btn>
                            </v-col>
                            <v-col>
                              <v-btn
                                value="No"
                                outlined
                                block
                                :small="$vuetify.breakpoint.mdAndDown"
                              >
                                No
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="py-4">
                  <v-btn color="primary" @click="addWorkExperience">Save</v-btn>
                  <v-btn text @click="closeDialog">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../components/loader-circle.vue";
import AutocompleteChip from "../components/autocomplete-chip.vue";
export default {
  components: { Loader, AutocompleteChip },
  name: "CandidateIdPage",
  data() {
    return {
      profile_section: "My Profile",
      form: {
        work_experiences: [],
      },
      userData: {},

      jobTitleItems: [],
      qualificationItems: [],
      stateItems: [],
      cityItems: [],
      cityLocalityItems: [],

      catSelected: [],
      categories: [],

      preferredLocSelected: [],
      preferredLocations: [],

      skillSelected: [],
      skills: [],

      workExpSkillSelected: [],
      workExpSkills: [],
      isPgLoading: false,

      dialog: false,
      start_date_menu: false,
      end_date_menu: false,
      workExperiences: [],
      currentWorkExp: {
        company_name: "",
        city_id: "",
        title: "",
        category_id: "",
        skills: [],
        start_date: "",
        end_date: "",
        salary: "",
        working_status: "No",
      },
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    openDialog() {
      this.resetCurrentWorkExp();
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    addWorkExperience() {
      // Validate required fields
      // this.errors = {};//
      // const requiredFields = [
      //   "company_name",
      //   "city_id",
      //   "title",
      //   "start_date",
      //   "end_date",
      // ];
      // requiredFields.forEach((field) => {
      //   if (!this.currentWorkExp[field]) {
      //     this.errors[field] = "This field is required.";
      //   }
      // });

      // Add to form.work_experiences array
      this.currentWorkExp.skills = this.workExpSkillSelected.map(
        (skill) => skill.id
      );
      this.form.work_experiences.push({ ...this.currentWorkExp });
      this.dialog = false; // Close dialog
      console.log("this.form.work_experiences", this.form.work_experiences);
      this.resetCurrentWorkExp(); // Reset the form
    },

    resetCurrentWorkExp() {
      this.currentWorkExp = {
        company_name: "",
        city_id: "",
        title: "",
        category_id: "",
        skills: [],
        start_date: "",
        end_date: "",
        salary: "",
        working_status: 0,
      };
      this.workExpSkillSelected = [];
    },
    resetForm() {
      // Reset the form
      this.form = {
        company_name: "",
        city_id: "",
        title: "",
        category_id: "",
        skills: [],
        start_date: "",
        end_date: "",
        salary: "",
        working_status: 0,
        file_path: null,
      };
    },
    async getMasters() {
      this.isPgLoading = true;
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
          });
          //City
          masters.cities.forEach((city) => {
            this.preferredLocations.push({
              id: city.id,
              text: city.name,
              value: city.name,
            });
          });
          //Qualification
          masters.qualifications.forEach((qualification) => {
            this.qualificationItems.push({
              id: qualification.id,
              text: qualification.description,
              value: qualification.id,
            });
          });
          //Categories
          masters.categories.forEach((cat) => {
            this.categories.push({
              id: cat.id,
              text: cat.name,
              value: cat.id,
            });
          });
          //Skills
          masters.skills.forEach((skill) => {
            this.skills.push({
              id: skill.id,
              text: skill.name,
              value: skill.name,
            });
            this.workExpSkills.push({
              id: skill.id,
              text: skill.name,
              value: skill.name,
            });
          });

          this.isPgLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },

    async getData() {
      this.isLoading = true;
      let encryptedId = this.encryptIt(this.user.id);
      axios
        .get(`/users/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.userData = response.data.data;
          this.getCity();
          this.getCityLocality();

          //Category
          this.form.category_experiences.forEach((cat) => {
            this.catSelected.push({
              id: cat.category.id,
              text: cat.category.name,
              value: cat.category.name,
            });
          });

          //Skills
          this.form.skills.forEach((skill) => {
            this.skillSelected.push({
              id: skill.id,
              text: skill.name,
              value: skill.name,
            });
          });

          //Skills
          this.form.skills.forEach((skill) => {
            this.workExpSkillSelected.push({
              id: skill.id,
              text: skill.name,
              value: skill.name,
            });
          });

          //City
          this.preferredLocSelected = [
            {
              id: this.form.city.id,
              text: this.form.city.name,
              value: this.form.city.name,
            },
          ];
          this.old_json = response.data.data;
        })
        .catch((error) => {
          // redirect to Home Page
          //   window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async getCity() {
      await axios
        .get(`/cities?state_id=${this.form.state_id}`)
        .then((response) => {
          const cities = response.data.data;
          this.cityItems = [];
          cities.forEach((city) => {
            this.cityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async getCityLocality() {
      await axios
        .get(`/city_localities?city_id=${this.form.city_id}`)
        .then((response) => {
          const cityLocalities = response.data.data;
          this.cityLocalityItems = [];
          cityLocalities.forEach((cityLocality) => {
            this.cityLocalityItems.push({
              id: cityLocality.id,
              text: cityLocality.name,
              value: cityLocality.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        await axios
          .post(`/users`, this.form)
          .then(async () => {
            await this.saveUserTimestamp(this.old_json, this.form);
            this.getData();
            this.handleFileUpload();
          })
          .catch((error) => {
            // redirect to Home Page
            // window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
        this.isLoading = false;
        if (this.$route.name == "ProfilePage") {
          // this.$router.push("/supervisor");
          window.location.href = "/auth/login";
        }
      } catch (e) {
        console.error("Error fetching data:", e);
      }
    },
    async handleFileUpload() {
      let formData = new FormData();
      this.file_attachment = this.form.file_path;
      formData.append("user_id", this.form.id);
      formData.append("file_path", this.file_attachment);
      await axios
        .post("upload_user_images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
      this.isUploading = false;
    },
    addChip(title, value) {
      if (title == "Category" && this.catSelected.length < 5) {
        this.catSelected.push(value);
      } else if (
        title == "Preferred Location" &&
        this.preferredLocSelected.length < 5
      ) {
        this.preferredLocSelected.push(value);
      } else if (title == "Skills" && this.skillSelected.length < 15) {
        this.skillSelected.push(value);
      } else if (
        title == "Work Experience Skills" &&
        this.workExpSkillSelected.length < 15
      ) {
        this.workExpSkillSelected.push(value);
      }
    },
    removeChip(title, index) {
      if (title == "Category") {
        this.catSelected.splice(index, 1);
      } else if (title == "Preferred Location") {
        this.preferredLocSelected.splice(index, 1);
      } else if (title == "Skills") {
        this.skillSelected.splice(index, 1);
      } else if (title == "Work Experience Skills") {
        this.workExpSkillSelected.splice(index, 1);
      }
    },
  },
};
</script>
