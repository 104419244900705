var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('v-container',{staticClass:"py-8 py-sm-12 px-5",staticStyle:{"background":"#1f8bff","color":"white"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"font-weight-bold text-h5 text-md-h3",staticStyle:{"font-size":"42px"}},[_c('span',[_vm._v("Explore Jobs By Cities")])])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"pa-3 pa-sm-5",attrs:{"elevation":"1"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",staticStyle:{"padding":"0 0 0 20px"},attrs:{"cols":"10","sm":"9"}},[_c('v-text-field',{staticClass:"search-textfield",attrs:{"dense":"","solo":"","label":"Your Keyword","prepend-inner-icon":"mdi-keyboard-variant","hide-details":""},model:{value:(_vm.filter.search_keyword),callback:function ($$v) {_vm.$set(_vm.filter, "search_keyword", $$v)},expression:"filter.search_keyword"}})],1),_c('v-col',{staticClass:"d-none d-sm-flex",staticStyle:{"padding":"0 20px"},attrs:{"sm":"3"}},[_c('v-btn',{attrs:{"color":"primary","large":"","block":""},on:{"click":_vm.getData}},[_c('v-icon',{attrs:{"left":"","size":"26"}},[_vm._v("mdi-magnify")]),_vm._v("Search ")],1)],1),_c('v-col',{staticClass:"d-sm-none",staticStyle:{"padding":"0 12px"},attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","large":"","icon":""},on:{"click":_vm.getData}},[_c('v-icon',{attrs:{"left":"","size":"26"}},[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center text-caption text-sm-body-1",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v("Popular Searches: ")]),_vm._l(([
            'Mumbai',
            'Pune',
            'Hyderabad',
            'Bangalore',
            'Delhi',
            'Chennai',
          ]),function(item,index){return _c('span',{key:index,staticClass:"text-decoration-underline mx-1",staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(item)),(index < 5)?_c('span',[_vm._v(",")]):_vm._e()])})],2)],1)],1),_c('v-container',{staticClass:"py-5"},[(!_vm.isLoading)?[_c('v-row',{attrs:{"justify":"center","align":"start"}},_vm._l((_vm.states),function(state){return _c('v-col',{key:state.name,staticClass:"text-center",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"font-weight-bold d-flex justify-center",staticStyle:{"padding":"8px 16px"}},[_vm._v(" "+_vm._s(state.name)+" ")]),_c('v-divider'),_c('v-card-text',{staticStyle:{"padding":"0px 16px"}},[_c('v-list',{attrs:{"dense":""}},[_vm._l((state.cities),function(city,index){return [_c('v-list-item',{key:index,attrs:{"to":`/jobs?city=${city.id}`}},[_c('v-list-item-title',{staticClass:"text-body-1",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(city.name))}})],1),(index < state.cities.length - 1)?_c('v-divider',{key:index,staticStyle:{"border-color":"rgba(0, 0, 0, 0.099)"}}):_vm._e()]})],2)],1)],1)],1)}),1)]:[_c('v-row',_vm._l((4),function(n){return _c('v-col',{key:'skeleton-' + n,staticClass:"text-center",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticStyle:{"padding":"16px 16px 8px"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"height":"20","width":"70%","type":"text"}})],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"padding":"0px 16px"}},[_c('v-list',{staticClass:"text-center",attrs:{"dense":""}},_vm._l((5),function(i){return _c('v-skeleton-loader',{key:'skeleton-item-' + i,staticClass:"mx-auto",staticStyle:{"margin":"16px 0"},attrs:{"type":"text","height":"15","width":"80%"}})}),1)],1)],1)],1)}),1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }