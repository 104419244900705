<template>
  <v-main>
    <v-container class="px-5 px-sm-16 mt-8" fluid>
      <v-row class="pa-0" align="center" justify="space-between">
        <!-- Left Section: Job Info -->
        <v-col>
          <v-row align-content="center">
            <v-col cols="auto">
              <v-avatar
                size="100"
                color="secondary"
                class="d-flex align-center justify-center"
                style="border: 1px solid #1f8bff"
              >
                <v-img :src="mediaUrl + organisation.logo_path" contain></v-img>
              </v-avatar>
            </v-col>
            <v-col align-self="center">
              <h1 class="font-weight-bold text-start text-h5 text-sm-h4">
                {{ organisation.name }}
              </h1>
              <v-row dense align="center">
                <v-icon size="18" color="#1C1B1F">
                  mdi-map-marker-outline
                </v-icon>
                <div class="text-caption grey--text text--darken-2">
                  {{ organisation.city_locality?.name }},
                  {{ organisation.city?.name }}
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <!-- Right Section: Apply Now Button -->
        <!-- <v-col class="d-flex align-center justify-end">
          <v-btn
            color="primary"
            class="py-5"
            @click="is_job_app_login = true"
            large
          >
            <v-icon left size="20">mdi-phone-outline</v-icon>
            Contact Us
          </v-btn>
        </v-col> -->
      </v-row>
      <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>
      <v-row>
        <!-- Left Body Content -->
        <v-col sm="9">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <h2>About Us</h2>
                  <p>
                    {{organisation.about}}
                  </p>
                </v-col>
                <!-- <v-col sm="12">
                  <h2>Essential Knowledge, Skills, and Experience</h2>
                  <ul v-for="(skill, index) in skills" :key="index">
                    <li>{{ skill }}</li>
                  </ul>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Right Body Content -->
        <v-col sm="3">
          <v-card>
            <v-card-title>Overview</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div
                v-for="(overviewList, olIndex) in overviewLists"
                :key="olIndex"
              >
                <v-row dense>
                  <v-col cols="auto" align-self="center" class="mr-1">
                    <v-avatar size="24">
                      <v-icon>{{ overviewList.logo }}</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <div class="text-subtitle-1 text-secondary">
                      {{ overviewList.name }}
                    </div>
                    <div class="text-subtitle-2 font-weight-bold">
                      {{ overviewList.value }}
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-row dense>
                <v-col>
                  <ul>
                    <li>{{ organisation.address }}</li>
                    <li>Phone: {{ organisation.phone }}</li>
                    <li>Email: {{ organisation.email }}</li>
                  </ul>
                </v-col>
              </v-row>

              <!-- <v-row class="mt-4">
                <v-col>
                  <v-btn block color="primary" dark>
                    <v-icon left>mdi-email-outline</v-icon>
                    Send Message
                  </v-btn>
                </v-col>
              </v-row> -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>
    <v-container class="px-5 px-sm-16" fluid>
      <!-- Similar Jobs -->
      <h1>Similar Jobs</h1>
      <v-row>
        <v-col sm="3" v-for="(jobData, jobIndex) in jobItems" :key="jobIndex">
          <JobCard :job="jobData" />
        </v-col>
      </v-row>
    </v-container>

    <div v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
      <v-divider
        style="border: 1px solid #d7d7d7"
        class="mt-16 mb-10"
      ></v-divider>
      <!-- Newsletter Layout -->
      <v-container class="px-8" fluid>
        <NewsLetter />
      </v-container>
    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import NewsLetter from "../components/news-letter.vue";
import JobCard from "../components/job-card.vue";
export default {
  components: { NewsLetter, JobCard },
  name: "OrganisationsPage",
  data() {
    return {
      filter: {
        show_deleted: 0,
        is_active: "",
        organisation_id: "",
      },
      form: {},
      organisation: {},
      jobs: [],
      jobItems: [],
      skills: ["React Js", "Next Js", "Java"],
      overviewLists: [
        {
          logo: "mdi-account",
          name: "Company Field",
          value: "Accounting / Finance",
        },
        {
          logo: "mdi-map-marker",
          name: "Location",
          value: "Mumbai, Pune, Remote Friendly",
        },
        { logo: "mdi-school", name: "Job Posted", value: "10" },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      axios
        .get(`/organisations/${this.$route.params.organisationId}`)
        .then((response) => {
          this.organisation = response.data.data;
          this.getSimilarJobData();
          this.old_json = response.data.data;
        })
        .catch((error) => {
          // redirect to Home Page
          //   window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post(`/users`, this.form)
          .then(async () => {
            await this.saveUserTimestamp(this.old_json, this.form);
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
        this.isLoading = false;
        if (this.$route.name == "ProfilePage") {
          // this.$router.push("/supervisor");
          window.location.href = "/auth/login";
        } else {
          this.$router.push("/users");
        }
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getSimilarJobData() {
      this.isLoading = true;
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.organisation_id = this.organisation.id
        ? this.organisation.id
        : "";

      let query = new URLSearchParams({
        page: this.page,
        rowsPerPage: this.rowsPerPage,
        ...this.filter, // Use the existing filter object directly
      });
      await axios
        .get(`/jobs?${query}`)
        .then((response) => {
          this.jobItems = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
  },
};
</script>
