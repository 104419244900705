<template>
  <v-main>
    <v-container style="max-width: 600px">
      <!-- Contact Us and Get in Touch Section -->
      <v-row class="d-flex flex-column align-start mb-6">
        <v-col>
          <div class="text-primary text-subtitle-1 text-capitalize">
            Contact Us
          </div>
          <div class="text-h4 font-weight-bold text-capitalize mb-2">
            Get In Touch
          </div>
          <div class="text-body-1 text-secondary">
            The right move at the right time saves your investment. Live the
            dream of expanding your business.
          </div>
          <div class="mt-4">
            <p class="text-body-1" style="margin-bottom: 0">
              <strong>Email:</strong> kirit.sayani@pousse.in
            </p>
            <p class="text-body-1" style="margin-bottom: 0">
              <strong>Phone:</strong> +91 98207 04909
            </p>
          </div>
        </v-col>
      </v-row>

      <!-- Input Fields Section -->
      <v-row class="d-flex flex-wrap gap-4">
        <v-col cols="6">
          <v-text-field
            label="Enter Your Name"
            v-model="form.name"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Your Email"
            v-model="form.email"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Company (optional)"
            v-model="form.company_name"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Phone Number"
            v-model="form.phone"
            hide-details
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Description"
            v-model="form.descriptions"
            hide-details
            outlined
            rows="8"
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- Send Message Section -->
      <v-row class="d-flex flex-column align-start">
        <v-col>
          <v-btn
            color="primary"
            block
            class="text-uppercase"
            large
            :disabled="form.agree_terms_policy == 0"
            :loading="isLoading"
            @click="save"
          >
            Send Message
          </v-btn>
          <v-checkbox
            v-model="form.agree_terms_policy"
            label="By clicking contact us button, you agree our terms and policy"
            class="mt-2"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
      <v-divider
        style="border: 1px solid #d7d7d7"
        class="mt-16 mb-10"
      ></v-divider>
      <!-- Newsletter Layout -->
      <v-container class="px-8" fluid>
        <NewsLetter />
      </v-container>
    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import NewsLetter from "../components/news-letter.vue";
export default {
  components: { NewsLetter },
  name: "ContactForm",
  data() {
    return {
      form: {
        agree_terms_policy: 0,
        name: "",
        company_name: "",
        email: "",
        phone: "",
        descriptions: "",
      },
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        const get_in_touch = await axios.post("/get_in_touch", this.form);
        await this.saveUserTimestamp(null, this.form);
        if (get_in_touch) {
          this.$toast.success("Your requested sended successfully!", {
            position: "top-right",
            timeout: 3000,
          });
        }
        this.form = {
          agree_terms_policy: 0,
          name: "",
          company_name: "",
          email: "",
          phone: "",
          descriptions: "",
        };
        this.isLoading = false;
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.text-primary {
  color: #1f8bff;
}
.text-secondary {
  color: #606060;
}
</style>
