<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Copy Pjp from Previous Month
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="user"
                outlined
                v-model="form.user_id"
                :items="userItems"
                label="User"
                prepend-inner-icon="mdi-account-tie-hat-outline"
                clearable
                :error-messages="errors.user_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.month_year"
                    label="from Previous Month"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.month_year = null"
                    :error-messages="errors.month_year"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.month_year"
                  type="month"
                  @change="date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Copy Pjp
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "PjpCreatePage",
  data() {
    return {
      form: {
        user_id: "",
        month_year: "",
      },
      userItems: [],
      isDeleteOutletDialogs: [],
      date_menu: false,
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let requirements = ["pjp_references"];
      let query = "requirements=" + requirements;
      await axios
        .get(`pjps/masters?${query}`)
        .then((masters) => {
          masters = masters.data;
          //Users
          masters.users.forEach((user) => {
            this.userItems.push({
              id: user.id,
              text: user.user_name,
              value: user.id,
            });
          });
          //Outlets
          masters.outlets.forEach((outlet) => {
            this.outletItems.push({
              id: outlet.id,
              text: outlet.name,
              value: outlet.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("pjps/copy_pjp_from_prev_month", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            // this.$router.push("/pjps");
          })
          .catch((error) => {
            this.isLoading = false;
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
