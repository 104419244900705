<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3
                class="font-weight-bold"
                v-text="isDeletePage() != true ? 'Users' : 'Deleted Users'"
              ></h3>
              <section v-if="isDeletePage() != true">
                <v-btn
                  class="mx-2 mb-2"
                  fab
                  x-small
                  color="primary"
                  to="/users/create"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
                <v-btn
                  class="mx-2 mb-2"
                  fab
                  x-small
                  color="primary"
                  to="/users/upload"
                >
                  <v-icon dark> mdi-upload </v-icon>
                </v-btn>
              </section>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    solo
                    rounded
                    v-model="filter.search_keyword"
                    label="Search ... "
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  ></v-text-field>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    solo
                    rounded
                    ref="position"
                    v-model="filter.position_id"
                    :items="positions"
                    label="Position"
                    @input="(page = 1), getData()"
                    prepend-inner-icon="mdi-account"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col sm="3">
                  <v-autocomplete
                    ref="status"
                    solo
                    rounded
                    v-model="filter.is_active"
                    :items="statuses"
                    label="Status"
                    @input="(page = 1), getData()"
                    prepend-inner-icon="mdi-toggle-switch"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title v-if="users.length != 0">
              <v-spacer></v-spacer>
              <v-btn
                rounded
                class="primary mx-4"
                @click="generateExcel()"
                v-if="isDeletePage() != true"
              >
                <span v-if="isExcelloading"> Generating Excel... </span>
                <span v-else>Generate Excel</span>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <section v-if="isLoading" style="height: 285px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                height="285"
                class="striped-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">First Name</th>
                      <th class="text-left">Middle Name</th>
                      <th class="text-left">Last Name</th>
                      <th class="text-left">User Name</th>
                      <th class="text-left">Email</th>
                      <th class="text-left">Phone</th>
                      <th class="text-left">Position</th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="users.length != 0">
                    <tr v-for="(user, i) in users" :key="`user${i}`">
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="user.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="teal"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15"
                                  >mdi-checkbox-marked-circle</v-icon
                                >
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>User is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="user.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="red"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-close-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>User is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>{{ user.first_name || "" }}</td>
                      <td>{{ user.middle_name || "" }}</td>
                      <td>{{ user.last_name || "" }}</td>
                      <td>{{ user.user_name || "" }}</td>
                      <td>{{ user.email || "" }}</td>
                      <td>{{ user.phone || "" }}</td>
                      <td>
                        {{ user.position ? user.position.name : "" }}
                      </td>

                      <td class="text-right">
                        <template v-if="isDeletePage() != true">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                x-small
                                class="my-auto"
                                color="error"
                                @click="Delete_this(user.id)"
                              >
                                <v-icon dark> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                            <span>Delete this user</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2"
                                fab
                                x-small
                                color="primary"
                                :to="`/users/${encryptIt(user.id)}`"
                              >
                                <v-icon dark> mdi-pencil </v-icon>
                              </v-btn>
                            </template>
                            <span>Edit this user</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                x-small
                                class="my-auto"
                                color="error"
                                @click="Restore_this(user.id)"
                              >
                                <v-icon dark> mdi-delete-restore </v-icon>
                              </v-btn>
                            </template>
                            <span>Restore this user</span>
                          </v-tooltip>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="12" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-pagination v-model="page" :length="paginationLength">
              </v-pagination>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_user" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>User deleted successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_deleted_user = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Restore Success Dialog Box -->
      <v-dialog v-model="is_restored_user" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-restore
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>User restored successfully.</h3>
                      <br />
                      <v-btn color="success" @click="is_restored_user = false">
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "usersPage",
  data() {
    return {
      filter: {
        active: "",
        supervisor_id: "",
        role_id: "",
        position_id: "",
        show_deleted: false,
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      is_deleted_user: false,
      restore_user: false,
      is_restored_user: false,
      users: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      positions: [
        {
          id: 0,
          text: "Select Position",
          value: "",
        },
      ],
      isLoading: false,
      isExcelloading: false,
      ExportUsers: [],
      Export_users: [],
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
        Email: "email",
        Phone: "phone",
        Supervisor: "supervisor",
        "Is Active": "status",
      },
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Position
          masters.positions.forEach((position) => {
            this.positions.push({
              id: position.id,
              text: position.name,
              value: position.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async getData() {
      console.log("Get Data");
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.position_id = this.filter.position_id
        ? this.filter.position_id
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&role_id=3" +
        "&show_deleted=" +
        this.filter.show_deleted +
        "&is_active=" +
        this.filter.is_active +
        "&position_id=" +
        this.filter.position_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`users/search_via_SP?${query}`)
        .then((response) => {
          this.users = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    async Delete_this(id) {
      this.delete_user = true;
      await axios
        .post(`/users/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_user = false;
          this.is_deleted_user = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async addToExcelData() {
      this.isExcelLoading = true;
      this.title = "Company Lists";
      let Export_users = [];
      this.search_keyword = this.search_keyword ? this.search_keyword : "";
      let query =
        "search_keyword=" +
        this.search_keyword +
        "&is_active=" +
        this.filter.active +
        "&roleId=" +
        this.filter.role_id;
      Export_users = await axios.get(`users?${query}`);
      this.Export_users = Export_users.data.data;

      this.Export_users.forEach((user) => {
        this.ExportUsers.push({
          sr_no: user.sr_no,
          name: user.name ? user.name.toUpperCase() : "",
          email: user.email ? user.email.toUpperCase() : "",
          phone: user.phone ? user.phone : "",

          supervisor: user.supervisor_id
            ? user.supervisor.name.toUpperCase()
            : "",
          status: user.active == 1 ? "YES" : "NO",
        });
      });
      this.isExcelLoading = false;
    },
    async generateExcel() {
      this.isExcelLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      this.filter.position_id = this.filter.position_id
        ? this.filter.position_id
        : "";
      let response = [];
      let query =
        "role_id=3" +
        "&companyId=" +
        this.company.id +
        "&is_active=" +
        this.filter.is_active +
        "&position_id=" +
        this.filter.position_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      response = await axios
        .get(`/users/excel_export?${query}`)
        .then((response) => window.open(`${response.data.data}`, "_blank"));
      if (response) {
        console.log(response);
      }
      this.isExcelloading = false;
    },
    isDeletePage() {
      let response = false;
      if (this.$route.query.open_trash) {
        response = true;
      }
      return response;
    },
    async Restore_this(id) {
      this.restore_user = true;
      await axios
        .post(`/users/restore/${this.encryptIt(id)}`)
        .then(() => {
          this.restore_user = false;
          this.is_restored_user = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
