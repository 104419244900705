<template>
  <v-main>
    <v-row>
      <v-col v-if="!$vuetify.breakpoint.mdAndDown">
        <img src="@/assets/about-us.png" width="100%" height="220" alt="" />
      </v-col>
      <v-col v-else>
        <img src="@/assets/about-us-2.png" width="100%" height="160" alt="" />
      </v-col>
    </v-row>

    <v-container class="px-5 px-sm-16 mt-sm-8" fluid>
      <v-row>
        <v-col class="text-center">
          <h1>CHOTU JOBS</h1>
          <h2 style="color: #c0c0c0">About Our Company</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="isPgLoading == false">
          <span v-html="companyData.about_us"></span>
        </v-col>
        <v-col v-else class="full-page-loader">
          <Loader />
        </v-col>
      </v-row>
    </v-container>
    <div v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
      <v-divider
        style="border: 1px solid #d7d7d7"
        class="mt-16 mb-10"
      ></v-divider>
      <!-- Newsletter Layout -->
      <v-container class="px-8" fluid>
        <NewsLetter />
      </v-container>
    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import NewsLetter from "../components/news-letter.vue";
import Loader from "../components/loader-circle.vue";
export default {
  components: { NewsLetter, Loader },
  name: "AboutUsPage",
  data() {
    return {
      filter: {},
      form: {},
      companyData: {},
      company_id: "1",
      isPgLoading: false,
    };
  },
  mounted() {
    this.getCompany(this.company_id);
  },
  methods: {
    async getCompany(companyId) {
      this.isPgLoading = true;
      await axios
        .get(`/get_company/${this.encryptIt(companyId)}`)
        .then((company) => {
          this.companyData = company.data.data;
          this.logo_path = company.logo_path;
          this.is_company_user = true;
          this.isPgLoading = false;
        })
        .catch((error) => {
          this.logo_path = "../../assets/bisleri-logo.png";
          console.error("Error fetching data:", error);
        });
      this.isPgLoading = false;
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
</style>
