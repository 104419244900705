<template>
  <v-main>
    <!-- JOB BOARD -->
    <v-container
      :style="{
        backgroundImage: `url(${require('@/assets/job-page.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
      class="py-8 py-sm-12 px-5 d-flex flex-column justify-start align-center"
      fluid
    >
      <!-- Header Section -->
      <v-row class="text-center" justify="center">
        <v-col cols="12">
          <h1 class="mb-4 text-h5 text-md-h3 font-weight-bold jobBoardTitle">
            <span style="color: #1f8bff">{{ totalCount }} Jobs </span>
            <span>Available Now</span>
          </h1>
          <p style="font-weight: 300" class="text-subtitle-1 text-sm-h5">
            Connecting Recruiters and Job Seekers<br />
            for Effortless Hiring
          </p>
        </v-col>
      </v-row>

      <!-- Search Section -->
      <v-row justify="center" class="mt-6">
        <v-col cols="12">
          <v-card class="search-card" elevation="1">
            <v-row>
              <!-- Industry -->
              <v-col
                cols="12"
                sm="4"
                class="d-none d-sm-flex align-center"
                style="border-right: 2px solid #1f8bff; padding: 0 24px"
              >
                <v-autocomplete
                  dense
                  solo
                  v-model="filter.organisation_id"
                  :items="industryItems"
                  label="Industry"
                  hide-details
                  clearable
                  :error-messages="errors.organisation_id"
                ></v-autocomplete>
              </v-col>
              <!-- Keyword -->
              <v-col
                cols="10"
                sm="4"
                class="d-flex align-center"
                style="padding: 0 0 0 20px"
              >
                <v-text-field
                  dense
                  solo
                  v-model="filter.search_keyword"
                  label="Your Keyword"
                  prepend-inner-icon="mdi-keyboard-variant"
                  hide-details
                  class="search-textfield"
                ></v-text-field>
              </v-col>
              <v-col class="d-none d-sm-flex" sm="4" style="padding: 0 20px">
                <v-btn color="primary" @click="getData" large block>
                  <v-icon left size="26">mdi-magnify</v-icon>Search
                </v-btn>
              </v-col>
              <v-col class="d-sm-none" cols="2" style="padding: 0 12px">
                <v-btn color="primary" @click="getData" large icon>
                  <v-icon left size="26">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Main Job Postings Layout -->
    <v-container class="py-8 px-8" fluid>
      <v-row justify="center">
        <v-col
          cols="12"
          sm="3"
          class="text-center"
          v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        >
          <v-row style="height: 90px">
            <v-col cols="auto" align-self="center">
              <h2>Advanced Filters</h2>
            </v-col>
            <v-col align-self="center">
              <v-btn text @click="filterReset">Reset</v-btn>
            </v-col>
          </v-row>
          <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>

          <!-- City -->
          <v-col sm="12">
            <v-autocomplete
              ref="city"
              outlined
              v-model="filter.city_id"
              :items="cityItems"
              label="City"
              prepend-inner-icon="mdi-account-tie-hat-outline"
              clearable
              hide-details
              @change="(page = 1), getData()"
              :error-messages="errors.city_id"
            ></v-autocomplete>
          </v-col>
          <v-card-text>
            <!-- Job Roles -->
            <h2 style="color: rgb(35, 35, 35)" class="text-start">Job Roles</h2>
            <v-text-field
              label="Search Roles"
              class="mb-2"
              @input="filterRoles"
              clearable
            ></v-text-field>
            <template v-if="isFilterLoading">
              <!-- Skeleton Loader -->
              <v-skeleton-loader
                v-for="n in 4"
                :key="`role-${n}`"
                type="list-item"
              ></v-skeleton-loader>
            </template>
            <template v-else>
              <v-row v-for="role in filteredRoles" :key="role.name">
                <v-col class="filterCols" cols="auto">
                  <v-checkbox
                    class="ma-0"
                    v-model="filter.role_id"
                    :label="role.name"
                    :value="role.id"
                    hide-details
                    @change="onCheckboxChange"
                  ></v-checkbox>
                </v-col>
                <!-- <v-col class="filterCols text-end">
                  <v-chip label>{{ role.count || 0 }}</v-chip>
                </v-col> -->
              </v-row>
            </template>

            <!-- Monthly Salary -->
            <h2 style="color: rgb(35, 35, 35)" class="text-start mt-10 mb-6">
              Monthly Salary
            </h2>
            <template v-if="isFilterLoading">
              <!-- Skeleton Loader -->
              <v-skeleton-loader
                v-for="n in 4"
                :key="`salary-${n}`"
                type="list-item"
              ></v-skeleton-loader>
            </template>
            <template v-else>
              <v-row
                v-for="monthlySalary in monthlySalaryItems"
                :key="monthlySalary.name"
              >
                <v-col class="filterCols" cols="auto">
                  <v-checkbox
                    class="ma-0"
                    v-model="filter.monthly_salary"
                    :label="monthlySalary.name"
                    :value="monthlySalary.name"
                    hide-details
                    @change="onCheckboxChange"
                  ></v-checkbox>
                </v-col>
                <!-- <v-col class="filterCols text-end">
                  <v-chip label>{{ monthlySalary.count || 0 }}</v-chip>
                </v-col> -->
              </v-row>
            </template>

            <!-- Experience -->
            <h2 style="color: rgb(35, 35, 35)" class="text-start mt-10 mb-6">
              Experience
            </h2>
            <template v-if="isFilterLoading">
              <!-- Skeleton Loader -->
              <v-skeleton-loader
                v-for="n in 4"
                :key="`experience-${n}`"
                type="list-item"
              ></v-skeleton-loader>
            </template>
            <template v-else>
              <v-row
                v-for="experience in experienceItems"
                :key="experience.name"
              >
                <v-col class="filterCols" cols="auto">
                  <v-checkbox
                    class="ma-0"
                    v-model="filter.experiences"
                    :label="experience.name"
                    :value="experience.name"
                    hide-details
                    @change="(page = 1), getData()"
                  ></v-checkbox>
                </v-col>
                <!-- <v-col class="filterCols text-end">
                  <v-chip label>{{ experience.count || 0 }}</v-chip>
                </v-col> -->
              </v-row>
            </template>

            <!-- Gender -->
            <v-row class="text-start mt-10">
              <v-col cols="auto" align-self="center">
                <h2 style="color: rgb(35, 35, 35)">Gender</h2>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-end">
                <v-btn text @click="(filter.gender = ''), getData()">
                  Clear
                </v-btn>
              </v-col>
            </v-row>
            <v-radio-group
              v-model="filter.gender"
              @change="(page = 1), getData()"
            >
              <template v-if="isFilterLoading">
                <!-- Skeleton Loader -->
                <v-skeleton-loader
                  v-for="n in 4"
                  :key="n"
                  type="list-item"
                ></v-skeleton-loader>
              </template>
              <template v-else>
                <v-row v-for="gender in genderItems" :key="gender.id">
                  <v-col class="filterCols" cols="auto" align-self="center">
                    <v-radio :label="gender.name" :value="gender.id"></v-radio>
                  </v-col>
                  <!-- <v-col class="filterCols text-end">
                    <v-chip label>{{ gender.count || 0 }}</v-chip>
                  </v-col> -->
                </v-row>
              </template>
            </v-radio-group>

            <!-- Qualification -->
            <h2 style="color: rgb(35, 35, 35)" class="text-start mt-10">
              Qualification
            </h2>
            <v-radio-group
              v-model="filter.qualification"
              @change="(page = 1), getData()"
            >
              <template v-if="isFilterLoading">
                <!-- Skeleton Loader -->
                <v-skeleton-loader
                  v-for="n in 4"
                  :key="n"
                  type="list-item"
                ></v-skeleton-loader>
              </template>
              <template v-else>
                <v-row
                  v-for="qualification in qualificationItems"
                  :key="qualification.name"
                >
                  <v-col
                    class="filterCols"
                    sm="9"
                    cols="auto"
                    align-self="center"
                  >
                    <v-radio
                      :key="qualification.id"
                      :label="qualification.name"
                      :value="qualification.id"
                    ></v-radio>
                  </v-col>
                  <!-- <v-col class="filterCols text-end" sm="3">
                    <v-chip label>{{ qualification.count || 0 }}</v-chip>
                  </v-col> -->
                </v-row>
              </template>
            </v-radio-group>
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="9" class="text-center">
          <v-row
            class="align-center justify-space-between"
            :style="{
              margin: $vuetify.breakpoint.smAndDown ? '0px' : '14px 0px 20px',
            }"
          >
            <!-- Showing Information -->
            <v-col class="text-start">
              <span>Showing </span>
              <!-- <span class="bold-text">41-60 </span>
              <span>of </span> -->
              <span class="bold-text"> {{ count }} </span>
              <span>jobs</span>
            </v-col>

            <!-- Sort By Dropdown -->
            <!-- <v-col cols="auto" style="padding:0">
              <v-autocomplete
                dense
                outlined
                v-model="filter.industry"
                :items="sortByItems"
                label="Sort By"
                hide-details
                clearable
                :error-messages="errors.industry"
              ></v-autocomplete>
            </v-col> -->
          </v-row>
          <v-divider
            style="border: 1px solid #d7d7d7"
            class="my-4"
            v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
          ></v-divider>
          <v-row>
            <v-col
              sm="4"
              v-for="(jobData, jobIndex) in jobItems"
              :key="jobIndex"
            >
              <JobCard :job="jobData" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- Newsletter Layout -->
    <v-container
      class="px-8"
      fluid
      v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
    >
      <Newsletter />
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import Newsletter from "../components/news-letter.vue";
import JobCard from "../components/job-card.vue";
export default {
  name: "HomePage",
  data() {
    return {
      filter: {
        show_deleted: 0,
        is_active: "",
        industry: "",
        location: "",
        search_keyword: "",
        city_id: "",
        organisation_id: "",
        role_id: "",
        gender: "",
        monthly_salary: "",
        experiences: "",
        qualification: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      filteredCount: 0,
      totalCount: 0,
      sortByItems: ["Newest Post", "Oldest Post"],
      industryItems: [],
      locationItems: [],
      cityItems: [],
      roleItems: [],
      filteredRoles: [],
      roleSearchKeyword: "",
      monthlySalaryItems: [],
      experienceItems: [],
      genderItems: [],
      qualificationItems: [
        { name: "All Education Levels", value: "", count: "0" },
      ],
      jobItems: [],
      isFilterLoading: true,
      isLoading: true,
    };
  },
  components: {
    Newsletter,
    JobCard,
  },
  watch: {
    // Watch for changes in the search keyword and trigger the filtering
    roleSearchKeyword(newKeyword) {
      this.filterRoles(newKeyword);
    },
  },
  mounted() {
    console.log("this.$route.params.city", this.$route);
    this.filter.city_id = this.$route.query.city;
    this.filter.organisation_id = this.$route.query.organisation;
    this.filter.role_id = this.$route.query.category;
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      this.isFilterLoading = true;
      await axios
        .get(`jobs/masters`)
        .then((masters) => {
          masters = masters.data;
          //Organisations
          masters.organisations.forEach((organisation) => {
            this.industryItems.push({
              id: organisation.id,
              text: organisation.name,
              value: organisation.id,
            });
          });
          //City
          masters.cities.forEach((city) => {
            this.cityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
            this.locationItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
          });

          //Category
          masters.categories.forEach((category) => {
            this.roleItems.push({
              id: category.id,
              name: category.name,
              value: category.id,
            });
          });
          this.filteredRoles = this.roleItems;

          //Monthly Salaries
          masters.monthlySalaries.forEach((salary) => {
            this.monthlySalaryItems.push({
              id: salary.id,
              name: salary.description,
              value: salary.id,
            });
          });

          //Qualification
          masters.qualifications.forEach((qualification) => {
            this.qualificationItems.push({
              id: qualification.id,
              name: qualification.description,
              value: qualification.id,
            });
          });

          //Gender
          masters.genders.forEach((gender) => {
            this.genderItems.push({
              id: gender.id,
              name: gender.description,
              value: gender.id,
            });
          });

          //Experience
          masters.experiences.forEach((experience) => {
            this.experienceItems.push({
              id: experience.id,
              name: experience.description,
              value: experience.id,
            });
          });
          this.isFilterLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.city_id = this.filter.city_id ? this.filter.city_id : "";
      this.filter.experiences = this.filter.experiences
        ? this.filter.experiences
        : "";
      this.filter.organisation_id = this.filter.organisation_id
        ? this.filter.organisation_id
        : "";
      this.filter.role_id = this.filter.role_id ? this.filter.role_id : "";
      this.filter.qualification = this.filter.qualification
        ? this.filter.qualification
        : "";
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      let query = new URLSearchParams({
        ...this.filter, // Use the existing filter object directly
        is_approved_org: 1,
        page: this.page,
        rowsPerPage: this.rowsPerPage,
        status: 1,
      });

      await axios
        .get(`/jobs?${query}`)
        .then((response) => {
          this.jobItems = response.data.data;
          console.log("this.jobItems", this.jobItems);
          this.count = response.data.count;
          this.filteredCount = response.data.count;
          this.totalCount = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    filterRoles(keyword) {
      if (keyword) {
        this.filteredRoles = this.roleItems.filter((role) =>
          role.name.toLowerCase().includes(keyword.toLowerCase())
        );
      } else {
        this.filteredRoles = this.roleItems; // If no keyword, show all roles
      }
    },
    filterReset() {
      this.filter = {
        show_deleted: 0,
        is_active: "",
        industry: "",
        location: "",
        search_keyword: "",
        city_id: "",
        organisation_id: "",
        role_id: "",
        gender: "",
        monthly_salary: "",
        experiences: "",
        qualification: "",
      };
      this.getData();
    },
    onCheckboxChange(checked) {
      // Handle the logic for empty string instead of null
      if (!checked) {
        if (this.filter.role_id === null) {
          this.filter.role_id = "";
        }
        if (this.filter.monthly_salary === null) {
          this.filter.monthly_salary = "";
        }
      }
      this.page = 1;
      this.getData();
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.transparent-card {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
}
.landingPgTitle {
  font-size: 42px;
}
.jobBoardTitle {
  font-size: 42px;
  word-spacing: 4px;
  line-height: 1.3;
}
.text--secondary {
  color: rgba(0, 0, 0, 0.6);
}
.cardLay {
  transition: transform 0.2s ease;
}
.cardLay:hover {
  transform: scale(1.02);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}
.filterCols {
  padding: 6px 12px;
}

.line-clamp {
  display: -webkit-box; /* Required for webkit-based browsers */
  -webkit-line-clamp: 3; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-card {
  padding: 20px 0px;
}
</style>
