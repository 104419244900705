<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Pjp Reference
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.location"
                outlined
                label="Location"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="user"
                outlined
                v-model="form.user_id"
                :items="userItems"
                label="User"
                prepend-inner-icon="mdi-account-tie-hat-outline"
                clearable
                :error-messages="errors.user_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="day"
                outlined
                v-model="form.day"
                :items="dayItems"
                label="Day"
                prepend-inner-icon="mdi-calendar"
                clearable
                :error-messages="errors.day"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="week"
                outlined
                v-model="form.week"
                :items="weekItems"
                label="Week"
                prepend-inner-icon="mdi-calendar-week"
                clearable
                :error-messages="errors.week"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <section>
            <template v-if="form.pjp_reference_outlets.length">
              <h3 class="my-4">List of Outlet(s) for this Pjp Reference:</h3>
              <v-row
                v-for="(outlet, pO) in form.pjp_reference_outlets"
                :key="`outlet${pO}`"
              >
                <v-col sm="4">
                  <v-autocomplete
                    ref="outlet"
                    outlined
                    v-model="outlet.outlet_id"
                    :items="outletItems"
                    label="Outlet"
                    prepend-inner-icon="mdi-store"
                    clearable
                    :error-messages="
                      errors[`pjp_reference_outlets.${pO}.outlet_id`]
                        ? errors[`pjp_reference_outlets.${pO}.outlet_id`]
                        : ``
                    "
                  ></v-autocomplete>
                </v-col>
                <v-col sm="1">
                  <v-dialog
                    v-model="isDeleteOutletDialogs[pO]"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        class="ml-9"
                        small
                        dark
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-close-thick </v-icon>
                      </v-btn>
                    </template>
                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the outlet?
                      </v-card-title>
                      <v-card-text>
                        <v-btn
                          color="primary"
                          @click.stop="deleteOutlet(outlet)"
                        >
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          color="red"
                          dark
                          @click.stop="isDeleteOutletDialogs = []"
                        >
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col sm="12" class="mb-5">
                <v-btn small rounded color="primary" @click="Add_Empty_Outlet">
                  Add Outlet
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-5 mb-5"> </v-divider>
          </section>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Pjp Reference
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "PjpReferenceEditPage",
  data() {
    return {
      form: {
        is_active: false,
        pjp_reference_outlets: [],
      },
      userItems: [],
      outletItems: [],
      isDeleteOutletDialogs: [],
      dayItems: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      weekItems: ["EVEN", "ODD"],
      isLoading: false,
      date_menu: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`pjp_references/masters`)
        .then((masters) => {
          masters = masters.data;
          //Users
          masters.users.forEach((user) => {
            this.userItems.push({
              id: user.id,
              text: user.user_name,
              value: user.id,
            });
          });
          //Outlets
          masters.outlets.forEach((outlet) => {
            this.outletItems.push({
              id: outlet.id,
              text: outlet.name,
              value: outlet.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post("/pjp_references", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push("/pjp-references");
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.pjpReferenceId;
      axios
        .get(`/pjp_references/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    Add_Empty_Outlet() {
      let pjp_reference_outlet = {
        outlet_id: "",
      };
      this.form.pjp_reference_outlets.push(pjp_reference_outlet);
    },
    deleteOutlet(pjp_reference_outlet) {
      this.form.pjp_reference_outlets.splice(
        this.form.pjp_reference_outlets.indexOf(pjp_reference_outlet),
        1
      );
      this.isDeleteOutletDialogs = [];
    },
  },
};
</script>
