<template>
  <v-main class="mt-8">
    <template v-if="isLoading == false">
      <v-container class="px-5 px-sm-16" fluid>
        <v-row
          class="py-2"
          align="center"
          justify="space-between"
          style="border-bottom: 1px solid #c0c0c0"
        >
          <!-- Left Section: Job Info -->
          <v-col cols="12" sm="auto">
            <h1 class="font-weight-bold text-start text-h5 text-sm-h4">
              {{ job.title }}
            </h1>
            <v-row dense>
              <v-col cols="auto" class="d-flex align-center">
                <v-icon size="14" color="#1C1B1F" class="mr-1">
                  mdi-briefcase-outline
                </v-icon>
                <span class="text-caption grey--text">
                  {{ job.job_type == 0 ? "Full Time" : "Part Time" }}
                </span>
              </v-col>
              <v-col cols="auto" class="d-flex align-center">
                <v-icon size="14" color="#1C1B1F" class="mr-1">
                  mdi-clock-time-four-outline
                </v-icon>
                <span class="text-caption grey--text">
                  {{ formattedDate(job.updated_at) }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="text-end" v-if="user.position_id == 1">
            <v-btn
              color="primary"
              class="py-5"
              :to="`/applied-candidates/${encryptIt(job.id)}`"
              large
            >
              Applied Candidates
            </v-btn>
          </v-col>
          <!-- Right Section: Apply Now Button -->
          <v-col v-else class="text-end">
            <template v-if="Object.keys(this.user).length > 0">
              <v-btn
                v-if="!isSaved"
                outlined
                color="#1F8BFF"
                class="mr-4"
                fab
                width="50"
                height="50"
                :loading="isLoading"
                @click="toggleSaveJob('save')"
              >
                <v-icon>mdi-bookmark-outline</v-icon>
              </v-btn>
              <v-btn
                v-else
                color="#1F8BFF"
                class="mr-4"
                fab
                width="46"
                height="46"
                :loading="isLoading"
                @click="toggleSaveJob('remove')"
              >
                <v-icon color="white">mdi-bookmark-check</v-icon>
              </v-btn>
            </template>

            <v-btn
              color="primary"
              class="py-5"
              @click="applyNowBtn"
              large
              :loading="isAppliedJobLoading"
              v-if="!isApplied"
            >
              Apply Now
            </v-btn>
            <v-btn color="primary" class="py-5" disabled large v-else>
              <v-icon left size="20">mdi-check-decagram-outline</v-icon>
              Applied
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="8">
            <v-card>
              <v-card-text>
                <!-- Title Section -->
                <v-row>
                  <v-col cols="12">
                    <v-row class="d-flex align-center">
                      <v-col>
                        <p class="text-h5 font-weight-bold text-capitalize">
                          Employment Information
                        </p>
                      </v-col>
                    </v-row>
                    <v-divider
                      style="border: 1px solid #d7d7d7"
                      class="my-4"
                    ></v-divider>
                  </v-col>
                </v-row>

                <!-- Information Cards -->
                <v-row class="d-flex justify-start" spacing="4">
                  <!-- Category -->
                  <v-col cols="12" md="6">
                    <v-card class="pa-3" outlined>
                      <v-row>
                        <v-col class="d-flex align-center" cols="auto">
                          <v-icon color="grey lighten-2">mdi-account</v-icon>
                        </v-col>
                        <v-col>
                          <div class="text-h6 text-center">Category</div>
                          <div class="text-body-1 text-center">
                            {{ job.category?.name || "" }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <!-- Job Type -->
                  <v-col cols="12" md="6">
                    <v-card class="pa-3" outlined>
                      <v-row>
                        <v-col class="d-flex align-center" cols="auto">
                          <v-icon color="grey lighten-2">mdi-briefcase</v-icon>
                        </v-col>
                        <v-col>
                          <div class="text-h6 text-center">Job Type</div>
                          <div class="text-body-1 text-center">
                            {{ job.job_type == 0 ? "Full Time" : "Part Time" }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <!-- Salary -->
                  <v-col cols="12" md="6">
                    <v-card class="pa-3" outlined>
                      <v-row>
                        <v-col class="d-flex align-center" cols="auto">
                          <v-icon color="grey lighten-2">mdi-cash</v-icon>
                        </v-col>
                        <v-col>
                          <div class="text-h6 text-center">Salary</div>
                          <div class="text-body-1 text-center">
                            {{ job.salary_range_from / 100000 }} LPA -
                            {{ job.salary_range_to / 100000 }} LPA
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <!-- Gender -->
                  <v-col cols="12" md="6">
                    <v-card class="pa-3" outlined>
                      <v-row>
                        <v-col class="d-flex align-center" cols="auto">
                          <v-icon color="grey lighten-2"
                            >mdi-gender-male-female</v-icon
                          >
                        </v-col>
                        <v-col>
                          <div class="text-h6 text-center">Gender</div>
                          <div class="text-body-1 text-center">
                            {{ pascalCaseText(job.gender.description) }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <!-- Working Days -->
                  <v-col cols="12" md="6">
                    <v-card class="pa-3" outlined>
                      <v-row>
                        <v-col class="d-flex align-center" cols="auto">
                          <v-icon color="grey lighten-2"
                            >mdi-calendar-week</v-icon
                          >
                        </v-col>
                        <v-col>
                          <div class="text-h6 text-center">Working Days</div>
                          <div class="text-body-1 text-center">
                            {{ job.working_days }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <!-- No. Of Openings -->
                  <v-col cols="12" md="6">
                    <v-card class="pa-3" outlined>
                      <v-row>
                        <v-col class="d-flex align-center" cols="auto">
                          <v-icon color="grey lighten-2"
                            >mdi-account-group</v-icon
                          >
                        </v-col>
                        <v-col>
                          <div class="text-h6 text-center">No. Of Openings</div>
                          <div class="text-body-1 text-center">
                            {{ job.job_openings }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <!-- Education -->
                  <v-col cols="12" md="6">
                    <v-card class="pa-3" outlined>
                      <v-row>
                        <v-col class="d-flex align-center" cols="auto">
                          <v-icon color="grey lighten-2">mdi-briefcase</v-icon>
                        </v-col>
                        <v-col>
                          <div class="text-h6 text-center">
                            Required Qualification
                          </div>
                          <div class="text-body-1 text-center">
                            {{
                              job.required_qualification.description || "Any"
                            }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="4">
            <v-card
              class="pa-4"
              :to="`/organisations/${encryptIt(job.organisation.id)}`"
            >
              <v-row>
                <v-col cols="auto">
                  <v-avatar
                    size="75"
                    color="secondary"
                    class="d-flex align-center justify-center"
                    style="border: 1px solid #1f8bff"
                  >
                    <v-img
                      :src="
                        job.organisation.logo_path
                          ? mediaUrl + job.organisation.logo_path
                          : require('@/assets/office-building.webp')
                      "
                      contain
                    ></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="auto" align-self="center">
                  <span class="text-h6 text-center font-weight-bold">
                    {{ job.organisation ? job.organisation.name : "" }}
                  </span>
                  <br />
                  <span class="text-body2 text-center text-muted">
                    {{
                      job.organisation
                        ? job.organisation.city_locality.name
                        : ""
                    }},
                    {{ job.organisation ? job.organisation.city.name : "" }}
                  </span>
                </v-col>
              </v-row>

              <v-divider
                style="border: 1px solid #d7d7d7"
                class="my-4"
              ></v-divider>

              <v-row class="pa-0">
                <v-col>
                  <ul>
                    <li>
                      {{ job.organisation ? job.organisation.address : "" }}
                    </li>
                    <li>
                      Phone:
                      {{ job.organisation ? job.organisation.phone : "" }}
                    </li>
                    <li>
                      Email:
                      {{ job.organisation ? job.organisation.email : "" }}
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col>
            <h2>Job Description</h2>
            <p>{{ job.descriptions }}</p>
          </v-col>
        </v-row>
      </v-container>

      <v-divider class="my-10"></v-divider>

      <v-container class="px-5 px-sm-12" fluid>
        <!-- Similar Jobs -->
        <h1>Similar Jobs</h1>
        <div v-if="filteredJobItems.length">
          <v-row class="mb-16">
            <v-col
              sm="3"
              v-for="(jobData, jobIndex) in filteredJobItems"
              :key="jobIndex"
            >
              <JobCard :job="jobData" />
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <p>No similar jobs available.</p>
        </div>

        <!-- Newsletter Layout -->
        <v-container
          class="mt-16"
          fluid
          v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        >
          <Newsletter />
        </v-container>

        <!-- Job Application Login -->
        <v-dialog width="450" v-model="isJobAppLogin">
          <JobApplicationLogin />
        </v-dialog>
      </v-container>
    </template>
    <template v-else>
      <Loader />
    </template>
  </v-main>
</template>

<script>
import Newsletter from "../components/news-letter.vue";
import JobCard from "../components/job-card.vue";
import JobApplicationLogin from "../auth/job-application-login.vue";
import axios from "axios";
import Loader from "../components/loader-circle.vue";
export default {
  name: "JobDetailPage",
  data() {
    return {
      filter: {
        show_deleted: 0,
        is_active: "",
        category_id: "",
      },
      job: {},
      jobItems: [],
      isJobAppLogin: false,
      isSaved: false,
      isAppliedJobLoading: false,
      isApplied: false,
      isLoading: false,
    };
  },
  components: {
    Newsletter,
    JobCard,
    JobApplicationLogin,
    Loader,
  },
  computed: {
    filteredJobItems() {
      // Exclude the job from jobItems
      return this.jobItems.filter((item) => item.id !== this.job.id);
    },
  },
  async mounted() {
    await this.getData();
    this.getSimilarJobData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.jobDetailId;
      await axios
        .get(`/jobs/${encryptedId}`)
        .then((response) => {
          this.job = response.data.data;
          this.isSaved = this.job.saved_candidates.some(
            (candidate) => candidate.id === this.user.id
          );
          this.isApplied = this.job.applied_candidates.some(
            (candidate) => candidate.id === this.user.id
          );
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async getSimilarJobData() {
      this.isLoading = true;
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.category_id = this.job.category_id
        ? this.job.category_id
        : "";
      let query = new URLSearchParams({
        page: this.page,
        rowsPerPage: this.rowsPerPage,
        ...this.filter, // Use the existing filter object directly
      });
      await axios
        .get(`/jobs?${query}`)
        .then((response) => {
          this.jobItems = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    async applyNowBtn() {
      this.isAppliedJobLoading = true;
      if (Object.keys(this.user).length > 0) {
        await axios
          .post(`/users/apply_jobs`, {
            user_id: this.user.id,
            job_id: this.job.id,
          })
          .then((response) => {
            this.isAppliedJobLoading = false;
            if (response) {
              this.$toast.success("🎉 Application Submitted Successfully!", {
                position: "top-right",
                timeout: 5000,
              });
            }
          })
          .catch(function (error) {
            this.isAppliedJobLoading = false;
            console.log(error);
            // window.location.href = "/auth/login";
          });
        this.getData();
      } else {
        this.$router.push("/login/candidate");
        // this.isJobAppLogin = true;
      }
    },
    async toggleSaveJob(action) {
      await axios
        .post(`/users/bookmark_jobs`, {
          user_id: this.user.id,
          job_id: this.job.id,
          action: action,
        })
        .then((response) => {
          console.log("users/job_saves", response);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.getData();
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
