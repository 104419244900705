<template>
  <v-main style="padding: 0">
    <v-container class="px-16" fluid>
      <v-row>
        <v-col sm="8">
          <h1 style="font-weight: 900">CHOTU JOBS</h1>
          <p style="font-size: 14px">
            A platform that bridges professionals, recruiters, and <br />
            job seekers to connect, collaborate, and discover <br />
            opportunities for career growth and success.
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col sm="2">
          <h3>Quicklinks</h3>
          <router-link
            to="/about-us"
            style="text-decoration: none; color: inherit;"
          >
            <p style="margin: 10px 0 0 0">About Us</p>
          </router-link>
          <router-link
            to="/privacy-policy"
            style="text-decoration: none; color: inherit"
          >
            <p style="margin: 10px 0 0 0">Privacy And Policy</p>
          </router-link>
          <router-link
            to="/terms-and-conditions"
            style="text-decoration: none; color: inherit"
          >
            <p style="margin: 10px 0 0 0">Terms And Conditions</p>
          </router-link>
          <router-link
            to="/contact-us"
            style="text-decoration: none; color: inherit"
          >
            <p style="margin: 10px 0 0 0">Contact Us</p>
          </router-link>
        </v-col>
        <v-col sm="2">
          <h3>Resources</h3>
          <router-link
            to="/jobs"
            style="text-decoration: none; color: inherit"
          >
            <p style="margin: 10px 0 0 0">Find A Job</p>
          </router-link>
        </v-col>
      </v-row>
      <v-divider style="border: 1px solid #c0c0c0" class="mt-8"></v-divider>
      <v-row justify="center" align="center" class="mt-8">
        <p style="font-size: 14px">
          Copyright © 2024. Chotujobs all rights reserved
        </p>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
export default {
  name: "FooterPage",
  data() {
    return {};
  },
};
</script>
