<template>
  <v-main>
    <v-container
      style="background: #1f8bff; color: white"
      class="py-8 py-sm-12 px-5"
      fluid
    >
      <!-- Header Section -->
      <v-row class="text-center" justify="center">
        <v-col cols="12">
          <h1
            style="font-size: 42px"
            class="font-weight-bold text-h5 text-md-h3"
          >
            <span>Explore Jobs By Cities</span>
          </h1>
        </v-col>
      </v-row>

      <!-- Search Section -->
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-card class="pa-3 pa-sm-5" elevation="1">
            <v-row>
              <!-- Keyword -->
              <v-col
                cols="10"
                sm="9"
                class="d-flex align-center"
                style="padding: 0 0 0 20px"
              >
                <v-text-field
                  dense
                  solo
                  v-model="filter.search_keyword"
                  label="Your Keyword"
                  prepend-inner-icon="mdi-keyboard-variant"
                  hide-details
                  class="search-textfield"
                ></v-text-field>
              </v-col>
              <v-col class="d-none d-sm-flex" sm="3" style="padding: 0 20px">
                <v-btn color="primary" @click="getData" large block>
                  <v-icon left size="26">mdi-magnify</v-icon>Search
                </v-btn>
              </v-col>
              <v-col class="d-sm-none" cols="2" style="padding: 0 12px">
                <v-btn color="primary" @click="getData" large icon>
                  <v-icon left size="26">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- Popular Searches -->
      <v-row justify="center" class="mt-4">
        <v-col cols="12" class="text-center text-caption text-sm-body-1">
          <span style="font-weight: 700">Popular Searches: </span>
          <span
            v-for="(item, index) in [
              'Mumbai',
              'Pune',
              'Hyderabad',
              'Bangalore',
              'Delhi',
              'Chennai',
            ]"
            :key="index"
            class="text-decoration-underline mx-1"
            style="font-weight: 300"
          >
            {{ item }}<span v-if="index < 5">,</span>
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="py-5">
      <template v-if="!isLoading">
        <v-row justify="center" align="start">
          <v-col
            v-for="state in states"
            :key="state.name"
            cols="12"
            sm="6"
            md="3"
            class="text-center"
          >
            <v-card outlined>
              <v-card-title
                class="font-weight-bold d-flex justify-center"
                style="padding: 8px 16px"
              >
                {{ state.name }}
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="padding: 0px 16px">
                <v-list dense>
                  <template v-for="(city, index) in state.cities">
                    <v-list-item :to="`/jobs?city=${city.id}`" :key="index">
                      <v-list-item-title
                        class="text-body-1"
                        v-html="highlightKeyword(city.name)"
                      >
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Add divider except after the last item -->
                    <v-divider
                      :key="index"
                      v-if="index < state.cities.length - 1"
                      style="border-color: rgba(0, 0, 0, 0.099)"
                    ></v-divider>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <!-- Skeleton Loader -->
      <template v-else>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="text-center"
            v-for="n in 4"
            :key="'skeleton-' + n"
          >
            <v-card outlined>
              <v-card-title style="padding: 16px 16px 8px">
                <v-skeleton-loader
                  class="mx-auto"
                  height="20"
                  width="70%"
                  type="text"
                ></v-skeleton-loader>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text style="padding: 0px 16px">
                <v-list dense class="text-center">
                  <v-skeleton-loader
                    v-for="i in 5"
                    :key="'skeleton-item-' + i"
                    type="text"
                    height="15"
                    width="80%"
                    class="mx-auto"
                    style="margin: 16px 0"
                  ></v-skeleton-loader>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "modulesPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      states: [],
      isLoading: false,
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&show_deleted=" +
        this.filter.show_deleted +
        "&is_active=" +
        this.filter.is_active +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`/states?${query}`)
        .then((response) => {
          this.states = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    highlightKeyword(text) {
      if (!this.filter.search_keyword) return text; // Return original text if no keyword
      const escapedKeyword = this.filter.search_keyword.replace(
        /[-[\]{}()*+?.,\\^$|#\s]/g,
        "\\$&"
      ); // Escape special regex characters
      const regex = new RegExp(`(${escapedKeyword})`, "gi");
      return text.replace(
        regex,
        `<span style="background-color: yellow; font-weight: bold;">$1</span>`
      ); // Wrap matches in a span
    },
  },
};
</script>
