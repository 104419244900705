<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add Position
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <section v-for="(module, m) in moduleItems" :key="`module${m}`">
            <v-row>
              <v-col>
                <h3 class="my-4">
                  List of Permission(s) for {{ module.name }}:
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                md="2"
                v-for="(permission, p) in module.permissions"
                :key="`permission${p}`"
              >
                <v-checkbox
                  v-model="selected"
                  :value="permission.id + '' + module.id"
                  @click="UpdatePermission(permission.id)"
                  :label="`${permission.permission_name}`"
                ></v-checkbox
              ></v-col>
            </v-row>
            <v-divider class="my-5"> </v-divider>
          </section>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save Position
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "PositionCreatePage",
  data() {
    return {
      form: {
        role_id: 3,
        name: "",
        position_permissions: [],
      },
      isDeletePermissionDialogs: [],
      moduleItems: [],
      selected: [],
      permissionItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async UpdatePermission(permission_id) {
      // Assign or unassign permission to position
      let permission_payload = {
        permission_id: permission_id,
      };
      if (permission_id) {
        let result = this.form.position_permissions.find(
          (pp) => pp.permission_id == permission_id
        );
        if (result) {
          this.form.position_permissions.splice(
            this.form.position_permissions.indexOf(result),
            1
          );
        } else {
          this.form.position_permissions.push(permission_payload);
        }
      }
      console.log(this.form);
    },
    async getMasters() {
      await axios
        .get(`positions/masters`)
        .then((masters) => {
          masters = masters.data;
          //Permission
          masters.permissions.forEach((permission) => {
            this.permissionItems.push({
              id: permission.id,
              text: permission.permission_name,
              value: permission.id,
            });
          });
          //Module
          this.moduleItems = masters.modules;
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.post("/positions", this.form);
        this.isLoading = false;
        await this.saveUserTimestamp(null, this.form);
        this.$router.push("/positions");
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
    Add_Empty_Permission() {
      let position_permission = {
        permission_id: "",
        description: "",
      };
      this.form.position_permissions.push(position_permission);
    },
    deletePermission(position_permission) {
      this.form.position_permissions.splice(
        this.form.position_permissions.indexOf(position_permission),
        1
      );
      this.closePermissionDialogs();
    },
  },
};
</script>
