<template>
  <v-main>
    <div>
      <p>Authenticating with Google, please wait...</p>
    </div>
  </v-main>
</template>

<script>

export default {
  name: "GoogleCallback",
  mounted() {
  const urlParams = new URLSearchParams(window.location.search);
  const user = JSON.parse(decodeURIComponent(urlParams.get('user')));
  const token = urlParams.get('token');

  // Store them in localStorage
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('token', token);

  // Optionally, you can use Vuex to store these in a global state
  this.$store.commit('setUser', user);
  this.$store.commit('setToken', token);

  window.location.href = '/'; 
  // this.$router.push({ name: 'home' }); 
}
};
</script>
