<template>
  <v-main>
    <v-container style="padding: 40px 0 30px" class="px-16">
      <v-stepper v-model="stepValue">
        <v-stepper-header>
          <v-stepper-step :complete="stepValue > 1" step="1">
            Verify Your Email
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="stepValue > 2" step="2">
            Personal Details
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="stepValue > 3" step="3">
            Skill Details
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="stepValue > 4" step="4">
            Language
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="stepValue > 5" step="5">
            Resume
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <!-------- Verify Your Email -------->
          <v-stepper-content step="1">
            <v-card class="my-6" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="5" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">Email</p>
                    <v-text-field
                      v-model="form.email"
                      outlined
                      placeholder="Enter Email"
                      clearable
                      @input="errors.email = []"
                      :disabled="isVerified"
                      :error-messages="errors.email"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="5" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-0">OTP</p>
                    <v-otp-input
                      v-model="form.otp"
                      :disabled="isVerified"
                    ></v-otp-input>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                    style="padding: 0 12px"
                    align-self="center"
                    v-if="isVerified"
                  >
                    <v-icon
                      style="font-size: 50px"
                      class="ml-1 mb-2"
                      color="primary"
                      >mdi-check-decagram-outline</v-icon
                    >
                  </v-col>
                </v-row>
                <v-row v-if="isVerified">
                  <v-col cols="12" sm="6" md="4" style="padding-top: 0">
                    <p class="font-weight-bold text-start mb-1">
                      Enter password
                    </p>
                    <v-text-field
                      v-model="form.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      outlined
                      solo
                      :error-messages="errors.password"
                      placeholder="Enter password"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" style="padding-top: 0">
                    <p class="font-weight-bold text-start mb-1">
                      Re-Enter password
                    </p>
                    <v-text-field
                      v-model="form.password_confirmation"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      outlined
                      solo
                      @keypress.enter="login"
                      @input="errors.password_confirmation = []"
                      :error-messages="errors.password_confirmation"
                      placeholder="Re-Enter password"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col class="text-end" v-if="!isVerified && form.otp.length < 6">
                <v-btn
                  color="primary"
                  class="px-16"
                  :disabled="isButtonDisabled"
                  @click="emailVerification"
                  :loading="isOtpLoading"
                >
                  {{ otpButtonText }}
                </v-btn>
              </v-col>
              <v-col
                class="text-end"
                v-else-if="!isVerified && form.otp.length == 6"
              >
                <v-btn
                  color="primary"
                  class="px-16"
                  :loading="isOtpLoading"
                  @click="emailVerification"
                >
                  Verify
                </v-btn>
              </v-col>
              <v-col class="text-end" v-else>
                <v-btn
                  color="primary"
                  class="px-16"
                  :disabled="!form.password || !form.password_confirmation"
                  @click="step1"
                >
                  Next
                </v-btn>
              </v-col>
              <!-- <v-col class="text-end">
                <v-btn
                  color="primary"
                  class="px-16"
                  @click="stepValue < 3 && stepValue++"
                >
                  Next
                </v-btn>
              </v-col> -->
            </v-row>
          </v-stepper-content>

          <!-------- Personal Details -------->
          <v-stepper-content step="2">
            <v-card class="my-6" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">First Name *</p>
                    <v-text-field
                      v-model="form.first_name"
                      outlined
                      placeholder="Enter First Name"
                      clearable
                      :error-messages="errors.first_name"
                      @keypress="onlyAlphabets"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">Middle Name</p>
                    <v-text-field
                      v-model="form.middle_name"
                      outlined
                      placeholder="Enter Middle Name"
                      clearable
                      :error-messages="errors.middle_name"
                      @keypress="onlyAlphabets"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-1">Last Name *</p>
                    <v-text-field
                      v-model="form.last_name"
                      outlined
                      placeholder="Enter Last Name"
                      clearable
                      :error-messages="errors.last_name"
                      @keypress="onlyAlphabets"
                    ></v-text-field>
                  </v-col>

                  <v-col sm="4">
                    <p class="font-weight-bold text-start mb-1">Gender *</p>
                    <v-autocomplete
                      ref="gender"
                      outlined
                      placeholder="Enter Your Gender"
                      v-model="form.gender"
                      :items="genderItems"
                      clearable
                      :error-messages="errors.gender_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="4">
                    <p class="font-weight-bold text-start mb-1">
                      Date Of Birth *
                    </p>
                    <v-menu
                      v-model="dob_date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.dob"
                          placeholder="Date of Birth"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:clear="form.dob = null"
                          :error-messages="errors.dob"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.dob"
                        @change="dob_date_menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <label class="subtitle-1 font-weight-bold"> State *</label>
                    <v-autocomplete
                      ref="state"
                      outlined
                      v-model="form.state_id"
                      :items="stateItems"
                      placeholder="Select State"
                      clearable
                      @change="getCity"
                      :error-messages="errors.state_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="4">
                    <p class="font-weight-bold text-start mb-1">City *</p>
                    <v-autocomplete
                      ref="city"
                      outlined
                      placeholder="Enter Your City"
                      v-model="form.city_id"
                      :items="cityItems"
                      clearable
                      @change="getCityLocality"
                      :error-messages="errors.city_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="4">
                    <p class="font-weight-bold text-start mb-1">
                      City Locality
                    </p>
                    <v-autocomplete
                      ref="city_locality"
                      outlined
                      placeholder="Enter Your City Locality"
                      v-model="form.city_locality_id"
                      :items="cityLocalityItems"
                      clearable
                      :error-messages="errors.city_locality_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="4">
                    <p class="font-weight-bold text-start mb-1">
                      Qualification *
                    </p>
                    <v-autocomplete
                      ref="qualification"
                      outlined
                      placeholder="Enter Your Qualification"
                      v-model="form.qualification_id"
                      :items="qualificationItems"
                      clearable
                      :error-messages="errors.qualification_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col>
                <v-btn
                  color="grey darken-1"
                  class="px-16"
                  @click="stepValue > 1 && stepValue--"
                  dark
                >
                  Back
                </v-btn>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  color="primary"
                  class="px-16"
                  @click="stepValue < 5 && stepValue++"
                  :disabled="
                    !form.first_name ||
                    !form.last_name ||
                    !form.gender ||
                    !form.dob ||
                    !form.state_id ||
                    !form.city_id ||
                    !form.qualification_id
                  "
                >
                  Next
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-------- Skill Details -------->
          <v-stepper-content step="3">
            <v-autocomplete
              multiple
              outlined
              v-model="form.category_experiences"
              :items="categoryItems"
              label="Category"
              class="mb-5"
              clearable
              return-object
              :error-messages="errors.category_id"
            ></v-autocomplete>

            <v-row
              v-for="(category, index) in form.category_experiences"
              :key="index"
            >
              <v-col cols="12" style="padding: 0 12px" class="mt-4">
                <h3>{{ category.text }}</h3>
              </v-col>
              <v-col cols="auto" style="padding: 0 12px">
                <v-chip-group v-model="category.is_experience" column>
                  <v-chip
                    v-if="
                      !category.is_experience || category.is_experience === '0'
                    "
                    filter
                    outlined
                    value="0"
                  >
                    Fresher
                  </v-chip>
                  <v-chip
                    v-if="
                      !category.is_experience || category.is_experience === '1'
                    "
                    filter
                    outlined
                    value="1"
                  >
                    Experience
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                style="padding: 0 12px"
                v-if="category.is_experience === '1'"
              >
                <v-text-field
                  v-model="category.year"
                  dense
                  outlined
                  label="Year"
                  placeholder="Enter Year"
                  clearable
                  :error-messages="errors.year"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
                style="padding: 0 12px"
                v-if="category.is_experience === '1'"
              >
                <v-text-field
                  v-model="category.month"
                  dense
                  outlined
                  label="Month"
                  placeholder="Enter Month"
                  clearable
                  :error-messages="errors.month"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="grey darken-1"
                  class="px-16"
                  @click="stepValue > 1 && stepValue--"
                  dark
                >
                  Back
                </v-btn>
              </v-col>
              <!-- <v-col class="text-end">
                <v-btn color="primary" class="px-16" @click="save">
                  Save
                </v-btn>
              </v-col> -->
              <v-col class="text-end">
                <v-btn
                  color="primary"
                  class="px-16"
                  @click="stepValue < 5 && stepValue++"
                >
                  Next
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-------- Language -------->
          <v-stepper-content step="4">
            <v-row class="mb-4">
              <v-col>
                <h3 class="text-h5 font-weight-bold">Language Proficiency</h3>
              </v-col>
              <v-col>
                <!-- Add Language Button -->
                <div class="text-end">
                  <v-btn color="primary" elevation="1" @click="addLanguage">
                    <v-icon left>mdi-plus</v-icon> Add Language
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <!-- Section Title -->

            <!-- Language Inputs -->
            <v-container>
              <v-row
                v-for="(language, index) in form.languages"
                :key="index"
                class="align-center"
              >
                <v-col cols="4" align-self="center">
                  <v-combobox
                    v-model="language.name"
                    :items="languageItems"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Select Language"
                    class="mb-2"
                    clearable
                  ></v-combobox>
                </v-col>
                <v-col cols="2" class="align-center">
                  <v-checkbox
                    v-model="language.read"
                    hide-details="auto"
                    class="mt-0 pt-0"
                    label="Read"
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    v-model="language.write"
                    hide-details="auto"
                    class="mt-0 pt-0"
                    label="Write"
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="2">
                  <v-checkbox
                    v-model="language.speak"
                    hide-details="auto"
                    class="mt-0 pt-0"
                    label="Speak"
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="2" class="text-center">
                  <v-btn icon color="red" @click="removeLanguage(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

            <!-- Navigation Buttons -->
            <v-row class="mt-4">
              <v-col>
                <v-btn
                  color="grey darken-1"
                  class="px-8"
                  @click="stepValue > 1 && stepValue--"
                  dark
                >
                  Back
                </v-btn>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  color="primary"
                  class="px-8"
                  @click="stepValue < 5 && stepValue++"
                >
                  Next
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-------- Resume -------->
          <v-stepper-content step="5">
            <v-card class="my-6" elevation="0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="4" style="padding: 0 12px">
                    <p class="font-weight-bold text-start mb-3 ml-5">
                      Add Your Resume *
                    </p>
                    <v-file-input
                      outlined
                      label="Resume"
                      type="file"
                      v-model="form.file_path"
                      truncate-length="50"
                      placeholder="Upload Resume file here"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col>
                <v-btn
                  color="grey darken-1"
                  class="px-16"
                  @click="stepValue > 1 && stepValue--"
                  dark
                >
                  Back
                </v-btn>
              </v-col>
              <v-col class="text-end">
                <v-btn color="primary" class="px-16" @click="save">
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        is_active: true,
        position_id: 2,
        soft_password: "123456",
        password: "",
        password_confirmation: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone: "",
        otp: "",
        state_id: "",
        city_id: "",
        city_locality_id: "",
        file_path: null,
        category_experiences: [],
        languages: [
          {
            name: "",
            read: false,
            write: false,
            speak: false,
          },
        ],
      },
      is_experience: 0,
      stepValue: 1,
      genderItems: [],
      stateItems: [],
      languageOptions: [],
      languageItems: [],
      cityItems: [],
      cityLocalityItems: [],
      qualificationItems: [],
      categoryItems: [],
      dob_date_menu: false,
      showPassword: false,
      isVerified: false,
      isButtonDisabled: false,
      isOtpLoading: false,
      otpButtonText: "Send OTP",
      errors: {
        password_confirmation: [],
        email: [],
      },
    };
  },
  mounted() {
    if (localStorage.getItem("company-id").length) {
      this.form.company_id = localStorage.getItem("company-id");
    }
    this.form.phone = sessionStorage.getItem("phone");
    this.form.position_id = sessionStorage.getItem("positionId");
    // const phone = this.$route.state?.phone;
    this.form.role_id = "3";
    this.getMasters();
  },
  methods: {
    ...mapActions({
      logIn: "auth/logIn",
    }),
    step1() {
      if (this.form.password == this.form.password_confirmation) {
        this.errors.password_confirmation = [];
        this.stepValue < 3 && this.stepValue++;
      } else {
        this.errors.password_confirmation.push("Passwords do not match");
      }
    },
    addLanguage() {
      this.form.languages.push({
        name: "",
        read: false,
        write: false,
        speak: false,
      });
    },
    removeLanguage(index) {
      this.form.languages.splice(index, 1);
    },
    async getMasters() {
      await axios
        .get(`register/masters`)
        .then((masters) => {
          masters = masters.data;
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
          });
          //City
          // masters.cities.forEach((city) => {
          //   this.cityItems.push({
          //     id: city.id,
          //     text: city.name,
          //     value: city.id,
          //   });
          // });
          //City Locality
          // masters.cityLocalities.forEach((cityLocality) => {
          //   this.cityLocalityItems.push({
          //     id: cityLocality.id,
          //     text: cityLocality.name,
          //     value: cityLocality.id,
          //   });
          // });
          //Qualification
          masters.qualifications.forEach((qualification) => {
            this.qualificationItems.push({
              id: qualification.id,
              text: qualification.description,
              value: qualification.id,
            });
          });
          //Categories
          masters.categories.forEach((category) => {
            this.categoryItems.push({
              category_id: category.id,
              text: category.name,
              value: category.id,
            });
          });
          //Gender
          masters.genders.forEach((gender) => {
            this.genderItems.push({
              id: gender.id,
              text: gender.description,
              value: gender.description,
            });
          });
          //Language
          masters.languages.forEach((language) => {
            this.languageItems.push(this.pascalCaseText(language.description));
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async emailVerification() {
      try {
        this.isLoading = true;
        this.isOtpLoading = true;
        const regOtp = await axios.post(
          "/register/email_verification",
          this.form
        );
        if (regOtp && !this.form.otp) {
          this.isOtpLoading = false;
          this.$toast.success(
            `Your OTP has been sent to ${this.form.email}. Kindly check your email.`,
            {
              position: "top-right",
              timeout: 5000,
            }
          );
        }
        if (regOtp.data.isVerified) {
          this.isVerified = true;
          this.isOtpLoading = false;
        }
        this.disableOtpButton();
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.email = error.response.data.errors.email || [];
        }
        this.isOtpLoading = false;
        this.isLoading = false;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        let formattedLanguages = this.form.languages.map((lang) => ({
          name: lang.name,
          read: lang.read ? 1 : 0,
          write: lang.write ? 1 : 0,
          speak: lang.speak ? 1 : 0,
        }));

        let payload = {
          ...this.form,
          languages: formattedLanguages,
        };
        let user = await axios.post("/register", payload);
        this.user_id = user.data.data.id;
        await this.handleFileUpload();
        this.isLoading = false;
        let userData = {
          is_login: true,
          email: user.data.data.email,
          password: user.data.data.soft_password,
          position_id: user.data.data.position_id,
        };
        await this.logIn(userData);
        await this.saveUserTimestamp(null, this.form);
        window.location.href = "/";
        // this.$router.push("/login/candidate");
      } catch (e) {
        // alert("error");
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      let formData = new FormData();
      this.file_attachment = this.form.file_path;
      formData.append("user_id", this.user_id);
      formData.append("file_path", this.file_attachment);
      await axios
        .post("upload_user_images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
      this.isUploading = false;
    },
    disableOtpButton() {
      this.isButtonDisabled = true;
      let remainingTime = 20; // 20 seconds timer
      this.otpButtonText = `Wait ${remainingTime}s`;

      const timer = setInterval(() => {
        remainingTime--;
        if (remainingTime <= 0) {
          clearInterval(timer);
          this.isButtonDisabled = false;
          this.otpButtonText = "Send OTP"; // Reset button text
        } else {
          this.otpButtonText = `Wait ${remainingTime}s`;
        }
      }, 1000);
    },
    async getCity() {
      await axios
        .get(`/cities?state_id=${this.form.state_id}`)
        .then((response) => {
          const cities = response.data.data;
          this.cityItems = [];
          cities.forEach((city) => {
            this.cityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async getCityLocality() {
      await axios
        .get(`/city_localities?city_id=${this.form.city_id}`)
        .then((response) => {
          const cityLocalities = response.data.data;
          this.cityLocalityItems = [];
          cityLocalities.forEach((cityLocality) => {
            this.cityLocalityItems.push({
              id: cityLocality.id,
              text: cityLocality.name,
              value: cityLocality.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
  },
};
</script>
