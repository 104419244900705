<template>
  <v-main>
    <v-container class="px-5 px-sm-16 mt-8" fluid>
      <v-row class="pa-0" align="center" justify="space-between">
        <!-- Left Section: Job Info -->
        <v-col cols="12" sm="auto">
          <v-row align-content="center">
            <v-col cols="auto">
              <v-avatar
                size="100"
                color="secondary"
                class="d-flex align-center justify-center"
                style="border: 1px solid #1f8bff; cursor: pointer"
                @click="triggerFileUpload"
              >
                <v-img :src="mediaUrl + userData.image_path" contain></v-img>
              </v-avatar>
              <!-- Hidden file input -->
              <input
                type="file"
                ref="imagepath_file"
                accept="image/*"
                style="display: none"
                :disabled="tab != 0"
                @change="handleFileUpload"
              />
            </v-col>
            <v-col align-self="center">
              <h1 class="font-weight-bold text-start text-h5 mb-1">
                {{ userData.user_name }}
              </h1>
              <v-row
                dense
                align="center"
                v-if="userData.city?.name && userData.city_locality?.name"
              >
                <v-icon size="18" color="#1C1B1F">
                  mdi-map-marker-outline
                </v-icon>
                <div class="text-caption grey--text text--darken-2">
                  {{ userData.city_locality?.name }},
                  {{ userData.city?.name }}
                </div>
              </v-row>
              <h4
                class="font-weight-bold text-start mt-2 text-body-1 text-sm-subtitle-1"
              >
                <span
                  v-for="(experience, index) in userData.category_experiences"
                  :key="index"
                  style="color: #3b3b3b"
                >
                  {{ experience.category.name }}
                  <span v-if="index < userData.category_experiences.length - 1">
                    ,
                  </span>
                </span>
              </h4>
            </v-col>
          </v-row>
        </v-col>

        <!-- Right Section: Apply Now Button -->
        <v-col
          class="d-flex align-center justify-end"
          v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        >
          <v-btn
            color="primary"
            class="py-5"
            @click="is_job_app_login = true"
            large
            to="/candidate-preview"
          >
            <v-icon left size="20">mdi-eye-outline</v-icon>
            Preview
          </v-btn>
        </v-col>
      </v-row>
      <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>
      <v-row>
        <v-col sm="3">
          <v-tabs
            v-model="tab"
            vertical
            background-color="transparent"
            class="tabs-left-align"
          >
            <v-tab>
              <v-icon left> mdi-account </v-icon>
              My Profile
            </v-tab>
            <v-tab>
              <v-icon left> mdi-briefcase </v-icon>
              Saved Jobs
            </v-tab>
            <v-tab>
              <v-icon left> mdi-briefcase </v-icon>
              Applied Jobs
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col sm="9">
          <v-tabs-items v-model="tab" style="background: transparent">
            <v-tab-item> <CandidateId ref="candidateRef" /> </v-tab-item>
            <v-tab-item> <SavedJob /> </v-tab-item>
            <v-tab-item>
              <JobStatus :jobs="userData.applied_jobs" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <div v-if="tab == 0">
        <v-divider
          style="border: 1px solid #d7d7d7"
          class="mt-5 mt-sm-16 mb-5 mb-sm-10"
        ></v-divider>

        <v-row class="pb-2">
          <v-col sm="12" class="d-flex justify-center">
            <v-btn
              color="primary"
              :min-width="$vuetify.breakpoint.mdAndDown ? '100%' : '40%'"
              x-large
              @click="triggerChildSave"
              :loading="isDataUpdateLoading"
            >
              Save All Changes
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <div v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
      <v-divider
        style="border: 1px solid #d7d7d7"
        class="mt-10 mb-10"
      ></v-divider>

      <!-- Newsletter Layout -->
      <v-container class="px-8" fluid>
        <NewsLetter />
      </v-container>
    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import CandidateId from "./candidate-id.vue";
import SavedJob from "./saved-job.vue";
import NewsLetter from "../components/news-letter.vue";
import JobStatus from "./job-status.vue";
export default {
  components: { CandidateId, SavedJob, NewsLetter, JobStatus },
  name: "CandidateProfilePage",
  data() {
    return {
      profile_section: "My Profile",
      tab: parseInt(this.$route.query.tab) || "",
      userData: {},
      isPgLoading: false,
      isDataUpdateLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let encryptedId = this.encryptIt(this.user.id);
      axios
        .get(`/users/${encryptedId}`)
        .then((response) => {
          this.userData = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          // redirect to Home Page
          //   window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    watch: {
      "$route.query.tab": {
        immediate: true,
        handler(newTab) {
          this.tab = parseInt(newTab) || 0; // Ensure tab is an integer
        },
      },
    },
    async handleFileUpload() {
      let formData = new FormData();
      this.imagepath_attachment = this.$refs.imagepath_file.files[0];
      formData.append("user_id", this.user.id);
      formData.append("imagepath", this.imagepath_attachment);
      await axios
        .post("upload_user_images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.isError = true;
          this.error_msg =
            "Looks like system faced an error while uploading the File. Kindly check the file before uploading ...";
        });
      this.isUploading = false;
    },
    triggerFileUpload() {
      this.$refs.imagepath_file.click();
    },
    async triggerChildSave() {
      this.isDataUpdateLoading = true;
      await this.$refs.candidateRef.save(); // Call the child component's save function
      this.isDataUpdateLoading = false;
      this.$toast.success("Your Data is updated successfully!", {
        position: "top-right",
        timeout: 3000,
      });
      this.isDataUpdateLoading = false;
    },
  },
};
</script>
