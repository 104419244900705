<template>
  <v-container fluid v-if="isPgLoading == false">
    <v-row class="mb-16">
      <v-col sm="4" v-for="(jobData, jobIndex) in jobItems" :key="jobIndex">
        <JobCard :job="jobData" />
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import axios from "axios";
import JobCard from "../components/job-card.vue";
import Loader from "../components/loader-circle.vue";
export default {
  components: { JobCard, Loader },
  name: "JobCardsPage",
  data() {
    return {
      profile_section: "Saved Jobs",
      filter: {
        show_deleted: 0,
        position_id: 2,
        is_active: 1,
        search_keyword: "",
      },
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      jobItems: [],
      isPgLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isPgLoading = true;

      let query = new URLSearchParams({
        page: this.page,
        rowsPerPage: this.rowsPerPage,
        saved_candidate_id: this.user.id,
        ...this.filter, // Use the existing filter object directly
      });
      await axios
        .get(`/jobs?${query}`)
        .then((response) => {
          this.jobItems = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isPgLoading = false;
    },
  },
};
</script>
