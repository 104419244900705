<template>
  <v-main class="mt-10">
    <v-row justify="center" align="center">
      <v-col sm="6">
        <v-card class="pt-4">
          <v-card-text class="px-8" style="color: rgb(35, 35, 35)">
            <v-row>
              <v-col cols="12" style="padding: 12px 12px 0px">
                <label class="subtitle-1 font-weight-bold">First Name *</label>
                <v-text-field
                  outlined
                  v-model="form.first_name"
                  placeholder="Enter Your First Name"
                  :error-messages="errors.first_name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" style="padding: 0px 12px">
                <label class="subtitle-1 font-weight-bold">Last Name *</label>
                <v-text-field
                  outlined
                  v-model="form.last_name"
                  placeholder="Enter Your Last Name"
                  :error-messages="errors.last_name"
                ></v-text-field>
              </v-col>

              <v-col class="mb-8" sm="12" style="padding: 0px 12px">
                <label class="subtitle-1 font-weight-bold">
                  Are You Hiring For?
                </label>
                <br />
                <v-btn-toggle v-model="form.work_type" style="width: 100%">
                  <v-row>
                    <v-col>
                      <v-btn outlined block> Your Company </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn outlined block> Your Client's Company </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn outlined block> Both </v-btn>
                    </v-col>
                  </v-row>
                </v-btn-toggle>
              </v-col>

              <v-col
                v-if="form.work_type == 0"
                sm="12"
                style="padding: 0px 12px"
              >
                <label class="subtitle-1 font-weight-bold"> City </label>
                <v-autocomplete
                  ref="city"
                  outlined
                  v-model="form.city_id"
                  :items="cityItems"
                  placeholder="Enter Company City"
                  clearable
                  :error-messages="errors.city_id"
                ></v-autocomplete>
              </v-col>
              <v-col v-else sm="12" style="padding: 0px 12px">
                <label class="subtitle-1 font-weight-bold">
                  Client For Which You Are Hiring
                </label>
                <v-autocomplete
                  outlined
                  v-model="form.city_id"
                  :items="cityItems"
                  placeholder="Enter Client Company Name"
                  clearable
                  :error-messages="errors.city_id"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" style="padding: 0px 12px">
                <label class="subtitle-1 font-weight-bold">
                  Company Address *
                </label>
                <v-text-field
                  outlined
                  v-model="form.address"
                  placeholder="Enter Your Company Address"
                  :error-messages="errors.address"
                ></v-text-field>
              </v-col>

              <!-------- Consultancy Details -------->
              <v-col sm="12" v-if="form.work_type != 0">
                <v-row>
                  <v-col sm="12">
                    <v-divider style="border: 1px solid #d7d7d7"></v-divider>
                  </v-col>
                  <v-col>
                    <h1>Consultancy Details</h1>
                  </v-col>
                  <v-col cols="12" style="padding: 12px 12px 0px">
                    <label class="subtitle-1 font-weight-bold">
                      Consultancy Name *
                    </label>
                    <v-text-field
                      outlined
                      v-model="form.consultancy_name"
                      placeholder="Enter Your Consultancy Name"
                      :error-messages="errors.consultancy_name"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="12" style="padding: 0px 12px">
                    <label class="subtitle-1 font-weight-bold"> City In Which Company Is Located </label>
                    <v-autocomplete
                      ref="city"
                      outlined
                      v-model="form.city_id"
                      :items="cityItems"
                      placeholder="Enter Company City"
                      clearable
                      :error-messages="errors.city_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="12" style="padding: 0px 12px">
                    <label class="subtitle-1 font-weight-bold">
                      Full Company Address
                    </label>
                    <v-text-field
                      outlined
                      v-model="form.address"
                      placeholder="Enter Full Company Address"
                      :error-messages="errors.address"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col sm="12">
                <v-divider style="border: 1px solid #d7d7d7"></v-divider>
              </v-col>

              <v-col sm="12" class="mt-4">
                <v-btn color="primary" large block> Upload Post </v-btn>
              </v-col>

              <v-col sm="12" class="mt-2">
                <v-checkbox
                  v-model="form.whatsapp_updates"
                  label="Interview address is different from company address"
                  class="mt-0"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: "ContactSection",
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        work_type: 0,
      },
    };
  },
};
</script>

<style>
</style>