<template>
  <v-main>
    <v-container
      class="sign-in-container px-4"
      style="max-width: 1440px; padding: 50px 0"
    >
      <v-row justify="center" align="center" class="mb-12">
        <v-col cols="12" md="8" class="text-center">
          <h2 class="primary--text text-h5 font-weight-regular">
            Sign In as {{ $route.params.position }}
          </h2>
          <h1 class="text-h4 font-weight-bold">
            Sign In to Discover Your Dream Job!
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="12" md="4" class="text-center">
          <v-btn
            block
            outlined
            color="secondary"
            class="mb-4"
            height="62"
            @click="loginWithGoogle"
          >
            <v-icon left large>mdi-google</v-icon>
            Sign Up with Google
          </v-btn>

          <div class="d-flex align-center justify-center mb-4">
            <v-divider class="flex-grow-1"></v-divider>
            <span class="mx-2 grey--text">Or Continue With</span>
            <v-divider class="flex-grow-1"></v-divider>
          </div>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-start"
              style="padding-bottom: 6px"
            >
              <label class="font-weight-bold">Username</label>
            </v-col>
            <v-col cols="12" style="padding: 0 12px">
              <v-text-field
                v-model="credential"
                outlined
                solo
                placeholder="Enter Email / Phone"
                clearable
                @click:clear="credential = null"
                @input="clearValidationError"
                :error-messages="getValidationError"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isPhone">
            <v-col
              cols="12"
              class="d-flex justify-start"
              style="padding-bottom: 6px"
            >
              <label class="font-weight-bold">OTP</label>
            </v-col>
            <v-col cols="12" style="padding: 0 12px">
              <v-otp-input v-model="form.otp" :disabled="loading"></v-otp-input>
            </v-col>
          </v-row>
          <v-row v-if="isEmail">
            <v-col
              cols="12"
              class="d-flex justify-start"
              style="padding: 0px 12px 6px"
            >
              <label class="font-weight-bold">Enter password</label>
            </v-col>
            <v-col cols="12" style="padding-top: 0">
              <v-text-field
                v-model="form.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                outlined
                solo
                @keypress.enter="login"
                @input="validationErrors.credential = []"
                :error-messages="validationErrors.credential"
                label="Password"
                placeholder="Enter password"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            v-if="isPhone && !form.otp"
            block
            class="mt-6"
            color="primary"
            :loading="isLoading"
            :disabled="isButtonDisabled"
            height="50"
            @click="sendOtp"
          >
            {{ otpButtonText }}
          </v-btn>
          <v-btn
            v-else
            block
            class="mt-6"
            color="primary"
            :loading="isLoading"
            :disabled="!isCredential"
            height="50"
            @click="login"
          >
            Sign In
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        position_id: this.$route.params.position == "recruiter" ? 1 : 2,
        email: "",
        password: "",
        otp: "",
        is_login: true,
      },
      credential: "",
      loggedInRole: "",
      logo_path: "../../assets/bisleri-logo.png",
      is_company_user: false,
      showPassword: false,
      loading: false,
      isLoading: false,
      isButtonDisabled: false,
      otpButtonText: "Send OTP",
      validationErrors: {
        credential: [],
      },
    };
  },
  computed: {
    isEmail() {
      return this.credential && /\S+@\S+\.\S+/.test(this.credential);
    },
    isPhone() {
      // Check if credential is a 10-digit number
      return this.credential && /^\d{10}$/.test(this.credential);
    },
    isCredential() {
      return !!this.form.password || this.form.otp.length == 6;
    },
    getValidationError() {
      if (this.isPhoneNumber(this.credential)) {
        return this.errors.phone;
      } else {
        return this.errors.email;
      }
    },
    isPhoneNumber() {
      return (value) => /^[0-9]+$/.test(value); // Check if input is only numbers
    },
  },
  watch: {
    "$route.params.position": {
      immediate: true,
      handler(newPosition) {
        this.form.position_id = newPosition === "recruiter" ? 1 : 2;
      },
    },
  },
  mounted() {
    if (localStorage.getItem("company-id").length) {
      let companyId = localStorage.getItem("company-id");
      this.getCompany(companyId);
    }
  },
  methods: {
    clearValidationError() {
      this.validationErrors.phone = [];
      this.validationErrors.email = [];
    },
    async getCompany(companyId) {
      await axios
        .get(`/get_company/${this.encryptIt(companyId)}`)
        .then((company) => {
          company = company.data.data;
          this.logo_path = company.logo_path;
          this.is_company_user = true;
        })
        .catch((error) => {
          this.logo_path = "../../assets/bisleri-logo.png";
          console.error("Error fetching data:", error);
        });
    },
    async sendOtp() {
      this.form.phone = this.credential;
      await axios
        .post(`/send_otp`, this.form)
        .then(() => {})
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      setTimeout(() => {
        this.isLoading = false;
        this.disableOtpButton();
      }, 1000);
    },
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async login() {
      this.isLoading = true;
      console.log(this.isLoading);
      if (this.isEmail) {
        this.form.email = this.credential;
        this.form.phone = "";
      } else if (this.isPhone) {
        this.form.phone = this.credential;
        this.form.email = "";
      } else {
        this.errors.credential = "Invalid email or phone number";
        this.isLoading = false;
        return;
      }
      this.logIn(this.form)
        .then(() => {
          this.isLoading = false;
          location.reload("/");
        })
        .catch((error) => {
          this.validationErrors.credential =
            error.response.data.errors.email || [];
          this.isLoading = false;
        });
      this.isLoading = false;
      console.log(this.isLoading);
    },
    async loginWithGoogle() {
      try {
        const response = await axios.get(
          `/auth/google?position_id=${this.form.position_id}`
        );
        window.location.href = response.data.url; // Redirect to Google's login page
      } catch (error) {
        console.error("Failed to initiate Google login:", error);
      }
    },
    disableOtpButton() {
      this.isButtonDisabled = true;
      let remainingTime = 20; // 20 seconds timer
      this.otpButtonText = `Wait ${remainingTime}s`;

      const timer = setInterval(() => {
        remainingTime--;
        if (remainingTime <= 0) {
          clearInterval(timer);
          this.isButtonDisabled = false;
          this.otpButtonText = "Send OTP"; // Reset button text
        } else {
          this.otpButtonText = `Wait ${remainingTime}s`;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
