<template>
  <v-main>
    <v-container class="px-16 mt-8" fluid>
      <v-row class="pa-0" align="center" justify="space-between">
        <!-- Left Section: Job Info -->
        <v-col>
          <v-row align-content="center">
            <v-col sm="auto">
              <v-avatar
                size="100"
                color="secondary"
                class="d-flex align-center justify-center"
                style="border: 1px solid #1f8bff"
              >
                <v-img :src="userData.image_path" contain></v-img>
              </v-avatar>
            </v-col>
            <v-col>
              <h1 class="font-weight-semibold text-start mb-1">
                {{ userData.user_name || "" }}
              </h1>
              <v-row dense align="center">
                <v-icon size="18" color="#1C1B1F">
                  mdi-map-marker-outline
                </v-icon>
                <div class="text-caption grey--text text--darken-2">
                  {{ userData.city_locality?.name || "" }},
                  {{ userData.city?.name || "" }}
                </div>
              </v-row>
              <h3 class="font-weight-semibold text-start mt-1">
                {{
                  userData.category_experiences
                    ? userData.category_experiences[0]?.category.name
                    : ""
                }}
              </h3>
            </v-col>
          </v-row>
        </v-col>

        <!-- Right Section: Apply Now Button -->
        <!-- <v-col class="d-flex align-center justify-end">
          <v-btn
            color="primary"
            class="py-5"
            @click="is_job_app_login = true"
            large
          >
            <v-icon left size="20">mdi-phone-outline</v-icon>
            Contact Us
          </v-btn>
        </v-col> -->
      </v-row>
      <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>
      <v-row>
        <v-col sm="3">
          <v-card>
            <v-card-title>Overview</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div
                v-for="(overviewList, olIndex) in overviewLists"
                :key="olIndex"
              >
                <v-row dense>
                  <v-col sm="auto" align-self="center" class="mr-1">
                    <v-avatar size="24">
                      <v-icon>{{ overviewList.logo }}</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col>
                    <div class="text-subtitle-1 text-secondary">
                      {{ overviewList.name }}
                    </div>
                    <div class="text-subtitle-2 font-weight-bold">
                      {{ overviewList.value }}
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-row dense>
                <v-col>
                  <ul>
                    <li>{{ userData.address || "Address" }}</li>
                    <li>Phone: {{ userData.phone }}</li>
                    <li>Email: {{ userData.email }}</li>
                  </ul>
                </v-col>
              </v-row>

              <!-- <v-row class="mt-4">
                <v-col>
                  <v-btn block color="primary" dark>
                    <v-icon left>mdi-email-outline</v-icon>
                    Send Message
                  </v-btn>
                </v-col>
              </v-row> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="9">
          <v-row>
            <v-col sm="12">
              <h2>About Me</h2>
              <p>{{ userData.about_me || "No Description" }}</p>
            </v-col>
            <v-col sm="12">
              <h2>Skills</h2>
              <div v-if="userData.skills.length">
                <ul v-for="(skill, index) in userData.skills" :key="index">
                  <li>{{ skill }}</li>
                </ul>
              </div>
              <div v-else>No Skills</div>
            </v-col>

            <!-------- Work Experience -------->
            <v-col sm="12">
              <h2>Work Experience</h2>
              <v-row v-for="(job, index) in work_experiences" :key="index">
                <v-col sm="4">
                  <v-card outlined elevation="2">
                    <v-card-text style="color: rgb(35, 35, 35)">
                      <!-- Company Logo and Info -->
                      <v-row align="center">
                        <v-col cols="auto" style="padding-right: 0">
                          <v-avatar
                            size="75"
                            color="secondary"
                            class="d-flex align-center justify-center"
                            style="border: 1px solid #1f8bff"
                          >
                            <!-- <v-img
                              :src="job.organisation.logo_path"
                              contain
                            ></v-img> -->
                          </v-avatar>
                        </v-col>
                        <!-- <v-col>
                          <h6 class="text-h6 font-weight-bold mb-1 text-start">
                            {{ job.title }}
                          </h6>
                          <v-row dense align="center">
                            <v-icon size="18" color="#1C1B1F">
                              mdi-map-marker-outline
                            </v-icon>
                            <div class="text-caption grey--text text--darken-2">
                              {{ job.organisation.city_locality.name }},
                              {{ job.organisation.city.name }}
                            </div>
                          </v-row>
                        </v-col> -->
                      </v-row>

                      <!-- Job Title and Details -->
                      <v-row>
                        <v-col>
                          <p
                            class="text-subtitle-1 font-weight-semibold text-start"
                          >
                            {{ job.company_name }}
                          </p>
                          <v-row dense>
                            <!-- <v-col cols="auto" class="d-flex align-center">
                              <v-icon size="14" color="#1C1B1F" class="mr-1">
                                mdi-briefcase-outline
                              </v-icon>
                              <span class="text-caption grey--text">
                                {{
                                  job.job_type == 0 ? "Full Time" : "Part Time"
                                }}
                              </span>
                            </v-col> -->
                            <v-col cols="auto" class="d-flex align-center">
                              <v-icon size="14" color="#1C1B1F" class="mr-1">
                                mdi-clock-time-four-outline
                              </v-icon>
                              <span class="text-caption grey--text">
                                {{ formattedDate(job.updated_at) }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <!-- Skills -->
                      <v-row dense class="mb-2">
                        <v-chip
                          v-for="(skill, skillIndex) in job.skills.slice(0, 3)"
                          :key="skillIndex"
                          class="ma-1"
                          color="#E9F3FF"
                          text-color="black"
                          label
                          small
                        >
                          {{ skill.name }}
                        </v-chip>
                      </v-row>

                      <!-- Salary and Apply Button -->
                      <v-row>
                        <v-col
                          sm="12"
                          class="text-start"
                          style="padding: 0px 12px"
                        >
                          <span class="subtitle-1 font-weight-bold blue--text">
                            ₹{{ job.salary_range_from }} - ₹{{
                              job.salary_range_to
                            }}
                          </span>
                          <span class="subtitle-1 font-weight-bold">LPA</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-divider
      style="border: 1px solid #d7d7d7"
      class="mt-16 mb-10"
    ></v-divider>
    <!-- Newsletter Layout -->
    <v-container class="px-8" fluid>
      <NewsLetter />
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import NewsLetter from "../components/news-letter.vue";
export default {
  components: { NewsLetter },
  name: "CandidatePreviewPage",
  data() {
    return {
      filter: {},
      form: {},
      userData: {},
      jobs: [],
      jobTitleItems: [],
      qualificationItems: [],
      stateItems: [],
      cityItems: [],
      cityLocalityItems: [],
      skills: ["React Js", "Next Js", "Java"],
      overviewLists: [
        { logo: "mdi-account", name: "Experience", value: "2 Years" },
        {
          logo: "mdi-map-marker",
          name: "Preferred Location",
          value: "Mumbai, Pune",
        },
        {
          logo: "mdi-currency-inr",
          name: "Expected Salary",
          value: "₹15,000 - ₹1,00,000",
        },
        { logo: "mdi-school", name: "Education Level", value: "Graduate" },
      ],
    };
  },
  mounted() {
    this.getData();
    this.getWorkExperience();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let encryptedId = this.encryptIt(this.user.id);
      axios
        .get(`/users/${encryptedId}`)
        .then((response) => {
          this.userData = response.data.data;
          this.old_json = response.data.data;
          this.getUplineUsers();
        })
        .catch((error) => {
          // redirect to Home Page
          //   window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios
          .post(`/users`, this.form)
          .then(async () => {
            await this.saveUserTimestamp(this.old_json, this.form);
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
        this.isLoading = false;
        if (this.$route.name == "ProfilePage") {
          // this.$router.push("/supervisor");
          window.location.href = "/auth/login";
        } else {
          this.$router.push("/users");
        }
      } catch (e) {
        this.isLoading = false;
      }
    },
    async getWorkExperience() {
      this.isLoading = true;
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      let query = "&show_deleted=" + this.filter.show_deleted;
      await axios
        .get(`/jobs?${query}`)
        .then((response) => {
          this.jobs = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
  },
};
</script>
