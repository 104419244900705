<template>
  <v-main>
    <v-container class="my-10" v-if="isPgLoading == false">
      <!-- Header Section -->
      <v-row justify="center" class="mb-8">
        <v-col cols="12" md="8">
          <h1 class="text-center font-weight-bold">Unlock Premium Features</h1>
          <p class="text-center subtitle-1">
            Choose your plan and elevate your experience with exclusive perks.
          </p>
        </v-col>
      </v-row>

      <!-- Premium Plans -->
      <v-row justify="center" class="mb-8">
        <v-col
          cols="12"
          md="4"
          v-for="(plan, index) in premiumPlans"
          :key="index"
          class="d-flex"
        >
          <template>
            <v-card
              elevation="4"
              :class="['pa-4', plan.offer >= 50 ? 'featured-card' : '']"
              style="border-radius: 16px; width: 100%"
            >
              <!-- Badge -->
              <div v-if="plan.offer >= 50" class="badge">Best Value</div>
              <v-card-title
                class="text-h5 font-weight-bold text-center"
                style="display: flex; justify-content: center"
              >
                <p>
                  {{ plan.title }}
                </p>
              </v-card-title>
              <v-card-subtitle class="mb-3 text-center">
                <div class="text-h6 font-weight-bold">
                  ₹{{
                    calculateDiscount(plan.quantity, plan.amount, plan.offer) ||
                    ""
                  }}
                  <span class="text-caption">/ month</span>
                </div>
                <div class="text-subtitle-1 font-weight-bold">
                  {{ plan.offer }}% off
                  <span
                    v-if="plan.offer && plan.offer != 0"
                    class="mt-1 offer-text text-decoration-line-through"
                  >
                    {{ plan.quantity * plan.amount }}
                  </span>
                </div>
                <div class="text-subtitle-1 mt-5">
                  Get
                  <span class="font-weight-bold">
                    {{ plan.quantity * plan.credit }}
                  </span>
                  Credit points
                </div>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-actions class="justify-center mt-4">
                <v-btn
                  color="primary"
                  large
                  block
                  class="rounded-pill"
                  @click="openDialog(plan)"
                >
                  Purchase
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
      </v-row>

      <!-- Benefits Section -->
      <v-row justify="center">
        <v-col cols="12" md="8">
          <h2 class="text-center font-weight-bold mb-6">Why Go Premium?</h2>
          <v-row>
            <v-col
              cols="12"
              md="6"
              v-for="(benefit, index) in benefits"
              :key="index"
            >
              <v-sheet elevation="2" class="pa-4 mb-4 d-flex align-center">
                <v-icon color="success" class="mr-3">mdi-check-circle</v-icon>
                <span>{{ benefit }}</span>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Dialog -->
      <v-dialog v-model="isDialogVisible" max-width="500">
        <v-card>
          <v-card-title class="headline">Purchase Details</v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>Actual Price</v-list-item-content>
                <v-list-item-content class="text-right">
                  <p>₹{{ selectedPlan.quantity * selectedPlan.amount }}</p>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  Discount ({{ selectedPlan.offer }}%)
                </v-list-item-content>
                <v-list-item-content class="text-right">
                  <p>
                    -₹{{
                      calculateDiscountAmt(
                        selectedPlan.quantity,
                        selectedPlan.amount,
                        selectedPlan.offer
                      )
                    }}
                  </p>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-content>
                  GST ({{ selectedPlan.gst }}%)
                </v-list-item-content>
                <v-list-item-content class="text-right">
                  <p>₹{{ calculateGST(selectedPlan) }}</p>
                </v-list-item-content>
              </v-list-item> -->
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="font-weight-bold">
                  Total
                </v-list-item-content>
                <v-list-item-content class="text-right font-weight-bold">
                  <p>₹{{ calculateTotal(selectedPlan) }}</p>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="isDialogVisible = false">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="confirmPurchase">
              Confirm Purchase
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <div v-else class="full-page-loader">
      <Loader />
    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import Loader from "../components/loader-circle.vue";

export default {
  name: "PremiumsPage",
  components: { Loader },
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      premiumPlans: [],
      benefits: [
        "Highlighted with an urgent hiring tag",
        "Boost Job Visibilities",
        "Number of Direct Applies will be increased",
        "Number of Database Matches will be increased",
      ],
      isDialogVisible: false,
      selectedPlan: {},
      isPgLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isPgLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "page=" +
        this.page +
        "&rowsPerPage=" +
        this.rowsPerPage +
        "&show_deleted=" +
        this.filter.show_deleted +
        "&is_active=" +
        this.filter.is_active +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`/premiums?${query}`)
        .then((response) => {
          this.premiumPlans = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isPgLoading = false;
    },
    calculateDiscount(quantity, amount, offer) {
      if (!quantity || !amount) return "";
      // Calculate the discounted value
      const calAmt = amount * quantity;
      const discount = calAmt - (calAmt * offer) / 100;
      return discount; // Format to 2 decimal places
    },
    calculateDiscountAmt(quantity, amount, offer) {
      if (!quantity || !amount) return "";
      // Calculate the discounted value
      const calAmt = amount * quantity;
      const discount = (calAmt * offer) / 100;
      return discount; // Format to 2 decimal places
    },
    openDialog(plan) {
      this.selectedPlan = plan; // Set the selected plan
      this.isDialogVisible = true;
    },
    closeDialog() {
      this.isDialogVisible = false;
    },
    calculateGST(plan) {
      const discountPrice = this.calculateDiscount(
        plan.quantity,
        plan.amount,
        plan.offer
      );
      const gst = (discountPrice * plan.gst) / 100;
      return gst;
    },
    calculateTotal(plan) {
      const discountPrice = this.calculateDiscount(
        plan.quantity,
        plan.amount,
        plan.offer
      );
      const gst = this.calculateGST(plan);
      return parseFloat(discountPrice) + parseFloat(gst);
    },
    async confirmPurchase() {
      try {
        await axios
          .post("/user_premiums/make_payment", {
            user_id: this.user.id,
            user_phone: this.user.phone,
            premium_id: this.selectedPlan.id,
          })
          .then((response) => {
            window.location.href =
              response.data.data.data.instrumentResponse.redirectInfo.url;
          })
          .catch(function (error) {
            console.log(error);
            // window.location.href = "/auth/login";
          });
        this.isDialogVisible = false;
        // alert("Purchase successful!");
      } catch (error) {
        console.error(error);
        alert("Purchase failed!");
      }
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.text-center {
  text-align: center;
}

.badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: linear-gradient(45deg, #ff6f61, #ff8a65);
  color: white;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.featured-card {
  background: linear-gradient(135deg, #4c60af, #81a7c7);
  color: white;
  border: none;
}

.featured-card .text-h5,
.featured-card .offer-text {
  color: #ffffff;
}

.elevate {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.shrink {
  transform: translateY(0);
}

.offer-text {
  color: #8d1e1eda;
  font-weight: bold;
}

.subtitle-1 {
  color: #757575;
}
</style>
