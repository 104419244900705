<template>
  <v-container fluid v-if="isPgLoading == false">
    <v-row>
      <v-col>
        <h4 class="mb-2">Sort by Posted Jobs</h4>
        <v-autocomplete
          outlined
          v-model="filter.job_id"
          :items="jobItems"
          placeholder="Eg. Back Office / Data Entry"
          clearable
          hide-details
          :error-messages="errors.job_id"
          @change="getData"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row class="align-center justify-space-between" style="height: 90px">
      <!-- Showing Information -->
      <v-col class="text-start">
        <!-- <span>Showing </span>
        <span class="bold-text">41-60 </span>
        <span>of </span>
        <span class="bold-text">944 </span>
        <span>jobs</span> -->
      </v-col>
      <v-col>
        <v-tabs v-model="toggle_status" @change="getData">
          <v-tab value="">Applied</v-tab>
          <v-tab value="1">Contacted</v-tab>
          <v-tab value="2">Hired</v-tab>
          <v-tab value="3">Rejected</v-tab>
        </v-tabs>
      </v-col>

      <!-- Sort By Dropdown -->
      <!-- <v-col cols="auto">
        <v-autocomplete
          dense
          outlined
          v-model="filter.industry"
          :items="sortByItems"
          label="Sort By"
          hide-details
          clearable
          :error-messages="errors.industry"
        ></v-autocomplete>
      </v-col> -->
    </v-row>
    <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>
    <v-row class="mb-16">
      <v-col v-if="candidates.length === 0">
        <h3 class="text-center text-muted" style="color: #3d3d3d">
          {{ noCandidatesMessage }}
        </h3>
      </v-col>
      <v-col
        v-else
        sm="4"
        v-for="(candidate, index) in candidates"
        :key="index"
      >
        <CandidateCard
          :candidate="candidate"
          :job-id="filter.job_id"
          @status-updated="getData"
        />
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../components/loader-circle.vue";
import CandidateCard from "../components/candidate-card.vue";
export default {
  components: { Loader, CandidateCard },
  name: "JobAppliedCandidatesPage",
  data() {
    return {
      profile_section: "Applied Candidates",
      filter: {
        show_deleted: 0,
        position_id: 2,
        job_id: "",
        is_active: "",
        search_keyword: "",
        status: "",
      },
      count: 0,
      candidates: [],
      jobItems: [],
      sortByItems: ["Newest Post", "Oldest Post"],
      isPgLoading: false,
      toggle_status: 0,
      noCandidatesMessage:
        "Please select a job from the dropdown to see candidates.",
    };
  },
  mounted() {
    this.getJobData();
    this.getData();
  },
  methods: {
    handleStatus(title) {
      if (title == "Applied") {
        this.filter.status = "";
      } else if (title == "Contacted") {
        this.filter.status = 1;
      } else if (title == "Hired") {
        this.filter.status = 2;
      } else if (title == "Rejected") {
        this.filter.status = 3;
      }

      this.getData();
    },
    async getData() {
      console.log("Get Data");
      if (this.toggle_status == "1") {
        this.filter.status = "1";
      } else if (this.toggle_status == "2") {
        this.filter.status = "2";
      } else if (this.toggle_status == "3") {
        this.filter.status = "3";
      } else {
        this.filter.status = "";
      }
      if (!this.filter.job_id) {
        this.candidates = [];
        this.noCandidatesMessage =
          "Please select a job from the dropdown to see candidates.";
        return;
      }
      this.isPgLoading = true;

      this.filter.job_id = this.filter.job_id ? this.filter.job_id : "";
      let query = new URLSearchParams({
        page: this.page,
        rowsPerPage: this.rowsPerPage,
        role_id: 3,
        recruiter_id: this.user.id,
        ...this.filter, // Use the existing filter object directly
      });

      await axios
        .get(`/users?${query}`)
        .then((response) => {
          this.candidates = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);

          if (this.candidates.length === 0) {
            // Show message based on status
            switch (this.filter.status) {
              case 1:
                this.noCandidatesMessage =
                  "No candidates have been contacted for this job.";
                break;
              case 2:
                this.noCandidatesMessage =
                  "No candidates have been hired for this job.";
                break;
              case 3:
                this.noCandidatesMessage =
                  "No candidates have been rejected for this job.";
                break;
              default:
                this.noCandidatesMessage =
                  "No candidates have applied for this job.";
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isPgLoading = false;
    },
    async getJobData() {
      this.isPgLoading = true;

      let query = new URLSearchParams({
        is_active: 1,
        position_id: 2,
        show_deleted: 0,
        user_id: this.user.id,
      });

      await axios
        .get(`/jobs?${query}`)
        .then((response) => {
          this.jobs = response.data.data;
          //Categories
          this.jobs.forEach((job) => {
            this.jobItems.push({
              id: job.id,
              text: job.title,
              value: job.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isPgLoading = false;
    },
  },
};
</script>
