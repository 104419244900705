<template>
  <div>
    <v-card outlined elevation="2">
      <v-card-text style="color: rgb(35, 35, 35)">
        <!-- Company Logo and Info -->
        <v-row align="center">
          <v-col cols="auto" style="padding-right: 0">
            <v-avatar
              size="75"
              color="secondary"
              class="d-flex align-center justify-center"
              style="border: 1px solid #1f8bff"
            >
              <v-img :src="mediaUrl + candidate?.image_path" contain></v-img>
            </v-avatar>
          </v-col>
          <v-col>
            <h6 class="text-h6 font-weight-bold mb-1 text-start">
              {{ candidate.user_name }}
            </h6>
            <v-row dense align="center">
              <v-icon size="18" color="#1C1B1F">
                mdi-map-marker-outline
              </v-icon>

              <p class="text-subtitle-1 font-weight-semibold text-start">
                {{
                  candidate.category_experiences?.length
                    ? candidate.category_experiences[0].category.name
                    : "No Category"
                }}
              </p>
            </v-row>
          </v-col>
        </v-row>

        <!-- Job Title and Details -->
        <v-row> </v-row>

        <!-- Job Description -->
        <v-row class="mb-2" style="height: 80px">
          <v-col>
            <p
              class="line-clamp-3 text-body-2 text-start"
              v-html="candidate.about_me"
              v-if="candidate.about_me"
            ></p>
            <p class="text-body-2 text-start" v-else>Candidate Description</p>
          </v-col>
        </v-row>

        <!-- Skills -->
        <v-row dense class="mb-2" style="height: 32px">
          <div v-if="candidate.skills?.length != 0">
            <v-chip
              v-for="(skill, skillIndex) in candidate.skills.slice(0, 3)"
              :key="skillIndex"
              class="ma-1"
              color="#E9F3FF"
              text-color="black"
              label
              small
            >
              {{ skill.name }}
            </v-chip>
          </div>
          <div v-else>
            <v-chip class="ma-1" color="#E9F3FF" text-color="black" label small>
              Not Added Any Skills
            </v-chip>
          </div>
        </v-row>

        <!-- Salary and Apply Button -->
        <v-row>
          <v-col
            v-if="
              candidate.applied_jobs?.length &&
              [1, 3].includes(candidate.applied_jobs[0].pivot?.status)
            "
            style="padding-bottom: 10px"
          >
            <v-btn
              color="primary"
              class="py-4"
              :disabled="candidate.applied_jobs[0]?.pivot?.status != 1"
              @click="updateStatus(3)"
              block
              >Rejected</v-btn
            >
          </v-col>
          <v-col
            v-if="
              candidate.applied_jobs[0]?.pivot?.status == 2 ||
              candidate.applied_jobs[0]?.pivot?.status == 1
            "
            style="padding-bottom: 10px"
          >
            <v-btn
              color="indigo"
              class="py-4"
              @click="updateStatus(2)"
              block
              :disabled="candidate.applied_jobs[0]?.pivot?.status != 1"
              :dark="candidate.applied_jobs[0]?.pivot?.status == 1"
              >Hired</v-btn
            >
          </v-col>
          <v-col
            style="padding: 0 12px 10px"
            v-if="
              candidate.applied_jobs[0]?.pivot?.status != 1 &&
              candidate.applied_jobs[0]?.pivot?.status != 2 &&
              candidate.applied_jobs[0]?.pivot?.status != 3
            "
          >
            <v-btn
              color="indigo"
              class="py-4"
              @click="updateStatus(1)"
              block
              dark
              >Invite For Interview</v-btn
            >
          </v-col>
          <v-col cols="12" style="padding-top: 0">
            <v-btn
              color="secondary"
              class="py-4"
              @click="isViewDialog = !isViewDialog"
              block
            >
              View CV
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Dialog to show full details -->
    <v-dialog v-model="isViewDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Candidate Details</v-card-title>

        <v-card-text>
          <!-- Candidate Details -->
          <v-row>
            <v-col cols="9" align-self="end">
              <h1 class="mb-3">{{ candidate.user_name }}</h1>
              <h3>
                <span
                  v-for="(experience, index) in candidate.category_experiences"
                  :key="index"
                  style="color: #3b3b3b"
                >
                  {{ experience.category.name }}
                  <span
                    v-if="index < candidate.category_experiences.length - 1"
                  >
                    ,
                  </span>
                </span>
              </h3>
            </v-col>
            <v-col cols="3">
              <v-img
                :src="mediaUrl + candidate.image_path"
                height="90"
                contain
              ></v-img>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <p>
                <strong>About:</strong>
                <span
                  v-html="candidate.about_me || 'No description available.'"
                ></span>
              </p>

              <v-row dense class="mb-4">
                <v-col cols="12" style="padding-bottom: 0">
                  <p><strong>Skills:</strong></p>
                </v-col>
                <v-col
                  v-for="(skill, index) in candidate.skills"
                  :key="index"
                  cols="auto"
                >
                  <v-chip color="#E9F3FF" text-color="black" label small>
                    {{ skill.name }}
                  </v-chip>
                </v-col>
              </v-row>

              <p class="mb-2">
                <strong>Phone: </strong>
                <span>{{ candidate.phone }}</span>
              </p>
              <p>
                <strong>Email: </strong>
                <span>{{ candidate.email }}</span>
              </p>

              <p v-if="candidate.resume_url">
                <strong>Resume:</strong>
                <a :href="mediaUrl + candidate.resume_url" target="_blank"
                  >Download Resume</a
                >
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="secondary" @click="isViewDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "JobCard",
  props: {
    candidate: {
      type: Object,
      required: true,
    },
    jobId: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        email: "",
      },
      isViewDialog: false,
    };
  },
  methods: {
    async updateStatus(status) {
      await axios.post("/users/update_applied_jobs", {
        status,
        job_id: this.jobId,
        user_id: this.candidate.id,
      });
      if (status == 1) {
        this.$toast.success("Invited the candidate for interview", {
          position: "top-right",
          timeout: 3000,
        });
      } else if (status == 2) {
        this.$toast.success("Candidate is Hired for the job", {
          position: "top-right",
          timeout: 3000,
        });
      } else if (status == 3) {
        this.$toast.error("Candidate is Rejected for the job", {
          position: "top-right",
          timeout: 3000,
        });
      }
      this.$emit("status-updated");
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
</style>
