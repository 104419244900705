<template>
  <v-container fluid v-if="isPgLoading == false">
    <v-row class="align-center justify-space-between">
      <!-- Showing Information -->
      <v-col
        class="text-start"
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
      >
        <!-- <span>Showing </span>
        <span class="bold-text"> {{ count }} </span> -->
        <!-- <span>of </span>
        <span class="bold-text">944 </span> -->
        <!-- <span>jobs</span> -->

        <v-btn-toggle v-model="toggle_status" color="primary" dense group>
          <v-btn :value="0" @click="handleStatus('Applied')" text>
            Applied
          </v-btn>

          <v-btn :value="1" @click="handleStatus('Approval Pending')" text>
            Approval Pending
          </v-btn>

          <v-btn :value="2" @click="handleStatus('Hired')" text> Hired </v-btn>

          <v-btn :value="3" @click="handleStatus('Rejected')" text>
            Rejected
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <!-- Sort By Dropdown -->
      <v-col cols="auto" v-else>
        <v-autocomplete
          dense
          outlined
          v-model="filter.applied_job_status"
          :items="statusItems"
          label="Select Status"
          hide-details
          clearable
          :error-messages="errors.industry"
          @change="getData"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>
    <v-row class="mb-16">
      <v-col v-if="jobs.length == 0">
        <h3 class="text-center text-muted" style="color: #3d3d3d">
          {{ noJobMessage }}
        </h3>
      </v-col>
      <v-col v-else sm="4" v-for="(jobData, jobIndex) in jobs" :key="jobIndex">
        <JobCard :job="jobData" />
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import Loader from "../components/loader-circle.vue";
import JobCard from "../components/job-card.vue";
import axios from "axios";
export default {
  components: { Loader, JobCard },
  name: "JobAppliedCandidatesPage",
  data() {
    return {
      profile_section: "Applied Candidates",
      filter: {
        show_deleted: 0,
        position_id: 2,
        job_id: "",
        is_active: "",
        search_keyword: "",
        status: "",
      },
      noJobMessage: "No Data",
      jobs: [],
      statusItems: [
        { text: "Applied", value: "" },
        { text: "Approval Pending", value: 1 },
        { text: "Hired", value: 2 },
        { text: "Rejected", value: 3 },
      ],
      count: 0,
      sortByItems: ["Newest Post", "Oldest Post"],
      isPgLoading: false,
      toggle_status: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleStatus(title) {
      if (title == "Applied") {
        this.filter.applied_job_status = "";
      } else if (title == "Approval Pending") {
        this.filter.applied_job_status = 1;
      } else if (title == "Hired") {
        this.filter.applied_job_status = 2;
      } else if (title == "Rejected") {
        this.filter.applied_job_status = 3;
      }

      this.getData();
    },
    async getData() {
      this.isPgLoading = true;

      let query = new URLSearchParams({
        page: this.page,
        rowsPerPage: this.rowsPerPage,
        candidate_id: this.user.id,
        ...this.filter, // Use the existing filter object directly
      });

      await axios
        .get(`/jobs?${query}`)
        .then((response) => {
          this.jobs = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);

          if (this.jobs.length === 0) {
            // Show message based on status
            switch (this.toggle_status) {
              case 1:
                this.noJobMessage =
                  "No job have been contacted.";
                break;
              case 2:
                this.noJobMessage =
                  "No job have been hired.";
                break;
              case 3:
                this.noJobMessage =
                  "No job have been rejected.";
                break;
              default:
                this.noJobMessage = "No job have applied.";
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isPgLoading = false;
    },
  },
};
</script>
