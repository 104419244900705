<template>
  <v-card outlined elevation="2">
    <v-icon
      v-if="job.is_premium === 1"
      class="premium-icon"
      color="amber darken-2"
      size="30"
    >
      mdi-crown
    </v-icon>
    <v-card-text
      style="color: rgb(35, 35, 35)"
      class="job-card"
      :class="{ 'premium-card': job.is_premium === 1 }"
    >
      <!-- Company Logo and Info -->
      <v-row align="center">
        <v-col cols="auto" style="padding-right: 0">
          <v-avatar
            size="75"
            color="secondary"
            class="d-flex align-center justify-center"
            style="border: 1px solid #1f8bff"
          >
            <v-img
              :src="
                job.organisation.logo_path
                  ? mediaUrl + job.organisation.logo_path
                  : require('@/assets/office-building.webp')
              "
              contain
            ></v-img>
          </v-avatar>
        </v-col>
        <v-col>
          <h6 class="text-h6 font-weight-bold mb-1 text-start">
            {{ job.organisation ? job.organisation.name : "Company Name" }}
          </h6>
          <v-row dense align="center">
            <v-icon size="18" color="#1C1B1F"> mdi-map-marker-outline </v-icon>
            <div class="text-caption grey--text text--darken-2">
              {{
                job.organisation && job.organisation?.city_locality
                  ? job.organisation?.city_locality.name
                  : "City Locality"
              }},
              {{
                job.organisation && job.organisation?.city
                  ? job.organisation?.city.name
                  : "City"
              }}
            </div>
          </v-row>
        </v-col>
      </v-row>

      <!-- Job Title and Details -->
      <v-row>
        <v-col>
          <p class="text-subtitle-1 font-weight-semibold text-start">
            {{ job.title }}
          </p>
          <v-row dense>
            <v-col cols="auto" class="d-flex align-center">
              <v-icon size="14" color="#1C1B1F" class="mr-1">
                mdi-briefcase-outline
              </v-icon>
              <span class="text-caption grey--text">
                {{ job.job_type == 0 ? "Full Time" : "Part Time" }}
              </span>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <v-icon size="14" color="#1C1B1F" class="mr-1">
                mdi-clock-time-four-outline
              </v-icon>
              <span class="text-caption grey--text">
                {{ formattedDate(job.updated_at) }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Job Description -->
      <v-row class="mb-2">
        <v-col>
          <p class="line-clamp-3 text-body-2 text-start" style="height: 60px">
            {{ job.descriptions }}
          </p>
        </v-col>
      </v-row>

      <!-- Skills -->
      <v-row dense class="mb-2" style="height: 64px">
        <v-chip
          v-for="(skill, skillIndex) in job.skills?.slice(0, 3)"
          :key="skillIndex"
          class="ma-1"
          color="#E9F3FF"
          text-color="black"
          label
          small
        >
          {{ skill.name }}
        </v-chip>
      </v-row>

      <!-- Salary and Apply Button -->
      <v-row>
        <v-col
          cols="auto"
          class="text-start"
          style="padding: 0px 12px"
          align-self="center"
        >
          <span
            class="subtitle-1 font-weight-bold"
            style="color: var(--v-primary-base)"
          >
            ₹{{ job.salary_range_from / 100000 }} - ₹{{
              job.salary_range_to / 100000
            }}
          </span>
          <span class="subtitle-1 font-weight-bold">LPA</span>
          <v-spacer></v-spacer>
        </v-col>
        <!-- <v-col class="text-end" v-if="selectedTab == ''">
          <v-chip color="status-chip" :class="jobStatusClass" >
            {{ jobStatusText }}
          </v-chip>
        </v-col> -->
        <v-col
          cols="12"
          style="padding-bottom: 0px"
          v-if="user.position_id == 1"
        >
          <v-btn
            color="amber darken-2"
            class="py-4"
            :disabled="job.is_premium === 1"
            @click="handlePremiumJob"
            block
          >
            Add Premium
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            class="py-4"
            block
            :to="`/job-details/${encryptIt(job.id)}`"
          >
            View Job
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline"> Confirm Action </v-card-title>
        <v-card-text>
          Are you sure you want to use <strong>500 credits</strong> on
          <strong>{{ job.title }}</strong> job?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="addPremiumJob">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Not Enough Credits Dialog -->
    <v-dialog v-model="notEnoughCreditsDialog" max-width="400">
      <v-card>
        <v-card-title class="headline"> Not Enough Credits </v-card-title>
        <v-card-text>
          You don't have enough credits to make this job premium. Would you like
          to purchase more credits?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="notEnoughCreditsDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="redirectToPremium">
            Buy Credits
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import axios from "axios";
export default {
  name: "JobCard",
  props: {
    job: {
      type: Object,
      required: true,
    },
    selectedTab: {
      type: [String, Number],
    },
  },
  data() {
    return {
      form: {
        email: "",
        is_premium: false,
        premium_start_date: "",
      },
      dialog: false,
      notEnoughCreditsDialog: false,
    };
  },
  computed: {
    jobStatusText() {
      return this.job.status == 1
        ? "Approved"
        : this.job.status == 2
        ? "Rejected"
        : "Pending";
    },
    jobStatusClass() {
      return this.job.status == 1
        ? "green"
        : this.job.status == 2
        ? "red"
        : "yellow";
    },
  },
  methods: {
    handlePremiumJob() {
      if (this.user.credits < 500) {
        this.notEnoughCreditsDialog = true; // Open the buy credits dialog
        return;
        // this.$toast.error("Not enough credits!", {
        //   position: "top-right",
        //   timeout: 3000,
        // });
      }
      this.dialog = true;
    },
    redirectToPremium() {
      this.$router.push("/premiums"); // Redirect to the premium purchase page
    },
    async addPremiumJob() {
      try {
        this.form.user_id = this.user.id;
        this.form.is_premium = true;
        this.form.premium_start_date = new Date().toISOString();

        await axios.patch(`/jobs/${this.encryptIt(this.job.id)}`, this.form);
        this.$emit("get-data");
        this.isLoading = false;
        window.location.reload();
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
p {
  margin-bottom: 0;
}
.status-chip {
  /* position: absolute;
  top: 10px;
  right: 10px; */
  font-size: 12px;
  font-weight: bold;
  padding: 4px 10px;
  border-radius: 8px;
}
.green {
  background-color: #478b4a !important; /* Dark Green */
  color: #ffffff !important;
}

.red {
  background-color: #c62828 !important; /* Classic Red */
  color: #ffffff !important;
}

.yellow {
  background-color: rgb(255, 218, 53) !important; /* Classic Yellow */
  color: #000000 !important;
}

.job-card {
  transition: 0.3s ease-in-out;
  position: relative;
}

/* .premium-card {
  background: url('@/assets/sapp-only-logo.png') no-repeat center;
  background-size: cover;
  background-color: rgba(255, 215, 0, 0.2);
  border: 2px solid #ffd700;
} */

.premium-icon {
  position: absolute;
  top: 6px;
  right: 10px;
  font-size: 28px;
}
</style>
