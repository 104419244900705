var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',[_c('v-container',{staticClass:"py-8 py-sm-12 px-5",staticStyle:{"background":"#1f8bff","color":"white"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"font-weight-bold text-h5 text-md-h3",staticStyle:{"font-size":"42px"}},[_c('span',[_vm._v("Explore Jobs By Roles")])])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"pa-3 pa-sm-5",attrs:{"elevation":"1"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",staticStyle:{"padding":"0 0 0 24px"},attrs:{"cols":"10","sm":"9"}},[_c('v-text-field',{staticClass:"search-textfield",attrs:{"dense":"","solo":"","label":"Your Keyword","prepend-inner-icon":"mdi-keyboard-variant","hide-details":""},model:{value:(_vm.filter.search_keyword),callback:function ($$v) {_vm.$set(_vm.filter, "search_keyword", $$v)},expression:"filter.search_keyword"}})],1),_c('v-col',{staticClass:"d-none d-sm-flex",staticStyle:{"padding":"0 20px"},attrs:{"sm":"3"}},[_c('v-btn',{attrs:{"color":"primary","large":"","block":""},on:{"click":function($event){(_vm.page = 1), _vm.getData()}}},[_c('v-icon',{attrs:{"left":"","size":"26"}},[_vm._v("mdi-magnify")]),_vm._v("Search ")],1)],1),_c('v-col',{staticClass:"d-sm-none",staticStyle:{"padding":"0 12px"},attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","large":"","icon":""},on:{"click":function($event){(_vm.page = 1), _vm.getData()}}},[_c('v-icon',{attrs:{"left":"","size":"26"}},[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center text-caption text-sm-body-1",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v("Popular Searches: ")]),_vm._l(([
            'Designer',
            'Web',
            'iOS',
            'Developer',
            'PHP',
            'Senior',
            'Engineer',
          ]),function(item,index){return _c('span',{key:index,staticClass:"text-decoration-underline mx-1",staticStyle:{"font-weight":"300"}},[_vm._v(" "+_vm._s(item)),(index < 6)?_c('span',[_vm._v(",")]):_vm._e()])})],2)],1)],1),_c('v-container',{staticClass:"py-5"},[_c('h1',{staticClass:"text-center font-weight-bold text-h6 text-sm-h4 mb-6"},[_vm._v("Job Roles")]),_c('v-row',{attrs:{"justify":"center","align":"start"}},[(!_vm.isLoading)?_vm._l((_vm.roles),function(role){return _c('v-col',{key:role,staticClass:"text-center",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',{staticClass:"cardLay",attrs:{"outlined":"","elevation":"1","to":`/jobs?category=${role.id}`}},[_c('v-card-title',{staticClass:"d-flex justify-center font-weight-medium text-body-1",staticStyle:{"padding":"8px 16px"}},[_vm._v(" "+_vm._s(role.name)+" ")])],1)],1)}):[_c('v-row',_vm._l((4),function(n){return _c('v-col',{key:'skeleton-' + n,staticClass:"text-center",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticStyle:{"padding":"16px 16px 10px"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"80%","type":"text"}})],1)],1)],1)}),1)]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }