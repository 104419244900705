import { mapGetters } from "vuex";
import axios from "axios";
import Vue from "vue";

const mixin = {
  install(Vue) {
    Vue.mixin({
      methods: {
        // Define your universal function
        encryptIt(string) {
          const CryptoJS = require("crypto-js");
          const secretKey = CryptoJS.enc.Utf8.parse(
            "12345678123456781234567812345678"
          );
          const iv = CryptoJS.enc.Utf8.parse("Ef7ix7ETPgghl3vP");
          let encrypted = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(string),
            secretKey,
            { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
          ).toString();
          return encrypted.replace(/[+]/g, "-").replace(/[/]/g, "_");
        },
        decryptIt(string) {
          const CryptoJS = require("crypto-js");
          const secretKey = CryptoJS.enc.Utf8.parse(
            "12345678123456781234567812345678"
          );
          const iv = CryptoJS.enc.Utf8.parse("Ef7ix7ETPgghl3vP");
          string = string.replace(/[-]/g, "+").replace(/[_]/g, "/");
          let encrypted = CryptoJS.AES.decrypt(string, secretKey, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }).toString(CryptoJS.enc.Utf8);
          return encrypted;
        },
        async saveUserTimestamp(old_json, new_json) {
          try {
            let form = {
              from_path: this.$route.path,
              from_name: this.$route.name,
              user_id: this.user.id,
              old_json: old_json ? JSON.stringify(old_json) : null,
              new_json: JSON.stringify(new_json),
            };
            await axios.post(`/user_timestamps`, form);
          } catch (error) {
            console.log(error);
          }
        },
        formattedDate(date) {
          if (!date) {
            return "";
          }
          const updatedDate = new Date(date);
          if (isNaN(updatedDate.getTime())) {
            return "";
          }
          return updatedDate.toISOString().split("T")[0];
        },
        genderType(gender) {
          switch (gender) {
            case 0:
              return "Any";
            case 1:
              return "Male";
            case 2:
              return "Female";
            default:
              return "";
          }
        },
        pascalCaseText(text) {
          if (!text) return "";
          text = String(text);
          // If text is fully uppercase, convert to Pascal Case
          if (text === text.toUpperCase()) {
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
          }
          return text; // Otherwise, keep it as is
        },
        onlyAlphabets(event) {
          const char = String.fromCharCode(event.keyCode); // Get the pressed key
          if (!/^[A-Za-z\s]+$/.test(char)) {
            event.preventDefault(); // Block invalid characters
          }
        },
        onlyNumbers(event) {
          if (!/^[0-9]$/.test(event.key)) {
            event.preventDefault();
          }
        },
        isDeletePage() {
          let response = false;
          if (this.$route.query.open_trash) {
            response = true;
          }
          return response;
        },
      },
      computed: {
        ...mapGetters({
          authenticated: "auth/authenticated",
          user: "auth/user",
          company: "auth/company",
          errors: "errors/getErrors",
          status: "errors/getStatus",
          baseUrl: "settings/getBaseUrl",
          mediaUrl: "settings/getMediaUrl",
          rowsPerPage: "settings/getRowsPerPage",
          roleName: "auth/roleName",
        }),
      },
    });
  },
};

Vue.use(mixin);
