var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:({
    backgroundImage: `url(${require('@/assets/newsletter.png')})`,
    borderRadius: '30px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '50px 110px',
    height: '300px',
  }),attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"mb-4",staticStyle:{"color":"white"}},[_c('span',[_vm._v("New Things Will Aways ")]),_c('br'),_c('span',[_vm._v(" Update Regularly ")])])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{attrs:{"width":"500"}},[_c('v-card-text',{staticClass:"pt-5 pb-6"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",staticStyle:{"padding":"0 0 0 24px"},attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{attrs:{"dense":"","label":"Enter Email Here","prepend-inner-icon":"mdi-email","hide-details":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('v-col',{staticStyle:{"padding":"0 16px"},attrs:{"sm":"3"}},[_c('v-btn',{staticClass:"mt-1 py-5",attrs:{"color":"primary","small":""}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-check-decagram-outline")]),_vm._v(" Subscribe ")],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }