<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            v-if="roleName == 'SUPER ADMIN' && company.id == null"
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Company
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Company Name *"
                prepend-inner-icon="mdi-buffet"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.tag_line"
                outlined
                label="Tag line"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.tag_line"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.email"
                outlined
                label="Email *"
                prepend-inner-icon="mdi-email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.phone"
                outlined
                label="Phone *"
                prepend-inner-icon="mdi-phone"
                :error-messages="errors.phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.admin_name"
                outlined
                label="Admin Name"
                prepend-inner-icon="mdi-card-account-phone"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.address"
                outlined
                label="Address *"
                prepend-inner-icon="mdi-map-marker-multiple"
                :error-messages="errors.address"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.state"
                outlined
                label="State "
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.state"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.city"
                outlined
                label="City"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.city"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.pincode"
                outlined
                label="Pincode"
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.pincode"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.tax_percent"
                outlined
                label="Tax Percent "
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.tax_percent"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.packaging_cost"
                outlined
                label="Packaging Costs "
                prepend-inner-icon="mdi-map-marker"
                :error-messages="errors.packaging_cost"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <input
                type="file"
                id="file"
                name="file"
                :ref="`file`"
                @change="onFileChange"
              />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="($refs.file.value = null), (image_url = null)"
                >Remove</v-btn
              >

              <div id="preview" v-if="image_url" class="mt-5">
                <label for="">Image Preview</label>
                <br />
                <img
                  :src="image_url"
                  style="height: 30%; width: 30% !important"
                />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2"> </v-divider>
          <h3 class="my-4">List of Flag(s) for this Company:</h3>
          <v-row>
            <v-col sm="3">
              <v-switch
                class="mt-0"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
            <!-- <v-col sm="3">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_location_tracking_enabled"
                inset
                :label="`Is Location tracking Enabled: ${
                  form.is_location_tracking_enabled ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="3">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_SUSP_enabled"
                inset
                :label="`Is SUSP Enabled: ${
                  form.is_SUSP_enabled ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col>
            <v-col sm="3">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_SUSL_enabled"
                inset
                :label="`Is SUSL Enabled: ${
                  form.is_SUSL_enabled ? 'YES' : 'NO'
                }`"
              ></v-switch>
            </v-col> -->
            <v-col sm="12">
              <label for="about-us">About Us</label>
              <vue-editor
                ref="about us"
                v-model="form.about_us"
                class="form-control"
              />
            </v-col>
            <v-col sm="12">
              <label for="terms-conditions">Term & Condition</label>
              <vue-editor
                ref="term condition"
                v-model="form.terms"
                class="form-control"
              />
            </v-col>
            <v-col sm="12">
              <label for="privacy-policy">FQAs</label>
              <vue-editor ref="faqs" v-model="form.faqs" class="form-control" />
            </v-col>
            <v-col sm="12">
              <label for="privacy">Privacy Policy</label>
              <vue-editor
                ref="privacy"
                v-model="form.privacy"
                class="form-control"
              />
            </v-col>
            <v-col sm="12">
              <label for="return-policy">Return Policy</label>
              <vue-editor
                ref="return policy"
                v-model="form.return_policy"
                class="form-control"
              />
            </v-col>
            <v-col sm="12">
              <label for="refund-policy">Refund Policy</label>
              <vue-editor
                ref="refund policy"
                v-model="form.refund_policy"
                class="form-control"
              />
            </v-col>
            <v-col sm="12">
              <label for="cancellation-policy">Cancellation Policy</label>
              <vue-editor
                ref="cancellation policy"
                v-model="form.cancellation_policy"
                class="form-control"
              />
            </v-col>
            <v-col sm="12">
              <label for="disclaimer " class="dark-label">Disclaimer</label>
              <vue-editor
                ref="disclaimer"
                v-model="form.disclaimer"
                class="form-control"
              />
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Company
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
export default {
  name: "CompanyEditPage",
  components: {
    VueEditor,
  },
  data() {
    return {
      form: {
        name: "",
        is_active: true,
      },
      image_url: "",
      old_json: {},
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.companyId;
      axios
        .get(`/companies/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
          this.image_url = this.form.logo_path
            ? `${this.mediaUrl + this.form.logo_path}`
            : "";
        })
        .catch((error) => {
          // redirect to Home Page
          window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        await axios.patch(
          `/companies/${this.$route.params.companyId}`,
          this.form
        );
        await this.handleFileUpload();
        await this.saveUserTimestamp(this.old_json, this.form);
        this.isLoading = false;
        if (
          this.roleName == "ADMIN" ||
          (this.roleName == "SUPER ADMIN" && this.company.id != null)
        ) {
          window.location.href = "/auth/login";
        } else {
          this.$router.push("/companies");
        }
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      console.log("called");
      if (this.$refs.file != null) {
        console.log("uploading");
        let attachment = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("id", this.form.id);
        formData.append("logo_path", attachment);
        await axios
          .post("upload_company_logo_path", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .catch(function (error) {
            console.log(error);
            console.log("FAILURE!!");
          });
      }
    },
    onFileChange() {
      this.warningImageLoader = false;
      this.file = this.$refs.file?.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (evt) => {
        let img = new Image();
        img.onload = () => {
          this.imageLoader = false;
          this.image_url = URL.createObjectURL(this.file);
          this.imageErrors = "";
        };
        img.src = evt.target.result;
      };

      reader.onerror = (evt) => {
        console.error(evt);
      };
      // this.image_url = URL.createObjectURL(this.file);
    },
  },
};
</script>
