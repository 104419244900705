<template>
  <v-main>
    <v-container style="max-width: 1440px; padding: 50px 0" class="px-4">
      <v-row justify="center" align="center" class="mb-12">
        <v-col cols="12" md="8" class="text-center">
          <h2 class="primary--text text-h5 font-weight-regular text-capitalize">
            Sign Up as {{ $route.params.position }}
          </h2>
          <h1 class="text-h4 font-weight-bold">
            Sign In to Discover Your Dream Job!
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col cols="12" md="4" class="text-center">
          <v-btn
            block
            outlined
            color="secondary"
            class="mb-4"
            height="62"
            @click="loginWithGoogle"
          >
            <v-icon left large>mdi-google</v-icon>
            Sign Up with Google
          </v-btn>

          <div class="d-flex align-center justify-center mb-6">
            <v-divider class="flex-grow-1"></v-divider>
            <span class="mx-2 grey--text">Or Continue With</span>
            <v-divider class="flex-grow-1"></v-divider>
          </div>
          <v-row>
            <v-col cols="12" style="padding: 0 12px">
              <p class="font-weight-bold text-start mb-1">Phone</p>
              <v-text-field
                v-model="form.phone"
                outlined
                solo
                label="Phone"
                maxlength="10"
                placeholder="Enter Phone"
                clearable
                @click:clear="form.phone = null"
                @keypress="onlyNumbers"
                :error-messages="errors.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" style="padding: 0 14px" v-if="isPhone">
              <p class="font-weight-bold text-start mb-1">OTP</p>
              <v-otp-input v-model="form.otp"></v-otp-input>
            </v-col>
          </v-row>
          <v-btn
            v-if="isPhone && form.otp.length < 6"
            block
            class="mt-6"
            color="primary"
            :disabled="isButtonDisabled"
            height="50"
            @click="sendOtp"
          >
            {{ otpButtonText }}
          </v-btn>
          <v-btn
            v-else
            block
            class="mt-6"
            color="primary"
            height="50"
            @click="nextPg"
            :disabled="form.otp.length != 6"
          >
            Next
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        is_registration: true,
        position_id: this.$route.params.position == "recruiter" ? 1 : 2,
        role_id: "3",
        first_name: "",
        middle_name: "",
        last_name: "",
        phone: "",
        otp: "",
        email: "",
        password: "",
        password_confirmation: "",
        soft_password: "123456",
      },
      loggedInRole: "",
      logo_path: "../../assets/bisleri-logo.png",
      is_company_user: false,
      showPassword: false,
      isLoading: false,
      isButtonDisabled: false,
      otpButtonText: "Send OTP",
    };
  },
  computed: {
    isPhone() {
      // Check if phone is a 10-digit number
      return this.form.phone && /^\d{10}$/.test(this.form.phone);
    },
  },
  watch: {
    "$route.params.position": {
      immediate: true,
      handler(newPosition) {
        this.form.position_id = newPosition === "recruiter" ? 1 : 2;
      },
    },
  },
  methods: {
    async nextPg() {
      const positionId = this.$route.params.position == "recruiter" ? 1 : 2;
      sessionStorage.setItem("phone", this.form.phone);

      if (positionId == 1) {
        sessionStorage.setItem("positionId", positionId);
        this.$router.push({ name: "RecruiterRegisterPage" });
      } else {
        sessionStorage.setItem("positionId", positionId);
        this.$router.push({ name: "CandidateRegisterPage" });
      }

      // Temporary
      // this.$router.push({
      //   name: "CandidateRegisterPage", // Your profile page route name
      //   state: { phone: this.form.phone }, // Pass the phone number here
      // });
    },
    async loginWithGoogle() {
      try {
        const response = await axios.get(
          `/auth/google?position_id=${this.form.position_id}`
        );
        window.location.href = response.data.url; // Redirect to Google's login page
      } catch (error) {
        console.error("Failed to initiate Google login:", error);
      }
    },
    async sendOtp() {
      await axios
        .post(`/send_otp`, this.form)
        .then(() => {})
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      this.isLoading = false;
      this.disableOtpButton();
    },
    disableOtpButton() {
      this.isButtonDisabled = true;
      let remainingTime = 20; // 20 seconds timer
      this.otpButtonText = `Wait ${remainingTime}s`;

      const timer = setInterval(() => {
        remainingTime--;
        if (remainingTime <= 0) {
          clearInterval(timer);
          this.isButtonDisabled = false;
          this.otpButtonText = "Send OTP"; // Reset button text
        } else {
          this.otpButtonText = `Wait ${remainingTime}s`;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.v-text-field {
  border-radius: 10px;
}
</style>
