<template>
  <div>
    <section v-if="isLoading" style="height: 285px">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </section>
    <v-simple-table v-else fixed-header class="striped-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left sr_no">Sr No</th>
            <th class="text-left">Status</th>
            <th class="text-left" style="min-width: none">Premium Offer</th>
            <th class="text-left" style="min-width: none">Credit Points</th>
            <th class="text-left" style="min-width: none">Amount Paid (₹)</th>
            <th class="text-left" style="min-width: none">Issue Date</th>
          </tr>
        </thead>
        <tbody v-if="user_premiums.length != 0">
          <tr
            v-for="(user_premium, i) in user_premiums"
            :key="`user_premium_${i}`"
            :class="{
              'table-row-even': i % 2 === 0,
              'table-row-odd': i % 2 !== 0,
              'table-row-last': user_premiums.length - 1 == i,
            }"
          >
            <td style="min-width: 85px">
              {{ i + 1 }}
            </td>
            <td>
              <v-tooltip bottom v-if="user_premium.status == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="orange"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon size="15">mdi-close-circle</v-icon>
                    </v-avatar>
                    Pending
                  </v-chip>
                </template>
                <span>User Premium is InActive</span>
              </v-tooltip>
              <v-tooltip bottom v-if="user_premium.status == 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="teal"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                    </v-avatar>
                    Approved
                  </v-chip>
                </template>
                <span>User Premium is Active</span>
              </v-tooltip>
              <v-tooltip bottom v-if="user_premium.status == 2">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="red"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon size="15">mdi-checkbox-marked-circle</v-icon>
                    </v-avatar>
                    Rejected
                  </v-chip>
                </template>
                <span>User Premium is Active</span>
              </v-tooltip>
            </td>
            <td>
              {{ user_premium.premium.title || "" }}
            </td>
            <td>
              {{
                user_premium.premium.quantity * user_premium.premium.credit ||
                ""
              }}
            </td>
            <td>
              {{
                calculateDiscount(
                  user_premium.premium.quantity,
                  user_premium.premium.amount,
                  user_premium.premium.offer
                ) || ""
              }}
            </td>
            <td>
              {{ formattedDate(user_premium.created_at) || "" }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center">No data found</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UserPremiumsPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      user_premiums: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      ExportUserPremiumTypes: [],
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      excel_fields: {
        "Sr No": "sr_no",
        Name: "name",
      },
      isLoading: false,
      isExcelloading: false,
      delete_user_premium: false,
      is_deleted_user_premium: false,
      is_restored_user_premium: false,
    };
  },
  watch: {
    page: "getData",
    $route() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "show_deleted=" +
        this.filter.show_deleted +
        "&is_active=" +
        this.filter.is_active +
        "&user_id=" +
        this.user.id +
        "&payment_status=" +
        1 +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`/user_premiums?${query}`)
        .then((response) => {
          this.user_premiums = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    calculateDiscount(quantity, amount, offer) {
      if (!quantity || !amount) return "";
      // Calculate the discounted value
      const calAmt = amount * quantity;
      const discount = calAmt - (calAmt * offer) / 100;
      return discount; // Format to 2 decimal places
    },
  },
};
</script>
