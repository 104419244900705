<template>
  <v-card
    class="cardLay rounded-xl mb-2"
    :width="cardWidth"
    outlined
    elevation="1"
    :to="city.jobs_count != 0 && `/jobs?city=${city.id}`"
  >
    <v-card-text style="padding: 10px">
      <v-img
        :src="mediaUrl + city?.image_path"
        style="background-color: gray"
        height="250"
        class="rounded-xl"
        alt="City Image"
      ></v-img>
      <div class="mt-4" style="color: rgb(35, 35, 35)">
        <h3 class="text-h6 font-weight-bold">
          {{ city.name }}{{ city.state ? `, ${city.state.name}` : "" }}
        </h3>
        <v-row>
          <v-col>
            <p
              class="text-subtitle-2"
              style="margin: 0; color: var(--v-primary-base)"
            >
              {{
                city.jobs_count != 0
                  ? `${city.jobs_count} Jobs`
                  : "Coming soon..."
              }}
            </p>
          </v-col>
          <v-col class="d-flex justify-end" v-if="city.jobs_count != 0">
            <p
              class="text-subtitle-2"
              style="margin: 0; color: var(--v-primary-base)"
            >
              {{ city.organisations_count }} Companies
            </p>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ExploreJobCard",
  props: {
    city: {
      type: Object,
      required: true,
    },
    cardWidth: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style></style>
