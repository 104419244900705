<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Module
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-text-field
                v-model="form.name"
                outlined
                label="Name"
                prepend-inner-icon="mdi-card-text-outline"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Module
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "ModuleEditPage",
  data() {
    return {
      form: {
        is_active: false,
      },
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // async getData() {
    //   this.isLoading = true;
    //   let form = await axios.get(`/modules/${this.$route.params.moduleId}`);
    //   this.form = form.data.data;
    //   this.old_json = form.data.data;
    //   this.isLoading = false;
    // },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.moduleId;
      axios
        .get(`/modules/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        await axios.patch(`/modules/${this.$route.params.moduleId}`, this.form);
        await this.saveUserTimestamp(this.old_json, this.form);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async saveUserTimestamp() {
      try {
        let form = {
          from_path: `/module/${this.form.id}`,
          from_name: "ModuleEditPage",
          user_id: this.user.id,
          old_json: JSON.stringify(this.old_json),
          new_json: JSON.stringify(this.form),
        };
        await axios.post(`/user_timestamps`, form);
        this.$router.push("/modules");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
