import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1F8BFF", // Default color
        recruiterPrimary: "#b01c1c", // Default color
      },
    },
    options: {
      customProperties: true,
    },
  },
});

export function setThemeBasedOnUser(userPosition) {
  const theme = userPosition == 1 ? "#b01c1c" : "#1F8BFF";
  vuetify.framework.theme.themes.light.primary = theme;
}

export default vuetify;