<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Add User
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-autocomplete
                ref="user"
                outlined
                v-model="form.user_id"
                :items="userItems"
                label="User"
                prepend-inner-icon="mdi-account-tie-hat-outline"
                clearable
                :error-messages="errors.user_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-menu
                v-model="start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.start_date"
                    label="Start Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.start_date = null"
                    :error-messages="errors.start_date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.start_date"
                  @change="start_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Save User
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
export default {
  name: "OutletUserCreatePage",
  data() {
    return {
      form: {
        user_id: "",
        outlet_id: "",
        start_date: "",
        end_date: "",
      },
      userItems: [],
      isLoading: false,
      start_date_menu: false,
    };
  },
  mounted() {
    this.form.outlet_id = this.decryptIt(this.$route.params.outletId);
    this.getMasters();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`outlet_users/masters`)
        .then((masters) => {
          masters = masters.data;
          //Module
          masters.users.forEach((user) => {
            this.userItems.push({
              id: user.id,
              text: user.user_name,
              value: user.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          window.location.href = "/auth/login";
        });
    },
    async save() {
      try {
        this.isLoading = true;

        await axios
          .post("/outlet_users", this.form)
          .then(async () => {
            await this.saveUserTimestamp(null, this.form);
            this.isLoading = false;
            this.$router.push(
              `/outlets/${this.$route.params.outletId}/outlet-users`
            );
          })
          .catch((error) => {
            // redirect to Home Page
            window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
