<template>
  <v-container fluid v-if="isPgLoading == false">
    <v-row justify="center">
      <v-col sm="8">
        <label class="subtitle-1 font-weight-bold"> First Name * </label>
        <v-text-field
          placeholder="Enter First Name"
          v-model="form.first_name"
          outlined
          @keypress="onlyAlphabets"
          :error-messages="errors.first_name"
          readonly
        ></v-text-field>

        <label class="subtitle-1 font-weight-bold"> Middle Name * </label>
        <v-text-field
          v-model="form.middle_name"
          placeholder="Enter Middle Name"
          outlined
          @keypress="onlyAlphabets"
          :error-messages="errors.middle_name"
          readonly
        ></v-text-field>

        <label class="subtitle-1 font-weight-bold"> Last Name * </label>
        <v-text-field
          v-model="form.last_name"
          placeholder="Enter Last Name"
          outlined
          @keypress="onlyAlphabets"
          :error-messages="errors.last_name"
          readonly
        ></v-text-field>

        <label class="subtitle-1 font-weight-bold"> Email * </label>
        <v-text-field
          v-model="form.email"
          placeholder="Enter Email"
          outlined
          :error-messages="errors.email"
          readonly
        ></v-text-field>

        <label class="subtitle-1 font-weight-bold"> Contact Number * </label>
        <v-text-field
          v-model="form.phone"
          placeholder="Enter Contact Number"
          outlined
          maxlength="10"
          @keypress="onlyNumbers"
          :error-messages="errors.phone"
          readonly
        ></v-text-field>

        <v-divider style="border: 1px solid #d7d7d7" class="my-4"></v-divider>

        <label class="subtitle-1 font-weight-bold"> Password</label>
        <v-text-field
          v-model="form.password"
          placeholder="Enter Password"
          outlined
          :error-messages="errors.password"
        ></v-text-field>

        <label class="subtitle-1 font-weight-bold"> Re-Password</label>
        <v-text-field
          v-model="form.password_confirmation"
          placeholder="Enter Re-Password"
          outlined
          :error-messages="errors.password_confirmation"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../components/loader-circle.vue";
export default {
  components: { Loader },
  name: "RecruiterIdPage",
  data() {
    return {
      profile_section: "My Company",
      tab: null,
      form: {},
      userData: {},
      qualificationItems: [],
      stateItems: [],
      cityItems: [],
      cityLocalityItems: [],
      isPgLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      this.isPgLoading = true;
      await axios
        .get(`users/masters`)
        .then((masters) => {
          masters = masters.data;
          //State
          masters.states.forEach((state) => {
            this.stateItems.push({
              id: state.id,
              text: state.name,
              value: state.id,
            });
          });
          //City
          masters.cities.forEach((city) => {
            this.cityItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
          });
          //City Locality
          masters.cityLocalities.forEach((cityLocality) => {
            this.cityLocalityItems.push({
              id: cityLocality.id,
              text: cityLocality.name,
              value: cityLocality.id,
            });
          });
          //Qualification
          masters.qualifications.forEach((qualification) => {
            this.qualificationItems.push({
              id: qualification.id,
              text: qualification.description,
              value: qualification.id,
            });
          });

          this.isPgLoading = false;
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isPgLoading = true;
      let encryptedId = this.encryptIt(this.user.id);
      axios
        .get(`/users/${encryptedId}`)
        .then((response) => {
          this.userData = response.data.data;
          this.form = response.data.data;
          this.old_json = response.data.data;
          this.isPgLoading = false;
        })
        .catch((error) => {
          // redirect to Home Page
          //   window.location.href = "/auth/login";
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        await axios
          .post(`/users`, this.form)
          .then(async () => {
            await this.saveUserTimestamp(this.old_json, this.form);
          })
          .catch((error) => {
            // redirect to Home Page
            // window.location.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
        this.isLoading = false;
        if (this.$route.name == "ProfilePage") {
          // this.$router.push("/supervisor");
          window.location.href = "/auth/login";
        }
      } catch (e) {
        console.error("Error fetching data:", e);
      }
    },
    addChip(title, value) {
      if (title == "Category" && this.catSelected.length < 5) {
        this.catSelected.push(value);
      } else if (
        title == "Preferred Location" &&
        this.preferredLocSelected.length < 5
      ) {
        this.preferredLocSelected.push(value);
      } else if (title == "Skills" && this.skillSelected.length < 15) {
        this.skillSelected.push(value);
      }
    },
    removeChip(title, index) {
      if (title == "Category") {
        this.catSelected.splice(index, 1);
      } else if (title == "Preferred Location") {
        this.preferredLocSelected.splice(index, 1);
      } else if (title == "Skills") {
        this.skillSelected.splice(index, 1);
      }
    },
  },
};
</script>
