<template>
  <v-main>
    <!-------- JOB BOARD -------->
    <v-container
      style="background: #1f8bff; color: white"
      class="py-8 py-sm-12 px-5"
      fluid
    >
      <!-- Header Section -->
      <v-row class="text-center" justify="center">
        <v-col cols="12" style="padding: 0">
          <h1 class="mb-4 text-h5 text-md-h3 font-weight-bold jobBoardTitle">
            <span>The </span>
            <span style="color: #fbff00">Job Board </span>
            <span>For<br />Hiring Or Find Your Next Job</span>
          </h1>
          <p style="font-weight: 300" class="text-subtitle-1 text-sm-h5">
            Connecting Recruiters and Job Seekers<br />
            for Effortless Hiring
          </p>
        </v-col>
      </v-row>

      <!-- Search Section -->
      <v-row justify="center" class="mt-6">
        <v-col cols="12" md="10" lg="8">
          <v-card class="search-card" elevation="1">
            <v-row>
              <!-- Industry -->
              <v-col
                cols="12"
                sm="3"
                class="d-none d-sm-flex align-center"
                style="border-right: 2px solid #1f8bff; padding: 0 24px"
              >
                <v-autocomplete
                  dense
                  solo
                  v-model="filter.industry"
                  :items="industryItems"
                  label="Industry"
                  hide-details
                  clearable
                  :error-messages="errors.industry"
                ></v-autocomplete>
              </v-col>
              <!-- Location -->
              <v-col
                cols="12"
                sm="3"
                class="d-none d-sm-flex align-center"
                style="border-right: 2px solid #1f8bff; padding: 0 24px"
              >
                <v-autocomplete
                  dense
                  solo
                  v-model="filter.location"
                  :items="locationItems"
                  label="Location"
                  prepend-inner-icon="mdi-map-marker"
                  hide-details
                  clearable
                  :error-messages="errors.location"
                ></v-autocomplete>
              </v-col>
              <!-- Keyword -->
              <v-col
                cols="10"
                sm="3"
                class="d-flex align-center"
                style="padding: 0 0 0 20px"
              >
                <v-text-field
                  dense
                  solo
                  v-model="filter.search_keywords"
                  label="Your Keyword"
                  prepend-inner-icon="mdi-keyboard-variant"
                  hide-details
                  class="search-textfield"
                ></v-text-field>
              </v-col>
              <v-col class="d-none d-sm-flex" sm="3" style="padding: 0 20px">
                <v-btn color="primary" to="/jobs" large block>
                  <v-icon left size="26">mdi-magnify</v-icon>Search
                </v-btn>
              </v-col>
              <v-col class="d-sm-none" cols="2" style="padding: 0 12px">
                <v-btn color="primary" to="/jobs" large icon>
                  <v-icon left size="26">mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-------- Popular Searches -------->
      <v-row justify="center" class="mt-4">
        <v-col
          cols="12"
          class="text-center text-caption text-sm-body-1"
          style="padding-top: 0"
        >
          <span style="font-weight: 700">Popular Searches: </span>
          <span
            v-for="(item, index) in ['Designer', 'Web', 'iOS', 'PHP', 'Senior']"
            :key="index"
            class="text-decoration-underline mx-1"
            style="font-weight: 300"
          >
            {{ item }}<span v-if="index < 5">,</span>
          </span>
        </v-col>
      </v-row>
    </v-container>

    <!-------- EXPLORE JOBS IN CITIES -------->
    <v-container class="py-8 px-md-16" fluid>
      <v-row justify="center">
        <v-col cols="12" md="10" class="text-center">
          <h1 class="text-h5 text-sm-h3 font-weight-bold">
            Explore jobs in cities
          </h1>
          <p class="text-body-1 text-sm-h5 text--secondary mt-3">
            Connecting Recruiters and Job Seekers to their dream cities
          </p>
        </v-col>
      </v-row>

      <!------ Slider Layout ------>
      <v-slide-group v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
        <v-slide-item
          v-for="(city, index) in cities.slice(0, 6)"
          :key="index"
          class="mx-2"
        >
          <ExploreJobCard :city="city" :card-width="260" />
        </v-slide-item>
      </v-slide-group>

      <!------ Grid System ------>
      <v-row justify="center" align="stretch" dense spacing="6" v-else>
        <template v-if="!isLoading">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="3"
            class="pa-3"
            v-for="(city, index) in cities.slice(0, 6)"
            :key="index"
          >
            <ExploreJobCard :city="city" />
          </v-col>
        </template>
        <template v-else>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="3"
            class="pa-3"
            v-for="(loader, index) in 6"
            :key="index"
          >
            <template>
              <v-card>
                <v-card-text style="padding: 10px">
                  <v-skeleton-loader
                    class="rounded-xl"
                    type="card"
                    max-height="250"
                  ></v-skeleton-loader>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </template>
      </v-row>
      <v-row justify="center" class="mt-10">
        <v-btn
          color="primary"
          dark
          large
          class="viewBtn text-h6 text-capitalize font-weight-bold"
          to="/city-jobs"
          >View More</v-btn
        >
      </v-row>
    </v-container>

    <!-------- FEATURED COMPANIES -------->
    <v-container class="py-8 px-md-16 mt-4" fluid>
      <v-row justify="center">
        <v-col cols="12" md="10" class="text-center mb-2">
          <h1 class="text-h5 text-sm-h3 font-weight-bold mb-4">
            Featured Companies
          </h1>
        </v-col>
      </v-row>

      <v-slide-group>
        <v-row justify="center" class="py-2 px-1">
          <template v-if="!isLoading">
            <v-slide-item
              v-for="(organisation, index) in organisations.slice(0, 4)"
              :key="index"
              class="mx-2"
            >
              <v-card
                class="cardLay rounded-xl my-2"
                width="280"
                height="90"
                outlined
                elevation="1"
                :to="`/jobs?organisation=${organisation.id}`"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="auto">
                      <v-avatar size="60" color="secondary">
                        <v-img
                          :src="
                            organisation.logo_path
                              ? mediaUrl + organisation.logo_path
                              : require('@/assets/office-building.webp')
                          "
                          class="rounded-xl"
                          alt="City Image"
                        ></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col style="padding: 12px 12px 12px 0px">
                      <div class="mt-1" style="color: rgb(35, 35, 35)">
                        <h3
                          class="text-h6 font-weight-bold"
                          style="white-space: nowrap"
                        >
                          {{ organisation.name }}
                        </h3>
                        <v-row>
                          <v-col>
                            <p
                              class="text-subtitle-2"
                              style="
                                margin: 0;
                                color: var(--v-primary-base);
                                white-space: nowrap;
                              "
                            >
                              View Jobs
                            </p>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-slide-item>
          </template>
          <template v-else>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="3"
                class="pa-3"
                v-for="(loader, index) in 4"
                :key="index"
              >
                <v-card
                  class="cardLay rounded-xl my-2"
                  width="280"
                  height="90"
                  outlined
                  elevation="1"
                >
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="auto"
                        class="text-center"
                        align-self="center"
                        style="padding-right: 0px"
                      >
                        <v-skeleton-loader
                          type="avatar"
                          height="60"
                          width="60"
                        ></v-skeleton-loader>
                      </v-col>
                      <v-col style="padding: 12px 12px 12px 0px">
                        <v-skeleton-loader type="list-item"></v-skeleton-loader>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-row>
      </v-slide-group>
    </v-container>

    <!-------- EXPLORE JOBS IN ROLES -------->
    <v-container class="py-8 px-md-16" fluid>
      <v-row justify="center">
        <v-col cols="12" md="10" class="text-center mb-8">
          <h1 class="text-h5 text-sm-h3 font-weight-bold">
            What type of role are you seeking?
          </h1>
        </v-col>
      </v-row>
      <v-slide-group v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
        <v-slide-item
          v-for="(category, index) in categories.slice(0, 6)"
          :key="index"
          class="ma-2"
        >
          <v-card
            class="cardLay rounded-xl"
            outlined
            elevation="2"
            :to="category.jobs_count != 0 && `/jobs?role=${category.id}`"
          >
            <v-card-text>
              <v-img
                :src="mediaUrl + category.image_path"
                style="background-color: gray"
                width="260"
                height="200px"
                class="rounded-xl"
                alt="City Image"
              ></v-img>
              <div class="mt-4" style="color: rgb(35, 35, 35)">
                <h3 class="text-h6 font-weight-bold">{{ category.name }}</h3>
                <v-row>
                  <v-col>
                    <p
                      class="text-subtitle-2"
                      style="margin: 0; color: var(--v-primary-base)"
                    >
                      {{
                        category.jobs_count != 0
                          ? `${category.jobs_count} Vacancies`
                          : "Coming soon..."
                      }}
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>

      <v-row justify="center" align="stretch" dense v-else>
        <template v-if="!isLoading">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="3"
            v-for="(category, index) in categories.slice(0, 6)"
            :key="index"
            class="pa-3"
          >
            <v-card
              class="cardLay rounded-xl"
              outlined
              elevation="2"
              :to="category.jobs_count != 0 && `/jobs?category=${category.id}`"
            >
              <v-card-text>
                <v-img
                  :src="mediaUrl + category.image_path"
                  style="background-color: gray"
                  height="200px"
                  class="rounded-xl"
                  alt="City Image"
                ></v-img>
                <div class="mt-4" style="color: rgb(35, 35, 35)">
                  <h3 class="text-h6 font-weight-bold">{{ category.name }}</h3>
                  <v-row>
                    <v-col>
                      <p
                        class="text-subtitle-2"
                        style="margin: 0; color: var(--v-primary-base)"
                      >
                        {{
                          category.jobs_count != 0
                            ? `${category.jobs_count} Vacancies`
                            : "Coming soon..."
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <template v-else>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="3"
            class="pa-3"
            v-for="(loader, index) in 6"
            :key="index"
          >
            <template>
              <v-card>
                <v-card-text style="padding: 10px">
                  <v-skeleton-loader
                    class="rounded-xl"
                    type="card"
                    max-height="250"
                  ></v-skeleton-loader>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </template>
      </v-row>
      <v-row justify="center" class="mt-10">
        <v-btn
          color="primary"
          dark
          large
          class="viewBtn text-h6 text-capitalize font-weight-bold"
          to="/role-jobs"
          >View More</v-btn
        >
      </v-row>
    </v-container>

    <!-- TYPE OF JOB REQUIREMENT -->
    <v-container class="py-8 px-md-16 mt-4" fluid>
      <v-row justify="center">
        <v-col cols="12" md="10" class="text-center mb-2">
          <h1 class="text-h5 text-sm-h3 font-weight-bold">
            Type of job requirement
          </h1>
        </v-col>
      </v-row>

      <template v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
        <v-slide-group>
          <v-row justify="center" class="py-2 px-1">
            <template v-if="!isLoading">
              <v-slide-item
                v-for="(jobReq, index) in jobRequirements.slice(0, 4)"
                :key="index"
                class="ma-2"
              >
                <v-card
                  class="cardLay rounded-xl"
                  outlined
                  elevation="1"
                  width="280"
                  :to="`/jobs?jobReq=${jobReq.name}`"
                >
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <!-- <v-avatar size="60" color="secondary">
                          <v-img
                            :src="jobReq.logo"
                            class="rounded-xl"
                            alt="City Image"
                          ></v-img>
                        </v-avatar> -->
                        <div class="mt-2" style="color: rgb(35, 35, 35)">
                          <h3 class="text-h6 font-weight-bold">
                            {{ jobReq.name }}
                          </h3>
                          <p
                            class="text-subtitle-2"
                            style="margin: 0; color: var(--v-primary-base)"
                          >
                            {{ jobReq.jobs_count }}+ Vacancies
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-slide-item>
            </template>
            <template v-else>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  class="pa-3"
                  v-for="(loader, index) in 4"
                  :key="index"
                >
                  <v-card
                    class="cardLay rounded-xl my-2"
                    width="280"
                    height="90"
                    outlined
                    elevation="1"
                  >
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="auto"
                          class="text-center"
                          align-self="center"
                          style="padding-right: 0px"
                        >
                          <v-skeleton-loader
                            type="avatar"
                            height="60"
                            width="60"
                          ></v-skeleton-loader>
                        </v-col>
                        <v-col style="padding: 12px 12px 12px 0px">
                          <v-skeleton-loader
                            type="list-item"
                          ></v-skeleton-loader>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-row>
        </v-slide-group>
      </template>
      <template v-else>
        <template v-if="!isLoading">
          <v-row justify="center" align="stretch" dense>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="3"
              v-for="(jobReq, index) in jobRequirements.slice(0, 4)"
              :key="index"
              class="px-6"
            >
              <v-card
                class="cardLay rounded-xl"
                outlined
                elevation="1"
                :to="`/jobs?jobReq=${jobReq.name}`"
              >
                <v-card-text>
                  <v-row>
                    <v-col>
                      <!-- <v-avatar size="60" color="secondary">
                        <v-img
                          :src="jobReq.logo"
                          class="rounded-xl"
                          alt="City Image"
                        ></v-img>
                      </v-avatar> -->
                      <div class="mt-2" style="color: rgb(35, 35, 35)">
                        <h3 class="text-h6 font-weight-bold text-capitalize">
                          {{ pascalCaseText(jobReq.name) }}
                        </h3>
                        <p
                          class="text-subtitle-2"
                          style="margin: 0; color: var(--v-primary-base)"
                        >
                          {{ jobReq.jobs_count }}+ Vacancies
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="3"
              class="pa-3"
              v-for="(loader, index) in 4"
              :key="index"
            >
              <v-card
                class="cardLay rounded-xl my-2"
                width="250"
                height="150"
                outlined
                elevation="1"
              >
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-center"
                      align-self="center"
                      style="padding-bottom: 0px"
                    >
                      <v-skeleton-loader
                        type="avatar"
                        height="60"
                        width="60"
                      ></v-skeleton-loader>
                    </v-col>
                    <v-col style="padding: 0px 0px 12px">
                      <v-skeleton-loader type="list-item"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </template>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import ExploreJobCard from "../components/explore-job-card.vue";
export default {
  components: { ExploreJobCard },
  name: "HomePage",
  data() {
    return {
      filter: {
        industry: "",
        location: "",
        search_keywords: "",
      },
      industryItems: [],
      locationItems: [],
      cities: [],
      organisations: [],
      categories: [],
      jobRequirements: [],
      company_id: "1",
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      await axios
        .get(`jobs/masters`)
        .then((masters) => {
          masters = masters.data;
          //organisations
          masters.organisations.forEach((organisation) => {
            this.industryItems.push({
              id: organisation.id,
              text: organisation.name,
              value: organisation.id,
            });
          });
          //cities
          masters.cities.forEach((city) => {
            this.locationItems.push({
              id: city.id,
              text: city.name,
              value: city.id,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
    },
    async getData() {
      this.isLoading = true;
      this.filter.show_deleted = this.$route.query.open_trash ? 1 : 0;
      this.filter.location = this.filter.location ? this.filter.location : "";
      this.filter.search_keywords = this.filter.search_keywords
        ? this.filter.search_keywords
        : "";
      let query =
        "&show_deleted=" +
        this.filter.show_deleted +
        "&location=" +
        this.filter.location +
        "&organisation=" +
        this.filter.industry +
        "&search_keywords=" +
        this.filter.search_keywords;
      await axios
        .get(`/jobs/job_boards?${query}`)
        .then((response) => {
          this.cities = response.data.cities;
          this.organisations = response.data.organisations;
          this.categories = response.data.categories;
          this.jobRequirements = response.data.jobRequirment;
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.landingPgTitle {
  font-size: 42px;
}
.jobBoardTitle {
  font-size: 42px;
  word-spacing: 4px;
  line-height: 1.3;
}
.text--secondary {
  color: rgba(0, 0, 0, 0.6);
}
.cardLay {
  transition: transform 0.2s ease;
}
.cardLay:hover {
  transform: scale(1.02);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.search-card {
  padding: 10px;
}

@media (max-width: 600px) {
  .search-card {
    padding: 10px;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .search-card {
    padding: 20px;
  }
}

@media (min-width: 961px) {
  .search-card {
    padding: 20px;
  }
}
</style>
